import { Flex, Image, Layout, Menu } from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuConf } from "../../configs/MenuConf";
import { useAppSelector } from "../../store/hooks";
import { useMemo } from "react";
import authService from "../../services/auth.service";
import Avatar from "../ui/avatar/avatar";

export default function Sider() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const {value : user} = useAppSelector(state => state.user);


  const handleMenuClick = ({ key } : {key: string}) => {
    if (key) {
      navigate(key);
    }
  };

  const getDefaultSelectedKeys = () => {
    const keys = pathname.split('/');

    return keys.length > 1 ? [keys[1] ? keys[1] : '/'] : [];
  }

  const menuConfEnabled = useMemo(() => {
    return MenuConf.filter((item) => {
      if (item.permission) {
        return user?.hasPermission(item.permission);
      }
      return true;
    });
  
  }, [user])
  

  return (
    <Layout.Sider theme="dark" width={316}>
      <Flex vertical gap={24} style={{height: '100%'}}>
        <Image src="/calque_logo.svg" width={221} height={74} preview={false} />
        <Menu
          theme="dark"
          items={menuConfEnabled}
          inlineIndent={12}
          defaultSelectedKeys={getDefaultSelectedKeys()}
          defaultOpenKeys={["reporting"]}
          mode="inline"
          onClick={handleMenuClick}
          style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}
        />

        {user && <div className="flex row align-item-center" style={{marginLeft: 10, marginBottom: 10}}>
          <Avatar placeholder={user.getLettersName()} size="normal" />
          <div className="flex column" style={{marginLeft: 8, gap: 4}}>
            <div style={{color: 'white', fontSize: 14, fontWeight: 600}}>{user.getFullName()}</div>
            <div style={{color: '#D0D5DD', fontSize: 14, fontWeight: 600, cursor: 'pointer' }} onClick={() => authService.logout(navigate)}>
              Se déconnecter
            </div>
          </div>
        </div>}
      </Flex>
    </Layout.Sider>
  );
}
