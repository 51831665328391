import { Layout } from "antd";
import Sider from "../layout/Sider";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import authService from "../../services/auth.service";
import { useAppDispatch } from "../../store/hooks";
import { setUser } from "../../store/user/reducer";
import NotificationList from "../ui/notifications-list/notification-list";
import taskService from "../../services/task.service";
import { ETaskStatus } from "../../models/task";
import { setRemainingTasks } from "../../store/tasks/reducer";
import roadDemandesService from "../../services/road-demandes.service";
import { setRemainingRoadDemandes } from "../../store/road-demandes/reducer";
import { StatusRoadDemande } from "../../models/enums/status-road-demande.enum";


export default function BaseTemplate() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
        async function fetchUser() {
            await authService.updateAxiosInterceptors(navigate);
            await authService.updateAxiosAuthorization();
            const user = await authService.me();
            dispatch(setUser(user))
            setLoading(false);

            const tasksPaginated = await taskService.list(1, '', user.id, undefined, undefined, ETaskStatus.TODO);
            dispatch(setRemainingTasks(tasksPaginated.total));

            const roadDemandesPaginated = await roadDemandesService.list(1, undefined, [{name: 'status', value: StatusRoadDemande.PENDING_PRICE}] , user.id);
            dispatch(setRemainingRoadDemandes(roadDemandesPaginated.total));
        }

        fetchUser();
    }, [])

    return !loading ? <Layout>
        <Sider />
        <Layout.Content>
            <Layout>
                <Outlet />
            </Layout>   
        </Layout.Content>
        <NotificationList />
    </Layout> : <></>
        
    
}