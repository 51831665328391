interface IProps{
    notFound: boolean;
    children: React.ReactNode;
    title: string;
}
export default function HasFound({ notFound, children , title}: IProps) {
    if (notFound) {
        return <div>
            {title}
        </div>
    }

    return <>{children}</>
    
}