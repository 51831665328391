import type { ColumnsType } from "antd/es/table";
import { TaskMinTableType } from "../models/table/TaskMinTableType";

const columns: ColumnsType<TaskMinTableType> = [
    {
        title: "Tâches",
        dataIndex: "title",
        key: "title",
        sorter: false,
        width: 120,
    },
    {
        title: "Attribué à",
        dataIndex: "user",
        key: "user",
        sorter: false,
        width: 100
    },
    {
        title: "Echéance",
        dataIndex: "due_date",
        key: "due_date",
        sorter: false,
        width: 100
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: false,
        width: 100
    },
    
];

export { columns }