import axios, { AxiosResponse } from "axios";

async function getAnnexes() {
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/pricing/annexes`).then(async (response: AxiosResponse) => {
        return response.data;
    })
}

async function uploadAnnexe(code: string, file: any) {
     const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/app/pricing/annexes/${code}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    
    return response.data;

}

async function downloadAnnexe(code: string) {
    const res = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/pricing/annexes/${code}/download`, {
        responseType: 'blob'
    });

    return res.data
}

async function deleteAnnexe(code: string) {
    return await axios.delete(`${import.meta.env.VITE_BACKEND_URL}/api/app/pricing/annexes/${code}`);
}

export default {
    getAnnexes,
    uploadAnnexe,
    downloadAnnexe,
    deleteAnnexe
}