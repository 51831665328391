import type { ColumnsType } from "antd/es/table";
import { RoadDemandeDataType } from "../models/RoadDemandeDataType";
import { RoadContractDataType } from "../models/RoadContractDataType";

const columns: ColumnsType<RoadContractDataType> = [
    {
        title: "Statut interne",
        dataIndex: "internal_status",
        key: "internal_status",
        sorter: false,
        width: 100
    },
    {
        title: "Affréteur",
        dataIndex: "assigned_user",
        key: "assigned_user",
        sorter: false,
        width: 80
    },
    {
        title: "Date de chargement",
        dataIndex: "loading_date_end",
        key: "loading_date_end",
        sorter: false,
        width: 100
    },
     {
        title: "Client",
        dataIndex: "client",
        key: "client",
        sorter: false,
        width: 100
    },
    {
        title: "Code du contrat",
        dataIndex: "code",
        key: "code",
        sorter: false,
        width: 120
    },
     {
        title: "Réf. client",
        dataIndex: "client_reference",
        key: "client_reference",
        sorter: false,
        width: 100
    },
    {
        title: "Origine",
        dataIndex: "loading_address",
        key: "loading_origin",
        sorter: false,
        width: 160
    },
    {
        title: "Destination",
        dataIndex: "delivery_address",
        key: "delivery_address",
        sorter: false,
        width: 160
    },
    {
        title: "Qte",
        dataIndex: "quantity",
        key: "quantity",
        sorter: false,
        width: 60
    },
    {
        title: "Type",
        dataIndex: "type",
        key: "type",
        sorter: false,
        width: 60
    },
    {
        title: "Date de livraison",
        dataIndex: "delivery_date",
        key: "delivery_date",
        sorter: false,
        width: 100
    },
    {
        title: "Nom du transporteur",
        dataIndex: "carrier_name",
        key: "carrier_name",
        sorter: false,
        width: 100
    },
    {
        title: "Conduc.",
        dataIndex: "driver",
        key: "driver",
        sorter: false,
        width: 100
    },
    {
        title: "ADR",
        dataIndex: "dangerous_substances",
        key: "dangerous_substances",
        sorter: false,
        width: 60
    },
    {
        title: "Statut",
        dataIndex: "status",
        key: "status",
        sorter: false,
        width: 100
    },
    {
        title: "Prix de vente",
        dataIndex: "selling_price",
        key: "selling_price",
        sorter: false,
        width: 100
    },
    {
        title: "Prix d'achat",
        dataIndex: "purchase_price",
        key: "purchase_price",
        sorter: false,
        width: 100
    }
];

export { columns }