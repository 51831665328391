import { Checkbox, Form } from "antd";
import { Controller } from "react-hook-form";

interface IProps {
  control: any;
  name: string;
  label?: string;
  defaultValue?: boolean;
  disabled?: boolean;
};
export default function FormCheckbox({
  control,
  name,
  label,
  defaultValue,
  disabled = false
}: IProps) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      disabled={disabled}
      render={({ field }) => (
        <Checkbox {...field} defaultChecked={defaultValue}>{label}</Checkbox>
      )}
    />
  );
};