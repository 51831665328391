import type { ColumnsType } from "antd/es/table";
import { CustomerContactTableType } from "../models/table/CustomerContactTableType";

const columns: ColumnsType<CustomerContactTableType> = [
    {
        title: "Nom",
        dataIndex: "name",
        key: "name",
        sorter: false,
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: false,
    },
    {
        title: "Date de création",
        dataIndex: "created_at",
        key: "created_at",
        sorter: false,
    }
];

export { columns }