export const ROAD_QUOTATION_OPTIONS = [
    { label: 'Palette 80x120', value: 'PALETTE_80_120' },
    { label: 'Palette 100x120', value: 'PALETTE_100_120' },
    { label: 'Mètre', value: 'METRE' },
    { label: 'Complet', value: 'COMPLET' }
]

export const ROAD_QUOTATION_DANGEROUS_CLASSES = [
    { label: "Classe 1 : Matières et objets explosibles", value: '1' },
    { label: "Classe 2 : Gaz", value: '2' },
    { label: "Classe 3 : Liquides inflammables", value: '3' },
    { label: "Classe 4.1 : Matières solides inflammables, matières autoréactives, matières explosibles désensibilisées solides et matières qui polymérisent", value: '4.1' },
    { label: "Classe 4.2 : Matières sujettes à l'inflammation spontanée", value: '4.2' },
    { label: "Classe 4.3 : Matières qui, au contact de l’eau, dégagent des gaz inflammables", value: '4.3' },
    { label: "Classe 5.1 : Matières comburantes", value: '5.1' },
    { label: "Classe 5.2 : Peroxydes organiques", value: '5.2' },
    { label: "Classe 6.1 : Matières toxiques", value: '6.1' },
    { label: "Classe 6.2 : Matières infectieuses", value: '6.2' },
    { label: "Classe 7 : Matières radioactives", value: '7' },
    { label: "Classe 8 : Matières corrosives", value: '8' },
    { label: "Classe 9 : Matières et objets dangereux divers", value: '9' },
]