import type { ColumnsType } from "antd/es/table";
import { CustomerDataType } from "../models/CustomerDataType";

const columns: ColumnsType<CustomerDataType> = [
    {
        title: "Numéro de client",
        dataIndex: "code",
        key: "code",
    },
    {
        title: "Client",
        dataIndex: "client",
        key: "client",
        sorter: false,
    },
    {
        title: "Contact",
        dataIndex: "contact",
        key: "contact",
        sorter: false,
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: false,
    },
    {
        title: "Ville",
        dataIndex: "city",
        key: "city",
        sorter: false,
    },
    {
        title: "Pays",
        dataIndex: "country",
        key: "country",
        sorter: false,
    },
    {
        title:"Agence de rattachement",
        dataIndex: "home_agency",
        key: "home_agency",
        sorter: false,
    },
    {
        title: "Affréteur",
        dataIndex: "charterer",
        key: "charterer",
        sorter: false,
    },
];

export { columns }