import { Text } from "../ui/typo/Text";

interface IProps{
    message?: string;
}

export default function FormError({ message }: IProps) {
    return (
        <>
            {message && <Text target="Regular" type="danger">{message}</Text>}
        </>
    )
}