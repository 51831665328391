import Panel from "../../../ui/panel/panel";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { Text } from "../../../ui/typo/Text";
import User, { ERole, getRoleName } from "../../../../models/user";
import { useEffect, useMemo, useState } from "react";
import DataPaginate from "../../../../models/utils/data-paginate";
import agencieService from "../../../../services/agencie.service";
import Agencie from "../../../../models/agencie";
import useDebounce from "../../../../utils/hooks/useDebounce";
import { Icon } from "../../../ui/typo/Icon";
import { Controller, useForm } from "react-hook-form";
import userService from "../../../../services/user.service";
import ErrorsForm from "../../../ui/errors/errors-form/errors-form";
import ErrorApi from "../../../../models/utils/error-api";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import FormInput from "../../../form/form-input";
import FormSelectMultiple from "../../../form/form-select-multiple";
import FormSelectSingle from "../../../form/form-select-single";
import { useAppDispatch } from "../../../../store/hooks";
import { addToast } from "../../../../store/toasts/reducer";
import Toast from "../../../../models/utils/toast";

interface IProps{
    showModal: boolean;
    onClose: (refresh?: boolean) => void;
    user?: User;
}
type Inputs = {
    firstname: string,
    lastname: string,
    email: string,
    roles: ERole[],
    agencies_id: string[],
    code: string;
}
export default function PanelFormUser({ showModal, onClose, user }: IProps) {
    const schema: any = yup.object().shape({
        firstname: yup.string().required("Le prénom est requis !"),
        lastname: yup.string().required("Le nom est requis !"),
        email: yup.string().required("L'email est requis !").email("L'email est invalide !"),
        code: yup.string(),
    })
    const [agenciePaginated, setAgenciePaginated] = useState<DataPaginate>();
    const [agencieSearch, setAgencieSearch] = useState<string>('');
    const {
        handleSubmit,
        control,
        formState: { isDirty, isValid, errors },
        reset,
    } = useForm<Inputs>({
        resolver: yupResolver(schema), mode: 'onChange'
    })
    const [loading, setLoading] = useState(false);
    const [errorApi, setErrorApi] = useState<ErrorApi>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await agencieService.list(1, agencieSearch);
                setAgenciePaginated(res);
            } catch (e) {
                console.log(e);
            }
        }
        fetchData();
    }, [agencieSearch])

    const agencieOptions = useMemo(() => {
        if (!agenciePaginated || !agenciePaginated.data) return [];
        
        return agenciePaginated.data.map((agencie: Agencie) => ({ label: agencie.name, value: agencie.id }));
    }, [agenciePaginated]);

    const title = useMemo(() => user ? "Modification d'un utilisateur" : "Nouvel utilisateur", [user]);

    const handleSearchAgencie = useDebounce((term) => setAgencieSearch(term), 500);

    async function onSubmit(data: any) {
        try {
            setLoading(true);
            setErrorApi(undefined);
            const {agencies_id, ...rest} = data;
            user ? await userService.update(user.id, data) : await userService.create(data);
            user ? dispatch(addToast(new Toast("L'utilisateur a été modifié avec succès !", 'success'))) :
                dispatch(addToast(new Toast("L'utilisateur a été créé avec succès !", 'success')))
            reset();
            onClose(true);
        } catch (e: any) {
            if (e.response) {
                setErrorApi(new ErrorApi(e.response));
            }
        } finally {
            setLoading(false);
        }
    }

    function closeModal() {
        reset();
        onClose();
    }
    
    return (
        <Panel
            title={<Text target="H2">{title}</Text>}
            isOpen={showModal}
            onClose={() => closeModal()}
        >
            <Form style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} layout="vertical" requiredMark={false} onSubmitCapture={handleSubmit(onSubmit)}>
                <div>
                    <Row gutter={16}>
                        <Col span={12}>
                            <FormInput 
                                label="Prénom"
                                required
                                name="firstname"
                                placeholder="Entrer le prénom"
                                defaultValue={user?.firstname}
                                control={control}
                                error={errors?.firstname}
                            />
                        </Col>

                        <Col span={12}>
                            <FormInput 
                                label="Nom"
                                required
                                name="lastname"
                                placeholder="Entrer le nom"
                                defaultValue={user?.lastname}
                                control={control}
                                error={errors?.lastname}
                            />
                        </Col>

                        <Col span={12}>
                            <FormInput 
                                label="Adresse email"
                                required
                                name="email"
                                placeholder="Entrer l'email"
                                defaultValue={user?.email}
                                control={control}
                                error={errors?.email}
                            />
                        </Col>
                        <Col span={12}>
                            <FormSelectMultiple
                                label="Droits"
                                name="roles"
                                placeholder="Séléctionner le(s) rôle(s)"
                                defaultValue={user?.roles as any}
                                control={control}
                                options={Object.values(ERole).map((role) => ({ label: getRoleName(role), value: role }))}
                            />
                        </Col>
                        <Col span={12}>
                            <FormSelectMultiple
                                label="Agence de rattachement"
                                name="agencies_id"
                                placeholder="Sélectionner les agences"
                            
                                defaultValue={user?.agencies.map((agencie: Agencie) => agencie.id) as any}
                                control={control}
                                options={agencieOptions}
                                onSearch={handleSearchAgencie}
                                hasCustomFilter={true}
                            />
                        </Col>
                        <Col span={12}>
                            <FormInput 
                                label="Code"
                                required
                                name="code"
                                placeholder="Entrer le code"
                                defaultValue={user?.code}
                                control={control}
                                error={errors?.code}
                            />
                        </Col>
                    </Row>

                    <ErrorsForm errorApi={errorApi}/>

                </div>

                <div className="ant-drawer-footer">
                    <Space
                        size={16}
                        style={{ justifyContent: "flex-end", width: "100%" }}
                    >       
                        <Button onClick={() => closeModal()} icon={<Icon path="./icons/arrow-left.svg" size={20} />}>
                            <Text target="Bold">Annuler</Text>
                        </Button>
                        
                        <Button htmlType="submit" disabled={!isDirty || !isValid || loading} type="primary" icon={<Icon path="./icons/check.svg" size={20} />}>
                            <Space size={6}>
                                <Text target="Bold">Valider</Text>
                            </Space>
                        </Button>
                    </Space>
                </div>
            </Form>
        </Panel>
    )
}
