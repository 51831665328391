
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./styles/app.scss";
import LoginPage from "./pages/auth/login-page";
import ValidationCodePage from "./pages/auth/validation-code-page";
import HomePage from "./pages/homepage/homepage";
import BaseTemplate from "./components/base-template/base-template";
import ListCustomersPage from "./pages/customers/list-customers-page";
import ListUsersPage from "./pages/users/list-users-page";
import DetailCustomer from "./pages/customers/detail/detail-customer";
import ListTasksPage from "./pages/notes/list-tasks-page";
import ListAgenciesPage from "./pages/agencies/list-agencies-page";
import ListRoadQuotationsPage from "./pages/road-folder/list-road-quotations-page";
import AnnexePage from "./pages/pricing/annexePage";
import DetailRoadQuotationPage from "./pages/road-folder/detail-road-quotation-page";
import ListContractRoadQuotationPage from "./pages/road-folder/list-contract-road-quotation-page";
import DetailRoadContractPage from "./pages/road-folder/detail-road-contract-page";


function App() {
  const router = createBrowserRouter(
    [
      {
        path: '/login',
        element: <LoginPage />
      },
      {
        path: '/validation-code',
        element: <ValidationCodePage />
      },
      {
        path: '/',
        element: <BaseTemplate />,
        children: [
          {
            path: '/',
            element: <HomePage />
          },
          {
            path: 'road-quotations',
            element: <ListRoadQuotationsPage />
          },
          {
            path: 'road-quotations/:id',
            element: <DetailRoadQuotationPage />
          },
          {
            path: 'road-contracts',
            element: <ListContractRoadQuotationPage />
          },
          {
            path: 'road-contracts/:id',
            element: <DetailRoadContractPage />
          },
          {
            path: 'customers',
            element: <ListCustomersPage />
          },
           {
            path: 'customers/:id',
            element: <DetailCustomer />
          },
          {
            path: 'tasks',
            element: <ListTasksPage />
          },
          {
            path: 'users',
            element: <ListUsersPage />
          },
          {
            path: 'agencies',
            element: <ListAgenciesPage />
          },
          {
            path: 'annexes',
            element: <AnnexePage />
          },
          {
            path: 'sentry',
            element: <div><button onClick={() => {throw new Error("This is your first error!");}}>Break the world</button></div>
          }
        ]
      }
    ] 
  )

  return <RouterProvider router={router} />;
}

export default App;
