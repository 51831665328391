import { Button, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useState } from "react";
import { Text } from "../../components/ui/typo/Text";
import { Icon } from "../../components/ui/typo/Icon";
import HeadTitle from "../../components/ui/table/HeadTitle";

const useTable = (columns: ColumnsType<any>, isArchived? : boolean, editIsDisabled = false, trashIsDisabled = false,  ) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [visibleColumns, setVisibleColumns] = useState<string[]>(columns.map((column: any) => column.key));
    const [showModalDeletion, setShowModalDeletion] = useState<boolean>(false);

    const rowSelection: any = {
        selectedRowKeys,
        onChange: (value: any) => {
            setSelectedRowKeys(value);
        },
    };

    function resetRowSelection() {
        setSelectedRowKeys([]);
    }

    function renderColumns() {
        return columns.filter((column: any) => visibleColumns.includes(column.key)).map((column: any) => {
            return {
                ...column,
                title: <HeadTitle title={column.title} />,
            }
        });
    }
    
    interface ICustomButton {
        svgPath: string,
        label: string,
        uniqueSelection?: boolean
    }
    function renderColumnsAndSelection(
        visibleColumns: string[],
        selectedRowKeys: React.Key[],
        onEdit: () => void,
        onUnarchive?: () => void,
        customButtons?: ICustomButton[],
        onCustomAction?: (index: number) => void
    ) {
        if (selectedRowKeys.length > 0) {
            const columns = renderColumns();

            return [
                {
                    colSpan: visibleColumns.length,
                    title: (
                        <Space size={32} style={{ width: "100%" }}>
                            <Text target="Bold">{selectedRowKeys.length} sélectionné(s)</Text>
                            {!isArchived && !trashIsDisabled && <Button
                                type="link"
                                icon={<Icon path="/icons/trash-danger.svg" size={20} />}
                                danger
                                onClick={() => setShowModalDeletion(true)}
                                style={{ padding: 0, height: "20px" }}
                            >
                                <Text target="Bold">Supprimer</Text>
                            </Button>}
                            {selectedRowKeys.length == 1 && !isArchived && !editIsDisabled && <Button
                                type="link"
                                icon={<Icon path="/icons/edit.svg" size={20} />}
                                onClick={onEdit}
                                style={{ padding: 0, height: "20px" }}
                            >
                                <Text target="Bold">Modifier</Text>
                            </Button>}

                            {isArchived && <Button
                                type="link"
                                icon={<Icon path="/icons/archive.svg" size={20} />}
                                onClick={onUnarchive}
                                style={{ padding: 0, height: "20px" }}
                            >
                                <Text target="Bold">Désarchiver</Text>
                            </Button>}

                            {customButtons && customButtons.map((button, index) => (
                                <React.Fragment key={index}>
                                    {(button.uniqueSelection && selectedRowKeys.length == 1) || !button.uniqueSelection  ? <Button
                                        type="link"
                                        icon={<Icon path={button.svgPath} size={20} />}
                                        onClick={() => onCustomAction ? onCustomAction(index) : null}
                                        style={{ padding: 0, height: "20px" }}
                                    >
                                        <Text target="Bold">{ button.label }</Text>
                                    </Button> : <></>
                                    }
                                </React.Fragment>
                            ))}
                        </Space>
                    ),
                    dataIndex: columns[0].dataIndex,
                    key: columns[0].key,
                    width: columns[0].width,
                },
                ...renderColumns().filter((_, index) => index > 0).map((column: any) => {
                    return {
                        ...column,
                        colSpan: 0
                    }
                })
            ]
        }
        return renderColumns();
    }



    return {
        selectedRowKeys,
        rowSelection,
        visibleColumns,
        setVisibleColumns,
        renderColumnsAndSelection,
        renderColumns,
        showModalDeletion,
        setShowModalDeletion,
        resetRowSelection
    };

}

export default useTable;