import { Breadcrumb, Button, Flex, Layout, Space } from "antd";
import { Text } from "../../components/ui/typo/Text";
import Avatar from "../ui/avatar/avatar";
import { useAppSelector } from "../../store/hooks";
import { Icon } from "../ui/typo/Icon";


interface IPropsWithTitle {
    title: string;
    children?: React.ReactNode;
    breadcrumb?: never;
}

interface IPropsWithBreadcrumb {
    breadcrumb: any;
    children?: React.ReactNode;
    title?: never;
}

type IProps = IPropsWithTitle | IPropsWithBreadcrumb;

export default function Header({title, children, breadcrumb}: IProps) {
    const {value : user} = useAppSelector(state => state.user);

    return (
        <Layout.Header>
            <Flex align="center" justify="space-between" style={{ width: "100%" }}>
                {title && <Breadcrumb
                    items={[{ title: <Text target="H1">{title}</Text> }]}
                />}
                {breadcrumb && <Breadcrumb items={breadcrumb} />}
                <Space size={24}>
                    {children}
                    <Avatar placeholder={user?.getLettersName()} />
                </Space>
            </Flex>
        </Layout.Header>
    )
}