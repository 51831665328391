import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IPageState {
  title?: string;
}

const initialState: IPageState = {
  title: undefined,
};

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setTitlePage: (state: IPageState, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
  },
});

export const { setTitlePage } = pageSlice.actions;

export const pageReducer = pageSlice.reducer;
