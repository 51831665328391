import { useAppSelector } from "../../../store/hooks";

export default function RoadDemandItemMenu() {
    const { nbRemainingRoadDemandes } = useAppSelector(state => state.road_demandes);

    return (
        <div className="flex row align-item-center justify-content-between">
            Cotations de transport
            {nbRemainingRoadDemandes > 0 && <div className="tag-item">
                <label>{nbRemainingRoadDemandes}</label>
            </div>}
        </div>
    )   
}