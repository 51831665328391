import Agencie from "./agencie"

export default class User{
    id: number
    email: string
    created_at: Date
    updated_at: Date
    roles: ERole[]
    agency_id: any
    firstname: string
    lastname: string
    code?: string
    deleted_at?: Date
    agencies: Agencie[] = [];

    constructor(data: any) {
        this.id = data.id;
        this.email = data.email;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.roles = data.roles;
        this.agency_id = data.agency_id;
        this.firstname = data.firstname;
        this.lastname = data.lastname;
        this.code = data.code;
        if(data.deleted_at)
            this.deleted_at = new Date(data.deleted_at);
        if (data.agencies)
            this.agencies = data.agencies.map((agencie: any) => new Agencie(agencie));
    }

    getFullName() {
        if(this.firstname && this.lastname)
            return this.firstname + ' ' + this.lastname;
        else if(this.firstname)
            return this.firstname;
        else if(this.lastname)
            return this.lastname;
        return '';
    }

    getLettersName() {
        if(this.firstname && this.lastname)
            return this.firstname[0].toUpperCase() + this.lastname[0].toUpperCase();

        return '';
    }

    getRolesName() {
        return this.roles.map(role => getRoleName(role)).join(', ');
    }

    hasPermission(permission: ERole) {
        if (this.roles.includes(ERole.ROLE_ADMIN)){
            return true;
        }
        return this.roles.includes(permission);
    }
}

export function getRoleName(role: ERole) {
    if(role === ERole.ROLE_ADMIN)
        return 'Administrateur';
    if (role === ERole.ROLE_MANAGE_USERS)
        return 'Gestion des rôles';
    if (role === ERole.ROLE_BI_ACCESS)
        return 'Accès BI';
    if (role === ERole.ROLE_PRICING_REFERENCE)
        return 'Gestion des tarifs de référence';
    if (role === ERole.ROLE_ROAD_TRANSPORTS)
        return 'Gestion des DT & CT route';
    if (role === ERole.ROLE_SEA_TRANSPORTS)
        return 'Gestion des DT & CT maritime';
    return '';
}
export enum ERole{
    ROLE_ADMIN = 'admin', // all access grant
    ROLE_MANAGE_USERS = 'manage_users',
    ROLE_BI_ACCESS = 'bi_access',
    ROLE_PRICING_REFERENCE = 'pricing_reference',
    ROLE_ROAD_TRANSPORTS = 'road_transports',
    ROLE_SEA_TRANSPORTS = 'sea_transports',
}