import { useForm } from "react-hook-form";
import Agencie from "../../../models/agencie";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo, useState } from "react";
import ErrorApi from "../../../models/utils/error-api";
import { useAppDispatch } from "../../../store/hooks";
import agencieService from "../../../services/agencie.service";
import { addToast } from "../../../store/toasts/reducer";
import Toast from "../../../models/utils/toast";
import Panel from "../../ui/panel/panel";
import { Text } from "../../ui/typo/Text";
import { Button, Col, Form, Row, Space } from "antd";
import FormInput from "../../form/form-input";
import ErrorsForm from "../../ui/errors/errors-form/errors-form";
import { Icon } from "../../ui/typo/Icon";

interface IProps{
    showModal: boolean;
    onClose: (refresh?: boolean) => void;
    agencie?: Agencie;
}
type Inputs = {
    name: string,
    code: string,
    phone: string,
    email: string,
}
export default function PanelFormAgencie({ showModal, onClose, agencie }: IProps) {
    const schema: any = yup.object().shape({
        name: yup.string().required("Le nom est requis !"),
        code: yup.string().required("L'identifiant est requis !"),
        phone: yup.string(),
        email: yup.string().email("L'email est invalide !"),
    })
    const {
        handleSubmit,
        control,
        formState: { isDirty, isValid, errors },
        reset,
    } = useForm<Inputs>({
        resolver: yupResolver(schema), mode: 'onChange'
    })
    const [loading, setLoading] = useState(false);
    const [errorApi, setErrorApi] = useState<ErrorApi>();
    const dispatch = useAppDispatch();

    const title = useMemo(() => agencie ? "Modification d'une agence" : "Nouvelle agence", [agencie]);

    async function onSubmit(data: any) {
        try {
            setLoading(true);
            setErrorApi(undefined);
            agencie ? await agencieService.update(agencie.id, data) : await agencieService.create(data);
            agencie ? dispatch(addToast(new Toast("L'agence a été modifié avec succès !", 'success'))) :
                dispatch(addToast(new Toast("L'agence a été créé avec succès !", 'success')))
            reset();
            onClose(true);
        } catch (e: any) {
            if (e.response) {
                setErrorApi(new ErrorApi(e.response));
            }
        } finally {
            setLoading(false);
        }
    }

    function closeModal() {
        reset();
        onClose();
    }
    
    return (
        <Panel
            title={<Text target="H2">{title}</Text>}
            isOpen={showModal}
            onClose={() => closeModal()}
        >
            <Form style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} layout="vertical" requiredMark={false} onSubmitCapture={handleSubmit(onSubmit)}>
                <div>
                    <Row gutter={16}>
                        <Col span={12}>
                            <FormInput 
                                label="Nom de l'agence"
                                required
                                name="name"
                                placeholder="Entrer le nom de l'agence"
                                defaultValue={agencie?.name}
                                control={control}
                                error={errors?.name}
                            />
                        </Col>

                        <Col span={12}>
                            <FormInput 
                                label="Identifiant"
                                required
                                name="code"
                                placeholder="Entrer l'identifiant"
                                defaultValue={agencie?.code}
                                control={control}
                                error={errors?.code}
                            />
                        </Col>

                        <Col span={12}>
                            <FormInput 
                                label="Téléphone"
                                required
                                name="phone"
                                placeholder="Entrer le téléphone"
                                defaultValue={agencie?.phone}
                                control={control}
                                error={errors?.phone}
                            />
                        </Col>

                        <Col span={12}>
                            <FormInput 
                                label="Email"
                                required
                                name="email"
                                placeholder="Entrer l'email"
                                defaultValue={agencie?.email}
                                control={control}
                                error={errors?.email}
                            />
                        </Col>
                    </Row>

                    <ErrorsForm errorApi={errorApi}/>

                </div>

                <div className="ant-drawer-footer">
                    <Space
                        size={16}
                        style={{ justifyContent: "flex-end", width: "100%" }}
                    >       
                        <Button onClick={() => closeModal()} icon={<Icon path="./icons/arrow-left.svg" size={20} />}>
                            <Text target="Bold">Annuler</Text>
                        </Button>
                        
                        <Button htmlType="submit" disabled={!isDirty || !isValid || loading} type="primary" icon={<Icon path="./icons/check.svg" size={20} />}>
                            <Space size={6}>
                                <Text target="Bold">Valider</Text>
                            </Space>
                        </Button>
                    </Space>
                </div>
            </Form>
        </Panel>
    )
}
