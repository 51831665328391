import axios, { AxiosResponse } from "axios";
import DataPaginate from "../models/utils/data-paginate";
import Agencie from "../models/agencie";

async function list(page: number = 1, search?: string) {
    const queryParams = new URLSearchParams();
    queryParams.append('page_size', '25');
    queryParams.append('page', page.toString());
    if (search) {
        queryParams.append('search', search);
    }
    const params = {
        params: queryParams
    }
    
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/agencies`, params).then(async (response: AxiosResponse) => {
        const dataPaginate = new DataPaginate(response.data);
        dataPaginate.setData(dataPaginate.data.map((item: any) => new Agencie(item)));

        return dataPaginate;
    })
}

async function find(id: number) {
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/agencies/${id}`).then(async (response: AxiosResponse) => {
        return new Agencie(response.data);
    })
}

async function create(data: any) {
    return await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/app/agencies`, data);
}

async function update(id: number, data: any) {
    return await axios.put(`${import.meta.env.VITE_BACKEND_URL}/api/app/agencies/${id}`, data);
}

async function destroy(id: number) {
    return await axios.delete(`${import.meta.env.VITE_BACKEND_URL}/api/app/agencies/${id}`);
}

export default {
    list,
    find,
    create,
    update,
    destroy
}