import { Modal, Space } from "antd";
import { Icon } from "../typo/Icon";
import { Text } from "../typo/Text";

interface IProps{
    modalOpen: boolean;
    title: string;
    description: string;
    confirmText?: string;
    icon?: string;
    onConfirm: () => void;
    onCancel: () => void;
}
export default function DeletionModal({ modalOpen, title, description, confirmText = "Oui", icon = '/icons/trash-expand.svg', onConfirm, onCancel }: IProps) {
    return (
        <Modal
            title={<Icon path={icon} size={48} />}
            centered
            width={400}
            open={modalOpen}
            onOk={() => onConfirm()}
            onCancel={() => onCancel()}
            okText={confirmText}
            cancelText="Non"
            okButtonProps={{ type: "primary", danger: true }}
            cancelButtonProps={{ type: "default", danger: true }}
        >
            <Space direction="vertical" size={16} style={{ width: "100%" }}>
            <Space direction="vertical" size={6} style={{ width: "100%" }}>
                <Text target="H2">{ title }</Text>
                <Text target="Regular">
                {description}
                </Text>
            </Space>
            </Space>
        </Modal>
    )
}