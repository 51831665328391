import { notification } from "antd";
import { useEffect } from "react";
import { useAppSelector } from "../../../store/hooks";

export default function NotificationList() {
    const [api, contextHolder] = notification.useNotification();
    const {item: toast} = useAppSelector((state) => state.toasts);

    useEffect(() => {
        if(toast){
            api[toast.type]({
                message: toast.title,
                description: toast.content,
                placement: 'topLeft',
                duration: 5,
                
            });
        }
    }, [toast])


    return (
        <>
        {contextHolder}
        </>
    )
}