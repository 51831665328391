export default class Agencie{
    id: number
    created_at: Date
    updated_at: Date
    name: string
    code: string
    phone?: string
    email?: string

    constructor(data: any) {
        this.id = data.id;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.name = data.name;
        this.code = data.code;
        this.phone = data.phone;
        this.email = data.email;
    }
}
