import { ERole } from "../../models/user";
import HasPermission from "../../components/core/commons/has-permission/has-permission";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RoadDemande from "../../models/RoadDemande";
import roadDemandesService from "../../services/road-demandes.service";
import HasFound from "../../components/core/commons/has-found/has-found";
import { Button, Col, Layout, Row, Space, Spin, Steps } from "antd";
import Header from "../../components/layout/Header";
import { Text } from "../../components/ui/typo/Text";
import { RightOutlined } from "@ant-design/icons";
import SectionNotesAndTasks from "../../components/core/commons/section-notes-and-tasks.tsx/section-notes-and-tasks";
import RoadQuotationDetailInformation from "../../components/core/road-folder/road-quotation-detail-informations";
import RoadQuotationDetailDevis from "../../components/core/road-folder/road-quotation-detail-devis";
import Devis from "../../models/Devis";
import devisService from "../../services/devis.service";
import RoadQuotationDetailPrice from "../../components/core/road-folder/road-quotation-detail-price";
import { Icon } from "../../components/ui/typo/Icon";
import PanelRoadCotation from "../../components/core/road-folder/panel-road-cotation";
import { StatusRoadDemande } from "../../models/enums/status-road-demande.enum";

export default function DetailRoadQuotationPage() {

    const id = useParams().id;
    const [cotation, setCotation] = useState<RoadDemande>();
    const [devis, setDevis] = useState<Devis>();
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [current, setCurrent] = useState<number>();
    const [attachmentsClient, setAttachmentsClient] = useState<any[]>([]);
    const [attachmentsInternal, setAttachmentsInternal] = useState<any[]>([]);
    const [isEdit, setIsEdit] = useState(false);

    async function fetchData() {
        try {
            setLoading(true);
            setCotation(await roadDemandesService.find(Number(id)))
            setDevis(await devisService.get(Number(id)));
            setAttachmentsClient(await roadDemandesService.getAttachments(Number(id), "client"))
            setAttachmentsInternal(await roadDemandesService.getAttachments(Number(id), "internal"))
        } catch (e: any) {
            console.log(e);
            if (e.response.status == 404) {
                setNotFound(true);
            }
        } finally {
            setLoading(false);
        }
    }


    useEffect(() => {
        fetchData();
    }, [id])

    useEffect(() => {
        if (cotation) {
            setCurrent(cotation.getDefaultTabOnDetail());
        }
    }, [cotation])
    
    return (
        <HasPermission permission={ERole.ROLE_ROAD_TRANSPORTS}>
            <HasFound notFound={notFound} title="La demande de transport n'existe pas !">
                <>
                    {loading && <Spin />}
                    {cotation && <>
                        <Header breadcrumb={[
                                {
                                    title: (
                                        <Space size={16}>
                                           
                                            <Link to="/road-quotations"><Text target="H1">Cotations de transport route</Text></Link>
                                            <RightOutlined />
                                            <Text target="BreadCrumbDetail">Transport n°{cotation.id}</Text>
                                            
                                            
                                        </Space>
                                    ),
                                },
                        ]}>
                            <>
                                {cotation && cotation.status !== StatusRoadDemande.STATUS_ACCEPTED && <Button
                                    type="link"
                                    icon={<Icon path="/icons/edit.svg" size={20} />}
                                    onClick={() => setIsEdit(true)}
                                    style={{ padding: 0, height: "20px" }}
                                >
                                </Button> }
                            </>
                        </Header>
                        <Layout>
                            <Row style={{scrollBehavior: 'auto'}}>
                                <Col className="gutter-row container full-height-content" span={15}>
                                    
                                    <Space direction="vertical" size={32} style={{ width: "100%" }}>
                                        <Steps
                                            current={current}
                                            onChange={(current) => setCurrent(current)}
                                            labelPlacement="vertical"
                                            style={{ width: "100%" }}
                                            className="steps-new-quotation steps-new-quotation-detail"
                                        >
                                            <Steps.Step
                                                title="Information"
                                                className="steps-item-information"
                                            />
                                            <Steps.Step
                                                title="Prix"
                                                className="steps-item-price"
                                                disabled={!cotation || !cotation.canShowDevis()}
                                            />
                                            <Steps.Step
                                                title="Devis"
                                                className="steps-item-estimate"
                                                disabled={!cotation || !cotation.canShowDevis()}
                                            />
                                        </Steps>
                                    </Space>

                                    {current == 0 && <RoadQuotationDetailInformation cotation={cotation} attachments={attachmentsClient.map(item => item.public_url)} />} 
                                    {current == 1 && devis && <RoadQuotationDetailPrice cotation={cotation} devis={devis} attachments={attachmentsInternal.map(item => item.public_url)} />}
                                    {current == 2 && devis && <RoadQuotationDetailDevis devis={devis} />}
                                    
                                </Col>
                            
                                <Col
                                    className="gutter-row container full-height-content detail-customer-sider-wrapper"
                                    span={9}
                                    style={{ backgroundColor: "#F9FAFB" }}
                                >
                                    <SectionNotesAndTasks customerId={cotation.client_id} reference={cotation.code} />
                                </Col>
                            </Row>
                        </Layout>
                    </>
                }
                </>

                {isEdit && <PanelRoadCotation
                    showModal={isEdit}
                    onClose={() => { setIsEdit(false); setCotation(undefined); fetchData(); }}
                    cotation={cotation}
                />}
            </HasFound>
        </HasPermission>

    )
}