export default class ErrorApi{
    status: number;
    message?: string;
    errors?: Record<string, string[]>;
    error?: string;

    constructor(data: any) {
        this.status = data.status;
        this.message = data.data.message;
        this.errors = data.data.errors;
        this.error = data.data.error;
    }
}