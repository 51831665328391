import { Form, Input, Row } from "antd";
import { Controller } from "react-hook-form";
import FormError from "./form-error";
import React from "react";
import ReadonlyField from "./readonly-field";

interface IProps{
    label: string;
    name: string;
    placeholder: string;
    control: any;
    required?: boolean;
    defaultValue?: string | number;
    error?: any;
    readonly?: boolean;
    contentReadonly?: React.ReactNode;
    prefix?: string;
    prefixComponent?: React.ReactNode;
    labelIsVisible?: boolean;
    disabled?: boolean;
}
export default function FormInput({ label, name, placeholder, control, required, defaultValue, error, readonly, contentReadonly, prefix, labelIsVisible = true, disabled = false, prefixComponent = <></> }: IProps) {
    
    function renderLabelWithContent() {
        return <Form.Item label={`${label} ${required ? '*' : ''}`} required>
               {renderInput()}
            </Form.Item>
    }

    function renderInput() {
        return <>
             <Row style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', gap: '5px'}}>
                    <Controller
                        name={name}
                        defaultValue={defaultValue}
                        control={control}
                        disabled={disabled}
                        render={({ field }: any) => <Input placeholder={placeholder} {...field} />}
                    />
                {prefix && <span style={{ width: 60 }}>{prefix}</span>}
                {prefixComponent} 
                </Row>
                <FormError message={error?.message} />
        </>
    }

    
    return (
        <ReadonlyField readonly={readonly} contentReadonly={contentReadonly} label={label} value={defaultValue}>
            {labelIsVisible ? renderLabelWithContent() : renderInput()}
        </ReadonlyField>
    )
}