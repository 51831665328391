import type { ColumnsType } from "antd/es/table";
import { RoadDemandeDataType } from "../models/RoadDemandeDataType";

const columns: ColumnsType<RoadDemandeDataType> = [
    {
        title: "Date de la demande",
        dataIndex: "created_at",
        key: "created_at",
        sorter: false,
    },
    {
        title: "Client",
        dataIndex: "client",
        key: "client",
        sorter: false,
    },
    {
        title: "Affectation principale",
        dataIndex: "main_assignee",
        key: "main_assignee",
        sorter: false,
    },
    {
        title: "Créée par",
        dataIndex: "created_by",
        key: "created_by",
        sorter: false,
    },
    {
        title: "Chiffrée par",
        dataIndex: "estimated_by",
        key: "estimated_by",
        sorter: false,
    },
    {
        title: "Origine",
        dataIndex: "origin",
        key: "origin",
        sorter: false,
    },
    {
        title:"Destination",
        dataIndex: "destination",
        key: "destination",
        sorter: false,
    },
    {
        title: "Statut",
        dataIndex: "status",
        key: "status",
        sorter: false,
    }
];

export { columns }