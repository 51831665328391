import { Button, Card, Col, Flex, Form, Row, Space } from "antd";
import { SaveOutlined, CheckOutlined } from "@ant-design/icons";
import { Text } from "../../ui/typo/Text";
import * as yup from "yup"
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import DataPaginate from "../../../models/utils/data-paginate";
import customerService from "../../../services/customer.service";
import Customer from "../../../models/customer";
import useDebounce from "../../../utils/hooks/useDebounce";
import FormSelectSingle from "../../form/form-select-single";
import userService from "../../../services/user.service";
import User from "../../../models/user";
import { PlusOutlined } from "@ant-design/icons";
import FormDatePicker from "../../form/form-datepicker";
import FormTimePicker from "../../form/form-timepicker";
import FormNumberInput from "../../form/form-number-input";
import FormCheckbox from "../../form/form-checkbox";
import FormTextarea from "../../form/form-textarea";
import dateHelper from "../../../utils/date.helper";
import roadDemandesService from "../../../services/road-demandes.service";
import { addToast } from "../../../store/toasts/reducer";
import Toast from "../../../models/utils/toast";
import { useAppDispatch } from "../../../store/hooks";
import RoadDemande from "../../../models/RoadDemande";
import dayjs from 'dayjs';
import SubformStopPoint from "../../form/subform-stop-point";
import InputFile from "../../ui/input-file/input-file";
import Devis from "../../../models/Devis";
import axios from "axios";
import FormAddressAutoComplete from "../../form/form-address-auto-complete";
import FormInput from "../../form/form-input";
import { ROAD_QUOTATION_DANGEROUS_CLASSES, ROAD_QUOTATION_OPTIONS } from "./types/constants";
import Account from "../../../models/account";

type Inputs = {
    client_id: string,
    contact_id: string | null,
    main_assignment: number | null,
    secondary_assignment: string,
    origin: string,
    destination: string,
    stop_points: any[],
    origin_date_date_start: Date,
    origin_date_hours_start: dayjs.Dayjs,
    origin_date_date_end: Date,
    origin_date_hours_end: dayjs.Dayjs,
    origin_date_hours: Date,
    destination_date_date_start: Date,
    destination_date_hours_start: dayjs.Dayjs,
    destination_date_date_end: Date,
    destination_date_hours_end: dayjs.Dayjs,
    destination_date_hours: Date,
    features: FeatureObject
}

type FeatureObject = {
    unit: string,
    quantity: string,
    total_weight: number,
    dangerous_substances_class: string,
    is_controlled_temperature: boolean,
    controlled_temperature_min?: number;
    controlled_temperature_max?: number;
    has_option_goods_on_returnable_pallets: boolean,
    nb_returnable_pallets?: number,
    goods_value: number
    is_import_customs: boolean,
    is_export_customs: boolean,
    required_equipment: string,
    instructions: string,
}

interface IProps{
    cotation?: RoadDemande
    devis?: Devis
    canEdit: boolean
    onSaved: (cotation: RoadDemande) => void
    onValidate: () => void;
}
const attachmentType = 'client';
export default function FormQuotationInformations({ cotation, devis, canEdit, onSaved, onValidate }: IProps) {

    const schema: any = yup.object().shape({
        client_id: yup.string().required("Le nom du client est requis !"),
        contact_id: yup.string().nullable(),
        main_assignment: yup.string().required("L'affectation principale est requise !"),
        secondary_assignment: yup.string().nullable(),
        origin: yup.string().required("L'adresse de chargement est requise !"),
        destination: yup.string().required("La destination de chargement est requise !"),
        stop_points: yup.array().of(yup.object().shape({
            address: yup.string().required("L'adresse de l'arrêt est requise !"),
            type: yup.string().required("Le type de l'arrêt est requis !"),
            date_start_date: yup.string().nullable(),
            date_start_hours: yup.string().nullable(),
            date_end_date: yup.string().nullable(),
            date_end_hours: yup.string().nullable(),
        })),

        origin_date_date_start: yup.string().nullable(),
        origin_date_hours_start: yup.string().nullable(),
        origin_date_date_end: yup.string().nullable(),
        origin_date_hours_end: yup.string().nullable(),

        destination_date_date_start: yup.string().nullable(),
        destination_date_hours_start: yup.string().nullable(),
        destination_date_date_end: yup.string().nullable(),
        destination_date_hours_end: yup.string().nullable(),
       
        features: yup.object().shape({
            unit: yup.string().required("Le choix de l'unité est requise!"),
            quantity: yup.number().required("La quantité est requise!").min(1, "La quantité doit être supérieur à 0"),
            total_weight: yup.number().transform((value, originalValue) => {
                return originalValue === '' ? NaN : value;
            }).required("Le poids total est requis!")
             .typeError("Le poids total doit être un nombre"),
            dangerous_substances_class: yup.string(),
            is_controlled_temperature: yup.boolean(),
            controlled_temperature_min: yup.string(),
            controlled_temperature_max: yup.string(),
            goods_value: yup.number().transform((value, originalValue) => {
                return originalValue === '' ? NaN : value;
            }).typeError("La valeur de marchandise doit être un nombre"),
            is_import_customs: yup.boolean(),
            is_export_customs: yup.boolean(),
            has_option_goods_on_returnable_pallets: yup.boolean(),
            required_equipment: yup.string(),
            instructions: yup.string(),
        })
    });
    const {
        handleSubmit,
        control,
        formState: { isDirty, isValid, errors },
        watch,
        setValue
    } = useForm<Inputs>({
        resolver: yupResolver(schema), mode: 'onChange',
        defaultValues: {
            stop_points: cotation?.stop_points ? cotation.stop_points.map(stop_point => {
                const res =  {
                    ...stop_point,
                    date_start_date: stop_point.date_start ? dayjs(stop_point.date_start) : '',
                    date_start_hours: stop_point.date_start ? dayjs(stop_point.date_start) : '',
                    date_end_date: stop_point.date_start ? dayjs(stop_point.date_end) : '',
                    date_end_hours: stop_point.date_end ? dayjs(stop_point.date_end): '',
                    address: stop_point && stop_point.address ? stop_point.address.raw : ''
                }

                return res;
            }) : [],
        }
    })

     const { fields, append, remove } = useFieldArray({
        control: control as any,
         name: "stop_points", // Nom de votre champ dans le formulaire
     });
    
    const clientId = watch('client_id');
    const contactId = watch('contact_id');
    const isControlledTemperature = watch('features.is_controlled_temperature');
    const hasOptionGoodsOnReturnablePallets = watch('features.has_option_goods_on_returnable_pallets');
    const originDateDateStart = watch('origin_date_date_start');
    const originDateDateEnd = watch('origin_date_date_end');
    const destinationDateDateStart = watch('destination_date_date_start');
    const destinationDateDateEnd = watch('destination_date_date_end');
    
    const dispatch = useAppDispatch();
    const [initialsClientsPaginaged, setInitialsClientsPaginated] = useState<DataPaginate>();
    const [clientsPaginated, setClientsPaginated] = useState<DataPaginate>();
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [clientSearch, setClientSearch] = useState<string>('');
    const [users, setUsers] = useState<User[]>([]);
    const [userSearch, setUserSearch] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [tmpClientFiles, setTmpClientFiles] = useState<any>([]);
    const [tmpDeletedClientFiles, setTmpDeletedClientFiles] = useState<string[]>([]);
    const [attachments, setAttachments] = useState<string[]>([]);
    const [agenciesPage, setAgenciesPage] = useState(1);

    useEffect(() => {
        async function fetchData() {
            if (clientId) {
                try {
                    const customer = await customerService.find(Number(clientId));
                    if (customer && customer.accounts) {
                        setAccounts(customer.accounts);
                        if (customer.accounts.find(a => a.id === Number(contactId)) === undefined) {
                            if (customer.accounts.length) {
                                setValue('contact_id', customer.accounts[0].id.toString());
                            } else { 
                                setValue('contact_id', null)
                            }
                        }

                        if (customer.assigned_user) {
                            setValue('main_assignment', customer.assigned_user.id);
                        } 
                    }

                } catch (e) {
                    console.log(e);
                }
            }
        }
        fetchData();
    }, [clientId])

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await customerService.list(agenciesPage, clientSearch);
                if (agenciesPage > 1 && clientsPaginated) {
                    res.setData([...clientsPaginated.data, ...res.data]);
                }
                setClientsPaginated(res);
                if(!initialsClientsPaginaged){
                    setInitialsClientsPaginated(res);
                }
            } catch (e) {
                console.log(e);
            }
        }
        fetchData();
    }, [clientSearch, agenciesPage])

    useEffect(() => {
        async function fetchData() {
            if (clientId) {
                 try {
                    const res = await customerService.getGrantedUsers(parseInt(clientId), userSearch);
                    setUsers(res);
                } catch (e) {
                    console.log(e);
                }
            }
           
        }
        fetchData();
    }, [userSearch, clientId])

    useEffect(() => {
        if (isControlledTemperature === false) {
            setValue('features.controlled_temperature_min', undefined)
            setValue('features.controlled_temperature_max', undefined)
        }
    }, [isControlledTemperature])

    useEffect(() => {
        if (hasOptionGoodsOnReturnablePallets === false) {
            setValue('features.nb_returnable_pallets', undefined)
        }
    }, [hasOptionGoodsOnReturnablePallets])

    useEffect(() => {
        setValue('origin_date_date_end', originDateDateStart)
        if (originDateDateStart) {
            const M2 = dayjs();
            const newDate = M2.hour(0).minute(0).second(0).millisecond(0);

            setValue('origin_date_hours_start', newDate)
        }
    }, [originDateDateStart])

    useEffect(() => {
        if(originDateDateEnd){
            const M2 = dayjs();
            const newDate = M2.hour(0).minute(0).second(0).millisecond(0);
            setValue('origin_date_hours_end', newDate)
        }
    }, [originDateDateEnd])


    useEffect(() => {
        setValue('destination_date_date_end', destinationDateDateStart)

        if (destinationDateDateStart) {
            const M2 = dayjs();
            const newDate = M2.hour(0).minute(0).second(0).millisecond(0);

            setValue('destination_date_hours_start', newDate)
        }
    }, [destinationDateDateStart])

    useEffect(() => {
        if(destinationDateDateEnd){
            const M2 = dayjs();
            const newDate = M2.hour(0).minute(0).second(0).millisecond(0);
            setValue('destination_date_hours_end', newDate)
        }
    }, [destinationDateDateEnd])

    const clientOptions = useMemo(() => {
        if (!clientsPaginated || !clientsPaginated.data) return [];

        const res = clientsPaginated.data.map((customer: Customer) => ({ label: `${customer.name} (${customer.code})`, value: customer.id }));
        if (cotation && cotation.client && !res.find(c => c.value === cotation?.client?.id)) {
            res.unshift({ label: `${cotation.client.name} (${cotation.client.code})`, value: cotation.client.id });
        }

        return res;
    }, [clientsPaginated, cotation]);

    const accountOptions = useMemo(() => {
        if (!accounts) return [];
        return accounts.map((account: Account) => ({ label: account.getLabel(), value: account.id.toString() }));
    }, [accounts])

    const handleSearchClient = useDebounce((term) => {
        setAgenciesPage(1);
        setClientSearch(term);
    } , 500);
    const userOptions = useMemo(() => {
        return users.map((user: User) => ({ label: user.getFullName(), value: user.id }));
    }, [users, cotation]);

    const unitOptions = useMemo(() => ROAD_QUOTATION_OPTIONS, []);

    const dangerousClassOptions = useMemo(() => ROAD_QUOTATION_DANGEROUS_CLASSES, [])

    const handleSearchUser = useDebounce((term) => setUserSearch(term), 500);

    async function onSubmit(data: any) {
  
        try {
            setLoading(true)
            const {
                stop_points,
                origin_date_date_start, origin_date_hours_start,
                origin_date_date_end, origin_date_hours_end,
                destination_date_date_start, destination_date_hours_start,
                destination_date_date_end, destination_date_hours_end,
                features,
                ...rest
            } = data;

            const {
                has_option_goods_on_returnable_pallets,
                is_controlled_temperature,
                ...restFeatures
            } = features;


            const body = {
                ...rest,
                stop_points: stop_points.map((stop: any) => {
                    const { date_start_date, date_start_hours, date_end_date, date_end_hours, ...rest } = stop;
                    return {
                        ...rest,
                        date_start: dateHelper.formatDateAndTime(date_start_date, date_start_hours),
                        date_end: dateHelper.formatDateAndTime(date_end_date, date_end_hours),
                    }
                }),
                origin_date_start: dateHelper.formatDateAndTime(origin_date_date_start, origin_date_hours_start),
                origin_date_end: dateHelper.formatDateAndTime(origin_date_date_end, origin_date_hours_end),
                destination_date_start: dateHelper.formatDateAndTime(destination_date_date_start, destination_date_hours_start),
                destination_date_end: dateHelper.formatDateAndTime(destination_date_date_end, destination_date_hours_end),
                features: restFeatures
            }

            let res = null;
            if (!cotation) {
                res = await roadDemandesService.create(body);
                onSaved(res);
                dispatch(addToast(new Toast("La cotation de transport a été ajouté !", "success")));
            } else {
                res = await roadDemandesService.update(cotation.id, body);
                onSaved(res);
                dispatch(addToast(new Toast("La cotation de transport a été modifié !", "success")));
            }

            if (tmpClientFiles && tmpClientFiles.length > 0) {
                for (const file of tmpClientFiles) {
                    await roadDemandesService.uploadAttachment(res.id, file, attachmentType);
                }
                setTmpClientFiles([]);
            }

            if (tmpDeletedClientFiles && tmpDeletedClientFiles.length > 0) {
                for (const file of tmpDeletedClientFiles) {
                    const filename = file.split("/").pop() || '';
                    await roadDemandesService.deleteAttachment(res.id, filename, attachmentType);
                }
                setTmpDeletedClientFiles([]);
            }

            await fetchAttachments();
            
        } catch (error) {
            dispatch(addToast(new Toast("Une erreur est survenue pendant l'ajout de la cotation de transport !", "error")));
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchAttachments();
    }, [cotation])
    
    async function fetchAttachments() {
        if (cotation) {
            const res = await roadDemandesService.getAttachments(cotation.id, attachmentType);
            setAttachments(res.map((item: any) => item.public_url));
        }
    }

    async function onDeleteAttachment(url: string) {
        setTmpDeletedClientFiles([...tmpDeletedClientFiles, url]);
        setAttachments(attachments.filter(a => a !== url));
    }

    async function onViewAttachment(url: string) {
        try {
            const response: any = await axios.get(url, {
                responseType: 'blob'
            });
            const blob = response.data;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            const parts = url.split("/");
            const filename = parts[parts.length - 1];
            link.download = `${filename}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Erreur lors du téléchargement du fichier :", error);
            dispatch(addToast(new Toast(`Une erreur est survenue pendant le téléchargement du fichier !`, 'error')))
        }     
    }

    async function validate() {
        try {
            if (!cotation) return;
            await roadDemandesService.validate(cotation.id);
            dispatch(addToast(new Toast("La cotation de transport a été validé pour le chiffrage !", "success")));
            onValidate();
        } catch (error) {
            dispatch(addToast(new Toast("Une erreur est survenue pendant la validation de la cotation de transport !", "error")));
            console.log(error);
        }
    }

    return (
        <Form style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} layout="vertical" requiredMark={false} onSubmitCapture={handleSubmit(onSubmit)}>
            
            
            <Flex justify="space-between" style={{ flexDirection: "column", height: '100%' }}>
                <div style={{ height: '100%', overflow: 'scroll' }}>

                    {devis && devis.isPending() &&
                        <Card style={{borderColor: '#990717', marginBottom: 10}}>
                            <Text target="Regular" style={{color:'#990717'}} >La cotation de transport est en cours de validation, vous ne pouvez plus la modifier !</Text>
                        </Card>
                    }

                    {devis && devis.isAccepted() &&
                        <Card style={{borderColor: '#079455', marginBottom: 10}}>
                            <Text target="Regular" style={{color:'#079455'}} >La cotation de transport a été validée, vous ne pouvez plus la modifier !</Text>
                        </Card>
                    }
                
                    <Col span={24}>
                        <FormSelectSingle
                            label="Client"
                            placeholder="Sélectionner un client"
                            name="client_id"
                            defaultValue={cotation?.client_id}
                            control={control}
                            options={clientOptions}
                            onSearch={handleSearchClient}
                            required
                            disabled={!canEdit}
                            hasCustomFilter={true}
                            loadMore={() => {
                                if (clientsPaginated?.last_page && agenciesPage < clientsPaginated?.last_page) {
                                    setAgenciesPage(agenciesPage + 1)
                                }
                            }
                            }
                        />

                        <FormSelectSingle
                            label="Contact"
                            placeholder="Sélectionner un contact"
                            name="contact_id"
                            defaultValue={cotation?.contact_id}
                            control={control}
                            options={accountOptions}
                            onSearch={() => null}
                             disabled={!canEdit}
                        />
                    </Col>
                    <Row gutter={16}>
                        <Col span={12}>
                            <FormSelectSingle
                                label="Affectation principale"
                                placeholder="Rechercher une personne"
                                name="main_assignment"
                                defaultValue={cotation?.main_assignment}
                                control={control}
                                options={userOptions}
                                onSearch={handleSearchUser}
                                required
                                disabled={!canEdit}
                                hasCustomFilter={true}
                            />
                        </Col>

                        <Col span={12}>
                            <FormSelectSingle
                                label="Affectation secondaire"
                                placeholder="Rechercher une personne"
                                name="secondary_assignment"
                                defaultValue={cotation?.secondary_assignment}
                                control={control}
                                options={userOptions}
                                onSearch={handleSearchUser}
                                disabled={!canEdit}
                                hasCustomFilter={true}
                            />
                        </Col>
                    </Row>

                    <Space direction="vertical" size={16} style={{ width: "100%" }}>
                        <Text target="SectionTitle">Détails du trajet</Text>

                        <FormAddressAutoComplete
                            label="Adresse de chargement"
                            required
                            name="origin"
                            placeholder="Entrer l'adresse"
                            defaultValue={cotation?.origin?.raw}
                            control={control}
                            error={errors?.origin}
                            disabled={!canEdit}
                        />

                         <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <FormDatePicker
                                    label="Date de début"
                                    name="origin_date_date_start"
                                    defaultValue={cotation?.origin_date_start ? dayjs(cotation?.origin_date_start) : null}
                                    control={control}
                                    error={errors?.origin_date_date_start}
                                    placeholder="-- / -- / ----"
                                    disabled={!canEdit}
                                />
                            </Col>
                            <Col span={6}>
                                <FormTimePicker
                                    label="Heure de début"
                                    name="origin_date_hours_start"
                                    defaultValue={cotation?.origin_date_start ? dayjs(cotation?.origin_date_start) : undefined}
                                    control={control}
                                    error={errors?.origin_date_hours}
                                    placeholder="-- : --"
                                    disabled={!canEdit}
                                />
                            </Col>

                            <Col span={6}>
                                <FormDatePicker
                                    label="Date de fin"
                                    name="origin_date_date_end"
                                    defaultValue={cotation?.origin_date_end ? dayjs(cotation?.origin_date_end) : null}
                                    control={control}
                                    error={errors?.origin_date_date_end}
                                    placeholder="-- / -- / ----"
                                    disabled={!canEdit}
                                />
                            </Col>
                            <Col span={6}>
                                <FormTimePicker
                                    label="Heure de fin"
                                    name="origin_date_hours_end"
                                    defaultValue={cotation?.origin_date_end ? dayjs(cotation?.origin_date_end) : undefined}
                                    control={control}
                                    error={errors?.origin_date_hours}
                                    placeholder="-- : --"
                                    disabled={!canEdit}
                                />
                            </Col>
                        </Row>

                        <FormAddressAutoComplete
                            label="Adresse de livraison"
                            required
                            name="destination"
                            placeholder="Entrer l'adresse"
                            defaultValue={cotation?.destination?.raw}
                            control={control}
                            error={errors?.destination}
                            disabled={!canEdit}
                        />

                         <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <FormDatePicker
                                    label="Date de début"
                                    name="destination_date_date_start"
                                    defaultValue={cotation?.destination_date_start ? dayjs(cotation?.destination_date_start) : null}
                                    control={control}
                                    error={errors?.destination_date_date_start}
                                    placeholder="-- / -- / ----"
                                    disabled={!canEdit}
                                />
                            </Col>
                            <Col span={6}>
                                <FormTimePicker
                                    label="Heure de début"
                                    name="destination_date_hours_start"
                                    defaultValue={cotation?.destination_date_start ? dayjs(cotation?.destination_date_start) : undefined}
                                    control={control}
                                    error={errors?.destination_date_hours}
                                    placeholder="-- : --"
                                    disabled={!canEdit}
                                />
                            </Col>

                            <Col span={6}>
                                <FormDatePicker
                                    label="Date de fin"
                                    name="destination_date_date_end"
                                    defaultValue={cotation?.destination_date_end ? dayjs(cotation?.destination_date_end) : null}
                                    control={control}
                                    error={errors?.destination_date_date_end}
                                    placeholder="-- / -- / ----"
                                    disabled={!canEdit}
                                />
                            </Col>
                            <Col span={6}>
                                <FormTimePicker
                                    label="Heure de fin"
                                    name="destination_date_hours_end"
                                    defaultValue={cotation?.destination_date_end ? dayjs(cotation?.destination_date_end) : undefined}
                                    control={control}
                                    error={errors?.destination_date_hours}
                                    placeholder="-- : --"
                                    disabled={!canEdit}
                                />
                            </Col>
                        </Row>

                        <SubformStopPoint
                            fieldName="stop_points"
                            control={control}
                            fields={fields}
                            onRemove={remove}
                            errors={errors}
                            canEdit={canEdit}
                            setValue={setValue}
                        />

                        <Button
                            type="default"
                            icon={<PlusOutlined size={20} />}
                            onClick={() => append({
                                address: "",
                                type: "",
                                date_start_date: "",
                                date_start_hours: undefined,
                                date_end_date: "",
                                date_end_hours: undefined
                            })}
                            style={{ width: "100%" }}
                            disabled={!canEdit}
                        >
                            <Text target="Bold">Ajouter un arrêt suplémentaire</Text>
                        </Button>

                    </Space>

                    <Space direction="vertical" size={16} style={{ width: "100%", marginTop: 20 }}>
                        <Text target="SectionTitle">Détails sur la marchandise</Text>
                     
                        <Row gutter={16}>
                            <Col span={8}>
                                <FormSelectSingle
                                    label="Choix de l'unité"
                                    placeholder="Choisir unité"
                                    name="features.unit"
                                    defaultValue={cotation?.features?.unit}
                                    control={control}
                                    options={unitOptions}
                                    onSearch={() => console.log('todo')}
                                    required
                                    disabled={!canEdit}
                                />
                            </Col>
                            
                            <Col span={8}>
                                <FormNumberInput
                                    label="Nombre"
                                    name="features.quantity"
                                    defaultValue={cotation?.features?.quantity ? cotation?.features?.quantity : 1}
                                    control={control}
                                    required
                                    error={errors?.features?.quantity}
                                    disabled={!canEdit}
                                />
                            </Col>
                            <Col span={8}>
                                <FormInput
                                    label="Poids en kg"
                                    required
                                    name="features.total_weight"
                                    placeholder="Poids en kg"
                                    control={control}
                                    error={errors?.features?.total_weight}
                                    defaultValue={cotation?.features?.total_weight}
                                    disabled={!canEdit}
                                />
                                
                            </Col>
                        </Row>
                           

                           
                        <FormSelectSingle
                            label="Matière dangereuse"
                            placeholder="Choisir la matière"
                            name="features.dangerous_substances_class"
                            defaultValue={cotation?.features?.dangerous_substances_class}
                            control={control}
                            options={dangerousClassOptions}
                            onSearch={() => null}
                            disabled={!canEdit}
                        />

                        <Row gutter={16}>
                            <Col span={8}>
                                <FormCheckbox
                                    label="Température dirigée"
                                    name="features.is_controlled_temperature"
                                    defaultValue={cotation?.features?.controlled_temperature_min != null || cotation?.features?.controlled_temperature_max != null}
                                    control={control}
                                    disabled={!canEdit}
                                />
                            </Col>
                            {isControlledTemperature && <>
                                <Col span={8}>
                                    <FormInput
                                        label="Minimum"
                                        prefix="°C"
                                        placeholder="Minimum"
                                        name="features.controlled_temperature_min"
                                        defaultValue={cotation?.features?.controlled_temperature_min}
                                        control={control}
                                        required
                                        error={errors?.features?.controlled_temperature_min}
                                        disabled={!canEdit}
                                    />
                                    
                                </Col>
                                <Col span={8}>
                                    <FormInput
                                        label="Maximum"
                                        prefix="°C"
                                        placeholder="Maximum"
                                        name="features.controlled_temperature_max"
                                        defaultValue={cotation?.features?.controlled_temperature_max}
                                        control={control}
                                        required
                                        error={errors?.features?.controlled_temperature_max}
                                        disabled={!canEdit}
                                    />
                                </Col>
                            </>}
                        </Row>
                        
                        <Row gutter={16}>
                            <Col span={8}>
                                <FormCheckbox
                                    label="Palettes consignées"
                                    name="features.has_option_goods_on_returnable_pallets"
                                    defaultValue={cotation?.features?.nb_returnable_pallets != null && cotation?.features?.nb_returnable_pallets != 0}
                                    control={control}
                                    disabled={!canEdit}
                                />
                            </Col>

                            {hasOptionGoodsOnReturnablePallets && <Col span={4}>
                                <FormInput
                                    label="Nombre"
                                    placeholder="Nombre"
                                    name="features.nb_returnable_pallets"
                                    defaultValue={cotation?.features?.nb_returnable_pallets}
                                    control={control}
                                    required
                                    error={errors?.features?.nb_returnable_pallets}
                                    disabled={!canEdit}
                                />
                            </Col>}
                        </Row>

                        <Col span={8}>
                            <FormInput
                                label="Valeur de la marchandise"
                                prefix="€ HT"
                                placeholder="Valeur de la marchandise"
                                name="features.goods_value"
                                defaultValue={cotation?.features?.goods_value}
                                control={control}
                                error={errors?.features?.goods_value}
                                disabled={!canEdit}
                            />
                        </Col>
                    </Space>

                    <Space direction="vertical" size={16} style={{ width: "100%" }}>
                        <Text target="SectionTitle">Informations complémentaires</Text>

                        <FormTextarea
                            label="Matériel exigé"
                            name="features.required_equipment"
                            defaultValue={cotation?.features?.required_equipment}
                            placeholder="Lister le matériel exigé"
                            control={control}
                            error={errors?.features?.required_equipment}
                            disabled={!canEdit}
                        />

                        <FormTextarea
                            label="Instructions"
                            name="features.instructions"
                            defaultValue={cotation?.features?.instructions}
                            placeholder="Entrer les instructions"
                            control={control}
                            error={errors?.features?.instructions}
                            disabled={!canEdit}
                        />

                        <Form.Item label="Pièce(s) jointe(s)" >
                            <InputFile
                                onUpload={(file: any) => setTmpClientFiles(file)}
                                existingUrls={attachments}
                                accept={['.pdf']}
                                onDelete={(url) => onDeleteAttachment(url)}
                                onView={(url) => onViewAttachment(url)}
                                disabled={!canEdit}
                            />
                        </Form.Item>
                    </Space>
                </div>

                <div className="ant-drawer-footer">
                    <Space
                        direction="vertical"
                        size={16}
                        style={{ width: "100%" }}
                        align="end"
                    >
                        <Space
                            size={16}
                            style={{ justifyContent: "flex-end", width: "100%" }}
                        >
                            <Button type="primary" htmlType="submit" disabled={!isDirty || !isValid || loading || !canEdit}>
                                <Space size={6}>
                                    <SaveOutlined />
                                    <Text target="Bold">Sauvegarder</Text>
                                </Space>
                            </Button>
                        
                            <Button type="primary" onClick={() => validate()} disabled={(!cotation?.canValidate() || !cotation.main_assignee)}>
                                <Space size={6}>
                                    <CheckOutlined />
                                    <Text target="Bold">Valider pour chiffrage</Text>
                                </Space>
                            </Button>
        
                        </Space>
                    </Space>
                </div>
            </Flex>
        </Form>
    )
}