import { DatePicker, Form, Input } from "antd";
import { Controller } from "react-hook-form";
import FormError from "./form-error";
import ReadonlyField from "./readonly-field";
import moment from 'moment';

interface IProps{
    label: string;
    name: string;
    control: any;
    required?: boolean;
    defaultValue?: any;
    error?: any;
    readonly?: boolean;
    contentReadonly?: React.ReactNode;
    placeholder?: string;
    disabled?: boolean;
}
export default function FormDatePicker({ label, name, control, required, defaultValue, error, readonly, contentReadonly, placeholder, disabled = false }: IProps) {
    
    return (
        <ReadonlyField readonly={readonly} contentReadonly={contentReadonly} label={label} value={defaultValue}>
            <Form.Item label={`${label} ${required ? '*' : ''}`} required style={readonly ? {padding: 0}:  {}}>
                {!readonly && <Controller
                    name={name}    
                    defaultValue={defaultValue}
                    control={control}
                    disabled={disabled}
                    render={({ field }: any) => <DatePicker
                        placeholder={placeholder}
                        format={'DD/MM/YYYY'}
                        {...field}
                        style={{ width: "100%" }}
                    />}
                />}
                {readonly && <>
                    {contentReadonly}
                </>}
                <FormError message={error?.message} />
            </Form.Item>
        </ReadonlyField>
    )
}