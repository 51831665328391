import { Button, Empty, Flex, Space, Table } from "antd";
import { Icon } from "../../ui/typo/Icon";
import { Text } from "../../ui/typo/Text";
import { useEffect, useMemo, useState } from "react";
import DataPaginate from "../../../models/utils/data-paginate";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Task, { ETaskStatus } from "../../../models/task";
import taskService from "../../../services/task.service";
import { columns } from "../../../configs/TaskMinTableColumns";
import LabelTaskName from "../notes/label-task-name";
import Avatar from "../../ui/avatar/avatar";
import dateHelper from "../../../utils/date.helper";
import StatusTask from "./status-task";
import { addToast } from "../../../store/toasts/reducer";
import Toast from "../../../models/utils/toast";
import useTable from "../../../utils/hooks/useTable";
import PanelFormTask from "./panel-form-task";
import DeletionModal from "../../ui/modal/deletion-modal";
import { setRemainingTasks } from "../../../store/tasks/reducer";

interface IProps{
    customerId: number;
    reference?: string;
}
export default function TasksList({ customerId, reference }: IProps) {
    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(1);
    const { selectedRowKeys, rowSelection, visibleColumns, renderColumnsAndSelection, showModalDeletion, setShowModalDeletion, resetRowSelection } = useTable(columns)
    const [editingTask, setEditingTask] = useState<Task>();
    const dispatch = useAppDispatch();
    const {value : user} = useAppSelector(state => state.user);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const res = await taskService.list(page, undefined, undefined, customerId, reference);
                setDatapaginate(res);
                await refreshMenuTasksRemaining();
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [page, customerId, reference])

    const dataTable = useMemo(() => {
        if (!dataPaginate || !dataPaginate.data) return [];
        return dataPaginate.data.map((item: Task) => {
            return {
                id: item.id,
                key: item.id,
                title: <LabelTaskName task={item} /> ,
                user: item.user ? <Avatar placeholder={item.user.getLettersName()} /> : '-',
                due_date: item.due_date ? dateHelper.dateToDDMMYYYY(item.due_date) : '-',
                status: <StatusTask task={item} />,
                reference: item.reference || '',
            }
        });
    }, [dataPaginate]);


    async function onClose(refresh?: boolean) {
        setShowModal(false)
        setEditingTask(undefined);
        if (refresh) {
            try {
                setLoading(true);
                const res = await taskService.list(page, undefined, undefined, customerId, reference);
                res.setData(res.data.map(item => new Task(item)));
                setDatapaginate(res);
                await refreshMenuTasksRemaining();
                
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
                resetRowSelection();
            }
        }
    }

    async function onEditTask() {
        const selectedTask = dataPaginate?.data?.find(item => item.id === selectedRowKeys[0]);

        if (selectedTask) {
            const task = await taskService.find(selectedTask.id);
            setEditingTask(task);
            setShowModal(true);
        }
    }

    async function onDelete() {
        try {
            setLoading(true);
            const listToDelete = dataPaginate?.data?.filter(item => selectedRowKeys.includes(item.id));
            if (listToDelete) {
                for (const item of listToDelete) {
                    await taskService.destroy(item.id);

                }
                dispatch(addToast(new Toast('Les tâches ont étaient supprimés avec succès', 'success')))
                if(dataPaginate && dataPaginate.data.length === selectedRowKeys.length){
                    setPage(page - 1)
                } else {
                    const res = await taskService.list(page, undefined, undefined, customerId, reference);
                    setDatapaginate(res);
                }
                await refreshMenuTasksRemaining();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            setShowModalDeletion(false);
            resetRowSelection();
        }
    }

    async function refreshMenuTasksRemaining() {
        if (user) {
            const tasksPaginated = await taskService.list(1, '', user.id, undefined, undefined, ETaskStatus.TODO);
            dispatch(setRemainingTasks(tasksPaginated.total));
        }
    }
    
    return <>
    
        <Space style={{ overflow: 'auto' }} direction="vertical" size={16} className="chat-box">
            {!dataPaginate && !loading && <Empty />}
            {dataPaginate && <>
                  <Table
                        rowKey={"id"}
                        loading={loading}
                        columns={renderColumnsAndSelection(visibleColumns, selectedRowKeys, onEditTask)}
                        dataSource={dataTable}
                        scroll={{ x: 400, y: 500 }}
                        showSorterTooltip={false}
                        pagination={{
                            current: page,
                            pageSize: dataPaginate?.per_page || 10,
                            total: dataPaginate?.total || 0,
                            onChange: (page) => {
                                setPage(page)
                            },
                        }}
                    rowSelection={rowSelection}
                    onRow={(record) => {
                            return {
                                onClick: () => {
                                    const task = dataPaginate?.data?.find(item => item.id === record.id);
                                    if (task) {
                                        setEditingTask(task);
                                        setShowModal(true);
                                    }
                                }
                            }
                        }}
                    />
            </>}
        </Space>
        <Flex gap={12} className="send-message-box" justify="end">
            <Button type="primary" onClick={() => setShowModal(true)}>
                <Space size={6}>
                    <Icon path="/icons/task-2.svg" size={20} />
                    <Text target="Bold">Créer une tâche</Text>
                </Space>
            </Button>
        </Flex>

        {showModal && <PanelFormTask
            task={editingTask}
            showModal={showModal}
            onClose={(refresh) => onClose(refresh)}
            customerId={customerId}
            reference={reference}
        />}
        {showModalDeletion && <DeletionModal
            modalOpen={showModalDeletion}
            title="Suppression de tâches"
            description="Voulez-vous vraiment supprimer ces tâches ?"
            onCancel={() => setShowModalDeletion(false)}
            onConfirm={() => onDelete()}
        />}
    </>

    return 
}