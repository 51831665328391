import { Row, Col, Space, Table } from "antd";
import { Text } from "../../../components/ui/typo/Text";
import Customer from "../../../models/customer";
import CustomerContactList from "./customer-contact-list";

interface IProps{
    customer: Customer
}
export default function CustomerGeneralInformations({customer}: IProps) {
    return (
    <Space direction="vertical" size={16} style={{ width: "100%" }}>
        <Text target="SectionTitle">Informations générales</Text>
        <Row gutter={[0, 16]}>
                <Col className="gutter-row" span={12}>
                <Space direction="vertical" size={0}>
                    <Text target="Bold">Numéro de client</Text>
                        <Text target="Regular">{customer.code ? customer.code : '-'}</Text>
                </Space>
                </Col>
                <Col className="gutter-row" span={12}>
                <Space direction="vertical" size={0}>
                    <Text target="Bold">Agence de rattachement</Text>
                    <Text target="Regular">{ customer.agency?.name}</Text>
                </Space>
                </Col>
                <Col className="gutter-row" span={12}>
                <Space direction="vertical" size={0}>
                    <Text target="Bold">Contact</Text>
                        <Text target="Regular">{ customer.contact || '-'}</Text>
                </Space>
                </Col>
                <Col className="gutter-row" span={12}>
                <Space direction="vertical" size={0}>
                    <Text target="Bold">Email</Text>
                        <Text target="Regular">{ customer.email || '-'}</Text>
                </Space>
                </Col>
                <Col className="gutter-row" span={12}>
                <Space direction="vertical" size={0}>
                    <Text target="Bold">Ville</Text>
                        <Text target="Regular">{customer.city || '-'}</Text>
                </Space>
                </Col>
                <Col className="gutter-row" span={12}>
                <Space direction="vertical" size={0}>
                    <Text target="Bold">Pays</Text>
                        <Text target="Regular">{customer.country || '-'}</Text>
                </Space>
                </Col>
                <Col className="gutter-row" span={12}>
                <Space direction="vertical" size={0}>
                    <Text target="Bold">Affréteur</Text>
                        <Text target="Regular">{ customer.sector2 || '-'}</Text>
                </Space>
                </Col>
            </Row>

            <CustomerContactList accounts={customer.accounts} />
        </Space>
    );
}