import Customer from "./customer";
import Place from "./place";
import User from "./user";

export default class RoadDemandeFeatures{

    unit?: string;
    quantity?: number
    total_weight?: number
    dangerous_substances_class? : boolean
    is_controlled_temperature?: boolean
    controlled_temperature_min?: number
    controlled_temperature_max?: number
    has_option_goods_on_returnable_pallets?: boolean
    nb_returnable_pallets?: number
    goods_value?: number

    is_import_customs? : boolean
    is_export_customs? : boolean
    has_fuel_surtax? : boolean
    has_option_tailgate? : boolean
    has_option_ad_valorem_insurance? : boolean
    option_ad_valorem_insurance_value? : number
    has_option_miscellaneous_costs? : boolean
    option_miscellaneous_costs_value? : number
    required_equipment? : string
    instructions? : string

    constructor(data: any) {
        if(data.unit){
            this.unit = data.unit
        }
        if(data.quantity){
            this.quantity = data.quantity
        }
        if (data.total_weight) {
            this.total_weight = data.total_weight
        }
        if(data.dangerous_substances_class){
            this.dangerous_substances_class = data.dangerous_substances_class
        }
        if(data.is_controlled_temperature){
            this.is_controlled_temperature = data.is_controlled_temperature
        }
        if (data.controlled_temperature_min) {
            this.controlled_temperature_min = data.controlled_temperature_min
        }
        if (data.controlled_temperature_max) {
            this.controlled_temperature_max = data.controlled_temperature_max
        }
        if(data.has_option_goods_on_returnable_pallets){
            this.has_option_goods_on_returnable_pallets = data.has_option_goods_on_returnable_pallets
        }
        if (data.nb_returnable_pallets) {
            this.nb_returnable_pallets = data.nb_returnable_pallets
        }
        if (data.goods_value) {
            this.goods_value = data.goods_value
        }


        if(data.is_import_customs){
            this.is_import_customs = data.is_import_customs
        }
        if(data.is_export_customs){
            this.is_export_customs = data.is_export_customs
        }
        if(data.has_fuel_surtax){
            this.has_fuel_surtax = data.has_fuel_surtax
        }
        if(data.has_option_tailgate){
            this.has_option_tailgate = data.has_option_tailgate
        }
        if(data.has_option_ad_valorem_insurance){
            this.has_option_ad_valorem_insurance = data.has_option_ad_valorem_insurance
        }
        if(data.option_ad_valorem_insurance_value){
            this.option_ad_valorem_insurance_value = data.option_ad_valorem_insurance_value
        }
        if(data.has_option_miscellaneous_costs){
            this.has_option_miscellaneous_costs = data.has_option_miscellaneous_costs
        }
        if(data.option_miscellaneous_costs_value){
            this.option_miscellaneous_costs_value = data.option_miscellaneous_costs_value
        }
        if(data.required_equipment){
            this.required_equipment = data.required_equipment
        }
        if(data.instructions){
            this.instructions = data.instructions
        }
    }
}