import { useEffect, useMemo, useState } from "react";
import {
  Layout,
  Table,
  Flex,
  Input,
  Space,
} from "antd";
import { columns } from "../../configs/CustomerTableColumns";
import customerService from "../../services/customer.service";
import DataPaginate from "../../models/utils/data-paginate";
import Customer from "../../models/customer";
import Header from "../../components/layout/Header";
import ButtonColumn from "../../components/ui/buttons/button-column";
import { Icon } from "../../components/ui/typo/Icon";
import useDebounce from "../../utils/hooks/useDebounce";
import useTable from "../../utils/hooks/useTable";
import { useNavigate } from "react-router-dom";

export default function ListCustomersPage() {
  const [loading, setLoading] = useState(false);
  const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>('');
  const { visibleColumns, setVisibleColumns, renderColumns } = useTable(columns)
  const navigate = useNavigate();


  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await customerService.list(page, search);
        setDatapaginate(res);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [page, search])


  const dataTable = useMemo(() => {
    if (!dataPaginate || !dataPaginate.data) return [];
    return dataPaginate.data.map((item: Customer) => {
      return {
        id: item.id,
        code: item.code,
        key: item.id,
        client: item.name,
        contact: item.contact,
        email: item.email,
        city: item.city,
        country: item.country,
        home_agency: item.agency ? item.agency.name : '-',
        charterer: item.assigned_user ? item.assigned_user.getFullName() : '-',
      }
    });
  }, [dataPaginate]);

  const handleSearch = useDebounce((term) => {
    setPage(1);
    setSearch(term)
  }, 500);
  
  return (
    <>
      <Header title="Fiches clients" />
  
      <Layout className="container">
        <Flex vertical gap={16}>
          <Flex justify="space-between">
           
            <Input
              size="large"
              placeholder="Rechercher..."
              prefix={<Icon path="/icons/search-refraction.svg" size={20} />}
              style={{ width: 320 }}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Space size={8}>
              <ButtonColumn columns={columns} visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} keyCache="customers" />
              {/*<Button icon={<Icon path="./icons/check-done.svg" size={20} />}>
                <Text disabled target="Bold">Mon suivi</Text>
              </Button>
              <Button disabled icon={<Icon path="./icons/filter-lines.svg" size={20} />}>
                <Text target="Bold">Filtres (0)</Text>
  </Button>*/}
            </Space>
          </Flex>
          <Table
            rowKey={"id"}
            loading={loading}
            columns={renderColumns()}
            dataSource={dataTable}
            scroll={{ x: 1327, y: 500 }}
            showSorterTooltip={false}
            onRow={(record) => { return { onClick: () => { navigate(`/customers/${record.id}`) } } }}
            pagination={{
              pageSize: dataPaginate?.per_page || 10,
              total: dataPaginate?.total || 0,
              onChange: (page) => {
                setPage(page);
              },
            }}
          />
        </Flex>
      </Layout>
    </>
  );
}
