import { Flex } from 'antd';
import { Text } from '../ui/typo/Text';

interface IProps{
    readonly?: boolean;
    contentReadonly?: React.ReactNode;
    children?: React.ReactNode;
    label: string;
    value?: string | number;
}
export default function ReadonlyField({ readonly = false, contentReadonly, children, label, value }: IProps) {
    if (!readonly) {
        return children;
    }

    return (
        <Flex vertical style={{marginBottom: '20px'}}>
            <Text target="Bold" style={{marginBottom: '4px'}}>{label}</Text>
            {!contentReadonly && <Text target="Regular">{value || '-'}</Text>}
            {contentReadonly && contentReadonly}
        </Flex>
    )
}