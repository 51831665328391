export default class Place {
    id: number;
    raw: string;
    area_level_1: string;
    area_level_2: string;
    country: string;
    country_code: string;
    formatted: string;
    latitude: number;
    longitude: number;
    locality: string;
    number: string;
    place_id: string;
    postal_code: string;
    route: string;
    created_at: Date;
    updated_at: Date;

    constructor(data: any) {
        this.id = data.id;
        this.raw = data.raw;
        this.area_level_1 = data.area_level_1;
        this.area_level_2 = data.area_level_2;
        this.country = data.country;
        this.country_code = data.country_code;
        this.formatted = data.formatted;
        this.latitude = data.latitude;
        this.longitude = data.longitude;
        this.locality = data.locality;
        this.number = data.number;
        this.place_id = data.place_id;
        this.postal_code = data.postal_code;
        this.route = data.route;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
    }
}