import { Form, Input, InputNumber } from "antd";
import { Controller } from "react-hook-form";
import FormError from "./form-error";
import React from "react";
import ReadonlyField from "./readonly-field";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

interface IProps{
    label: string;
    name: string;
    control: any;
    required?: boolean;
    defaultValue?: string | number;
    error?: any;
    readonly?: boolean;
    contentReadonly?: React.ReactNode;
    disabled?: boolean;
}
export default function FormNumberInput({ label, name, control, required, defaultValue, error, readonly, contentReadonly, disabled = false }: IProps) {
    
    return (
        <ReadonlyField readonly={readonly} contentReadonly={contentReadonly} label={label} value={defaultValue}>
            <Form.Item label={`${label} ${required ? '*' : ''}`} required>
                <Controller
                    name={name}
                    defaultValue={defaultValue ? defaultValue : 0}
                    control={control}
                    disabled={disabled}
                    render={({ field: { onChange, value } }: any) => <InputNumber
                        disabled={disabled}
                        addonBefore={<MinusOutlined onClick={() => onChange(value -1)} size={20} />}
                        addonAfter={<PlusOutlined onClick={() => onChange(value + 1) } size={20} />}
                        value={value}
                        onChange={(newValue: number | string | null) => onChange(newValue)}
       
                    />}
                />
                <FormError message={error?.message} />
            </Form.Item>
        </ReadonlyField>
    )
}