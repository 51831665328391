import { useMemo } from "react"
import { StatusRoadDemande } from "../../../models/enums/status-road-demande.enum"

interface IProps{
    status: StatusRoadDemande
}
export default function StatusRoadCotation({ status }: IProps) {

    const borderColor = useMemo(() => {
        switch (status) {
            case StatusRoadDemande.DRAFT:
                return '#FFD6AE';
            case StatusRoadDemande.CANCELLED:
                return '#D1F7C4';
            case StatusRoadDemande.PENDING_PRICE:
                return '#B9E6FE';
            //case StatusRoadDemande.NEW:
                //return '#B9E6FE';
            case StatusRoadDemande.STATUS_WAITING_ACCEPTATION:
                return '#FCCEEE'
            case StatusRoadDemande.STATUS_ACCEPTED:
                return '#ABEFC6';
            case StatusRoadDemande.STATUS_REFUSED:
                return '#FEDF89';
                
            default:
                return '#ccc';
        }
    }, [status]);

    const backgroundColor = useMemo(() => {
        switch (status) {
            case StatusRoadDemande.DRAFT:
                return '#FEF6EE';
            case StatusRoadDemande.CANCELLED:
                return '#ECFDF3';
            case StatusRoadDemande.PENDING_PRICE:
                return '#F0F9FF';
            //case StatusRoadDemande.NEW:
                //return '#F0F9FF';
            case StatusRoadDemande.STATUS_WAITING_ACCEPTATION:
                return '#FDF2FA';
            case StatusRoadDemande.STATUS_ACCEPTED:
                return '#ECFDF3';
            case StatusRoadDemande.STATUS_REFUSED:
                return '#FFFAEB';
            
            default:
                return '#ccc';
        }
    }, [status]);

    const color = useMemo(() => {
        switch (status) {
            case StatusRoadDemande.DRAFT:
                return '#E62E05';
            case StatusRoadDemande.CANCELLED:
                return '#067647';
            case StatusRoadDemande.PENDING_PRICE:
                return '#026AA2';
            //case StatusRoadDemande.NEW:
                //return '#026AA2';
            case StatusRoadDemande.STATUS_WAITING_ACCEPTATION:
                return '#C11574';
            case StatusRoadDemande.STATUS_ACCEPTED:
                return '#067647';
            case StatusRoadDemande.STATUS_REFUSED:
                return '#B54708';
            
            default:
                return '#000';
        }
    }, [status]);

    const label = useMemo(() => {
        switch (status) {
            case StatusRoadDemande.DRAFT:
                return 'Brouillon';
            case StatusRoadDemande.NEW:
                return 'Demande client';
            case StatusRoadDemande.CANCELLED:
                return 'Annulé';
            case StatusRoadDemande.PENDING_PRICE:
                return 'Pour devis';
            case StatusRoadDemande.STATUS_WAITING_ACCEPTATION:
                return 'Attente acceptation';
            case StatusRoadDemande.STATUS_ACCEPTED:
                return 'Accepté';
            case StatusRoadDemande.STATUS_REFUSED:
                return 'Décliné';
            default:
                return 'Inconnu';
        }
    }, [status]);

    return (
        <div className="status-task" style={{borderColor: borderColor, backgroundColor: backgroundColor, color: color}}>
            <label>{label}</label>
        </div>
    )
}