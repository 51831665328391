import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Toast from "../../models/utils/toast";

export interface IToastState {
    item?: Toast;
}

const initialState: IToastState = {
    item: undefined,
};

export const toastSlide = createSlice({
    name: "toasts",
    initialState,
    reducers: {
        addToast: (state: IToastState, action: PayloadAction<Toast>) => {
            state.item = action.payload;
        },
    },
});

export const { addToast } = toastSlide.actions;

export const toastsReducer = toastSlide.reducer;