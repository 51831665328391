import { Button, Flex, Form, Input } from "antd";
import { Icon } from "../../ui/typo/Icon";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import noteService from "../../../services/note.service";
import { useAppDispatch } from "../../../store/hooks";
import { addToast } from "../../../store/toasts/reducer";
import Toast from "../../../models/utils/toast";

type Inputs = {
    text: string,
    client_id: number,
    reference?: string
}

interface IProps{
    clientId: number;
    reference?: string;
    onAdd: () => void;
}
export default function FormNote({ clientId, reference, onAdd }: IProps) {
    
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const schema: any = yup.object().shape({
        text: yup.string().required(),
        client_id: yup.number().required(),
        reference: yup.string().optional()
    })
    const {
        handleSubmit,
        control,
        formState: { isDirty, isValid },
        reset,
    } = useForm<Inputs>({
        resolver: yupResolver(schema), mode: 'onChange'
    })

    const onSubmit = async (data: Inputs) => {
        try {
            setLoading(true);
            await noteService.create({ ...data, reference: reference });
            dispatch(addToast(new Toast("Note ajoutée avec succès !", "success")));
            reset();
            onAdd();
        } catch (error) {
            dispatch(addToast(new Toast("Une erreur est survenue pendant l'ajout de la note !", "error")));

            console.error(error);
        } finally {
            setLoading(false);
        }
       
    }

    return <Form requiredMark={false} onSubmitCapture={handleSubmit(onSubmit)}>

        <Flex gap={12} className="send-message-box">
            
             <Controller
                name="text"
                control={control}
                render={({ field }: any) => <Input type="text" placeholder="Ajouter une note" {...field}  />}
            />

            <Controller
                name="client_id"
                control={control}
                defaultValue={clientId}
                render={({ field }: any) => <Input type="hidden" {...field}  />}
            />

            <Controller
                name="reference"
                control={control}
                defaultValue={reference}
                render={({ field }: any) => <Input type="hidden" {...field}  />}
            />
            
            <Button className="send-message-btn" htmlType="submit" disabled={!isDirty || !isValid || loading}>
                <Icon path="/icons/send.svg" size={20} />
            </Button>
        </Flex>
    </Form>
}