import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ITaskState {
  nbRemainingTasks: number;
}

const initialState: ITaskState = {
  nbRemainingTasks: 0,
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setRemainingTasks: (state: ITaskState, action: PayloadAction<number>) => {
      state.nbRemainingTasks = action.payload;
    },
  },
});

export const { setRemainingTasks } = tasksSlice.actions;

export const tasksReducer = tasksSlice.reducer;
