import { isEqual, debounce } from 'lodash';
import { useEffect, useRef, useCallback } from 'react';

const useDeepCompareEffect = (effect: React.EffectCallback, dependencies: any[], delay: number = 300) => {
  const prevDepsRef = useRef<string>();
  const debouncedEffect = useCallback(debounce(effect, delay), [effect, delay]);

  useEffect(() => {
    const depsString = JSON.stringify(dependencies);

    if (!isEqual(prevDepsRef.current, depsString)) {
      debouncedEffect();
    }

    prevDepsRef.current = depsString;

    return () => {
      debouncedEffect.cancel();
    };
  }, [dependencies, debouncedEffect]);
};

export default useDeepCompareEffect;