import { useAppSelector } from "../../../store/hooks";

export default function TaskItemMenu() {
    const { nbRemainingTasks } = useAppSelector(state => state.tasks);

    return (
        <div className="flex row align-item-center justify-content-between">
            Tâches
            {nbRemainingTasks > 0 && <div className="tag-item">
                <label>{nbRemainingTasks}</label>
            </div>}
        </div>
    )   
}