import type { ColumnsType } from "antd/es/table";

const columns: ColumnsType<any> = [
    {
        title: "ID",
        dataIndex: "code",
        key: "code",
        sorter: false,
    },
    {
        title: "Date de transport",
        dataIndex: "date",
        key: "date",
        sorter: false,
    },
    {
        title: "Origine",
        dataIndex: "origin",
        key: "origin",
        sorter: false,
    },
    {
        title: "Destination",
        dataIndex: "destination",
        key: "destination",
        sorter: false,
    },
    {
        title: "Tarif HT",
        dataIndex: "price",
        key: "price",
        sorter: false,
    },
];

export { columns }