export enum StatusContract{
    COMPLETED = "completed",
    HEURE = "heure",
    LIVRAISON = "livraison",
    N_CAMION = "n_camion",
    RAF = "raf",
}

export const statusContractList = [
    { value: StatusContract.COMPLETED, label: "Complet" },
    { value: StatusContract.HEURE, label: "Heure ?" },
    { value: StatusContract.LIVRAISON, label: "Livraison ?" },
    { value: StatusContract.N_CAMION, label: "N° de camion ?" },
    { value: StatusContract.RAF, label: "RAF" },
    { value: '', label: "Etat de quai" },
]