import { useEffect, useMemo, useState } from "react";
import DataPaginate from "../../../models/utils/data-paginate";
import useTable from "../../../utils/hooks/useTable";
import Document from "../../../models/document";
import { Flex, Table } from "antd";
import axios from "axios";
import { columns } from "../../../configs/DocumentContractTableColumns";
import { Icon } from "../../ui/typo/Icon";
import customerService from "../../../services/customer.service";
import StatusYesOrNo from "./status-yes-or-no";


interface IProps {
    customerId: number,
    contratId: number
}
export default function ListDocumentsContract({customerId, contratId}: IProps) {
    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
    const [page, setPage] = useState<number>(1);
    const { renderColumns  } = useTable(columns)

    useEffect(() => {

        fetchData();

    }, [page]);

    async function fetchData() {
        try {
            setLoading(true);
            const res = await customerService.listDocuments(page, customerId, contratId);
            setDatapaginate(res);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

     async function onViewAttachment(url: string) {
        try {
            const response: any = await axios.get(url, {
                responseType: 'blob'
            });
            const blob = response.data;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            const parts = url.split("/");
            const filename = parts[parts.length - 1];
            link.download = `${filename}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Erreur lors du téléchargement du fichier :", error);
        }     
    }

     const dataTable = useMemo(() => {
        if (!dataPaginate || !dataPaginate.data) return [];
        return dataPaginate.data.map((item: Document) => {
            return {
                id: item.id,
                name: item.name,
                type: item.type,
                client_visible: <Flex justify="space-between" align="center">
                    <StatusYesOrNo value={item.client_visible} />
                    <Flex gap={20} align="center" >
                        <a target="_blank" href={item.public_url}><Icon path="/icons/eyes.svg" size={25} /></a>
                        <div style={{cursor: 'pointer'}} onClick={() => onViewAttachment(item.public_url)}><Icon path="/icons/download.svg" size={25} /></div>
                    </Flex>
                </Flex> 
            }
        });
     }, [dataPaginate]);
    
    return <>    
        <Table
            rowKey={"id"}
            loading={loading}
            columns={renderColumns()}
            dataSource={dataTable}
            scroll={{ x: 700, y: 500 }}
            showSorterTooltip={false}
            onRow={(record) => { return { onClick: () => { console.log('TODO') } } }}
            pagination={{
                current: page,
                pageSize: dataPaginate?.per_page || 10,
                total: dataPaginate?.total || 0,
                    onChange: (page) => {
                        setPage(page);
                    },
            }}
        />
    </>
}