import { Button, Flex, Layout, Space, Spin } from "antd";
import Header from "../../components/layout/Header";
import { Text } from "../../components/ui/typo/Text";
import InputFile from "../../components/ui/input-file/input-file";
import { SaveOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import pricingService from "../../services/pricing.service";
import { useAppDispatch } from "../../store/hooks";
import { addToast } from "../../store/toasts/reducer";
import Toast from "../../models/utils/toast";

export default function AnnexePage() {

    const dispatch = useAppDispatch();

    const [annexes, setAnnexes] = useState<any>(
        [
            {
                code: 'general_sales_conditions',
                label: 'Conditions générales de vente',
                tmpFile: null,
                existingFile: null,
                path: null,
                created_at: null,
                update_at: null
            },
            {
                code: 'terms_of_payment',
                label: 'Conditions de réglements',
                tmpFile: null,
                existingFile: null,
                path: null, 
                created_at: null,
                updated_at: null
            }
        ]
    );
    const [loading, setLoading] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    useEffect(() => {
        fetchData();
    }, [])

    async function fetchData() {
        try {
            setLoading(true);
            const res = await pricingService.getAnnexes();
            setAnnexes(
                annexes.map((annexe: any) => {
                    const found = res.find((r: any) => r.code === annexe.code);
                        return {
                            ...annexe,
                            existingFile: found ? found.file : null,
                            path: found ? found.path : null,
                            created_at: found ? found.created_at : null,
                            updated_at: found ? found.updated_at : null
                        }
                })
            );
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const hasNewFile = useMemo(() => {
        return annexes.some((annexe: any) => annexe.tmpFile != null);
    }, [annexes])

    async function onSubmit() {
        const newAnnexes = annexes.filter((annexe: any) => annexe.tmpFile != null);
        setLoadingSave(true);
        for (const annexe of newAnnexes) {
            try {
                await pricingService.uploadAnnexe(annexe.code, annexe.tmpFile);
                dispatch(addToast(new Toast(`Les ${annexe.label.toLowerCase()} ont été mis à jour !`, 'success')))
            }
            catch (e) {
                dispatch(addToast(new Toast(`Une erreur est survenue pendant la mise à jour des ${annexe.label.toLowerCase()} !`, 'error')))
            }   
        }
        setLoadingSave(false);
        fetchData();
    }

    async function updateTmpFile(code: string, file: any) {
        setAnnexes(
            annexes.map((annexe: any) => {
                if (annexe.code === code) {
                    return {
                        ...annexe,
                        tmpFile: file
                    }
                }
                return annexe;
            })
        )
    }

    async function onDelete(annexe: any) {
        if(loadingDelete) return;
        try {
            setLoadingDelete(true);
            await pricingService.deleteAnnexe(annexe.code);
            dispatch(addToast(new Toast(`Les ${annexe.label.toLowerCase()} ont été supprimé !`, 'success')))
        } catch (e) {
            dispatch(addToast(new Toast(`Une erreur est survenue pendant la suppression des ${annexe.label.toLowerCase()} !`, 'error')))
        } finally {
            setLoadingDelete(false);
            fetchData();
        }
    }

    async function onView(annexe: any) {
        try {
            if (annexe) {
                const blob: any = await pricingService.downloadAnnexe(annexe.code);
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                const parts = annexe.path.split("/");
                const filename = parts[parts.length - 1];
                link.download = `${filename}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error("Erreur lors du téléchargement du fichier :", error);
            dispatch(addToast(new Toast(`Une erreur est survenue pendant le téléchargement des ${annexe.label.toLowerCase} !`, 'error')))
        }
    }

    return <>
        <Header title="Annexes au devis" />
        <Layout className="container">
            {loading && <Flex justify="center"><Spin /></Flex>}

            {!loading && <Flex vertical gap={16}>
                {annexes.map((annexe: any, i: number) =>
                    <Space direction="vertical" size={16} style={{ width: "100%", marginTop: i > 0 ? 20 : 0 }} key={i}>
                        <Text target="SectionTitle">{annexe.label}</Text>
                        <InputFile
                            onUpload={(files) => updateTmpFile(annexe.code, files[0])}
                            existingUrls={ annexe && annexe.path ? [annexe.path] : []}
                            accept={['.pdf']}
                            onDelete={() => onDelete(annexe)}
                            onView={() => onView(annexe)}
                            hideDragger={annexe && annexe.path ? true : false}
                        />
                    </Space>
                )}

                <Space direction="horizontal" style={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    left: 316,
                    width: 'cal(100%-316px)',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '16px',
                    boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
                }}>
                    <Button type="primary" onClick={onSubmit} disabled={!hasNewFile || loadingSave}>
                        <Space size={6}>
                            <SaveOutlined />
                            <Text target="Bold">Sauvegarder</Text>
                        </Space>
                    </Button>
                </Space>
               
            </Flex>}
        </Layout>
    </>
}