import Task from "../../../models/task"

interface IProps{
    task: Task
}
export default function StatusTask({ task }: IProps) {
    return (
        <div className="status-task" style={{borderColor: task.getBorderColor(), backgroundColor: task.getBackgroundColor(), color: task.getColor()}}>
            {task.getStatusLabel()}
        </div>
    )
}