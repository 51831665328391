import axios, { AxiosResponse } from "axios";
import DataPaginate from "../models/utils/data-paginate";
import User from "../models/user";

async function list(page: number = 1, search?: string, isArchive?: boolean) {
    const queryParams = new URLSearchParams();
    queryParams.append('page_size', '25');
    queryParams.append('page', page.toString());
    if (search) {
        queryParams.append('search', search);
    }

    if (isArchive) {
        queryParams.append('archives', isArchive.toString());
    }
    const params = {
        params: queryParams
    }

    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/users`, params).then(async (response: AxiosResponse) => {
        const res = new DataPaginate(response.data);
        res.setData(res.data.map(item => new User(item)));

        return res;
    })
}

async function create(data: any) {
    return await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/app/users`, data);
}

async function find(id: number) {
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/users/${id}`).then(async (response: AxiosResponse) => {
        return new User(response.data);
    })
}

async function update(id: number, data: any) {
    return await axios.put(`${import.meta.env.VITE_BACKEND_URL}/api/app/users/${id}`, data);
}

async function destroy(id: number) {
    return await axios.delete(`${import.meta.env.VITE_BACKEND_URL}/api/app/users/${id}`);
}

async function unarchive(id: number) {
    return await axios.patch(`${import.meta.env.VITE_BACKEND_URL}/api/app/users/${id}/unarchive`);

}

export default {
    list,
    create,
    find,
    update,
    destroy,
    unarchive
}