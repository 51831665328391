import React, { useEffect, useState } from 'react';
import { Text } from '../../typo/Text';
import { Flex } from 'antd';
import ErrorApi from '../../../../models/utils/error-api';
import { useAppDispatch } from '../../../../store/hooks';
import { addToast } from '../../../../store/toasts/reducer';
import Toast from '../../../../models/utils/toast';

interface IProps{
    errorApi?: ErrorApi;
}
export default function ErrorsForm({ errorApi: ErrorApi }: IProps) {
    const dispatch = useAppDispatch();
    const [errors, setErrors] = useState<string[]>();

    useEffect(() => {
        setErrors([]);
        if (ErrorApi?.errors) {
            const errors = Object.values(ErrorApi.errors).reduce((acc, value) => {
                return acc.concat(value);
            }, []);
            setErrors(errors);
        } else if (ErrorApi?.error) {
            dispatch(addToast(new Toast(ErrorApi.error, "error")));
        } else if (ErrorApi?.message) {
            dispatch(addToast(new Toast(ErrorApi.message, "error")));
        } else if(ErrorApi?.status === 500 && !ErrorApi?.message) {
            dispatch(addToast(new Toast("Une erreur inattendue est survenue !", "error")));
        }
        
    }, [ErrorApi])

    return (
        <Flex vertical gap={2}>
            {errors?.map((error, index) => (
                <Text key={index} target="Regular" type="danger">{error}</Text>
            ))}
        </Flex>
    )
}