import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import User from "../../models/user";

export interface IUserState {
  value: User | undefined;
}

const initialState: IUserState = {
  value: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: IUserState, action: PayloadAction<User | undefined>) => {
      state.value = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
