import { Empty, Flex, Space, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import noteService from "../../../services/note.service";
import DataPaginate from "../../../models/utils/data-paginate";
import { useAppSelector } from "../../../store/hooks";
import React from "react";
import Avatar from "../../ui/avatar/avatar";
import Note from "../../../models/note";
import { Text } from "../../ui/typo/Text";
import Message from "../../ui/notes/Message";
import dateHelper from "../../../utils/date.helper";
import FormNote from "./form-note";

interface IProps{
    customerId: number;
    reference?: string;
}
export default function NotesList({ customerId, reference }: IProps) {
    const [page, setPage] = useState(1)
    const [dataPaginated, setDataPaginated] = useState<DataPaginate>()
    const [loading, setLoading] = useState(false)
    const { value: user } = useAppSelector(state => state.user)
    const [loadingNext, setLoadingNext] = useState(false)
    const spaceRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                setDataPaginated(await noteService.list(1, customerId, reference));
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [customerId, reference])

    async function refresh() {
        try {
            setLoading(true);
            setPage(1);
            setDataPaginated(await noteService.list(1, customerId, reference));
            if (spaceRef.current) {
                spaceRef.current.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function loadNext() {
        try {
            setLoadingNext(true);
            const nextPage = page + 1;
            const res = await noteService.list(nextPage, customerId, reference);
            if (dataPaginated && dataPaginated.data) {
                dataPaginated.setData([...dataPaginated.data, ...res.data]);
                setDataPaginated(dataPaginated);
                setPage(nextPage);
            }
            
            
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingNext(false);
        }
    }

    const handleScroll = () => {
        if (spaceRef.current && dataPaginated && dataPaginated?.total != dataPaginated.data.length) {
            const { scrollTop, offsetHeight, scrollHeight } = spaceRef.current;
            if (scrollTop + offsetHeight >= scrollHeight) {
                loadNext()
            }
        }
    };

    useEffect(() => {
        const spaceElement = spaceRef.current;
        if (spaceElement) {
            spaceElement.addEventListener('scroll', handleScroll);
        }

        return () => {
        if (spaceElement) {
            spaceElement.removeEventListener('scroll', handleScroll);
        }
        };
    }, []);

    return <>
        <Space style={{ overflow: 'auto' }} direction="vertical" size={16} className="chat-box" ref={spaceRef} onScroll={handleScroll}>
            
            {loading && <Flex justify="center"><Spin /></Flex> }

            {!loading && dataPaginated?.total == 0 && (
                <Empty /> 
            )}

            {dataPaginated && dataPaginated?.total > 0 && (
                <>
                    {dataPaginated.data.map((note: Note) => (
                        <React.Fragment key={`note_${note.id}`}>
                            { user && !note.isAuthor(user) ? (
                                <Flex gap={12} style={{ width: "100%" }}>
                                <Avatar placeholder={note.author?.getLettersName()} />
                                <Space
                                    direction="vertical"
                                    size={6}
                                    style={{ width: "100%" }}
                                >
                                    <Flex justify="space-between">
                                    <Text target="NotesName">{note.author?.getFullName() || '-'}</Text>
                                    <Text target="NotesDate">{ dateHelper.dateToCompleteDateLettersAndHours(note.created_at) }</Text>
                                    </Flex>
                                    <Message theme="light" content={note.text} />
                                </Space>
                                </Flex>
                            ) : (
                                <Flex style={{ width: "100%" }}>
                                <Space
                                    direction="vertical"
                                    size={6}
                                    style={{ width: "100%" }}
                                >
                                    <Flex justify="space-between">
                                    <Text target="NotesName">Vous</Text>
                                    <Text target="NotesDate">{dateHelper.dateToCompleteDateLettersAndHours(note.created_at)}</Text>
                                    </Flex>
                                    <Message theme="dark" content={note.text} />
                                </Space>
                                </Flex>
                            )}
                        </React.Fragment>
                    ))}
                </>
            )} 
        </Space>
         {loadingNext && <Flex justify="center"><Spin /></Flex>}
        <FormNote clientId={customerId} reference={reference} onAdd={() => refresh() }  />
    </>
}