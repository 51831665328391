import { StatusDevis } from "./enums/status-devis.enum";
import User from "./user";

export default class Devis {
    accepted_at?: Date;
    code: string;
    created_at: Date;
    created_by: number;
    creator?: User;
    data: DevisData;
    demande_id: number;
    expired_at: Date;
    id: number;
    public_url?: string;
    refused_at?: string;
    send_at?: Date;
    status: StatusDevis;
    total: number;
    updated_at: Date;

    constructor(data: any) {
        if(data.accepted_at){
            this.accepted_at = new Date(data.accepted_at);
        }
        this.code = data.code;
        this.created_at = new Date(data.created_at);
        this.created_by = data.created_by;
        if(data.creator){
            this.creator = new User(data.creator);
        }
        this.data = new DevisData(data.data);
        this.demande_id = data.demande_id;
        this.expired_at = new Date(data.expired_at);
        this.id = data.id;
        if(data.public_url){
            this.public_url = data.public_url;
        }
        if(data.refused_at){
            this.refused_at = data.refused_at;
        }
        if(data.send_at){
            this.send_at = new Date(data.send_at);
        }
        this.status = data.status;
        this.total = data.total;
        this.updated_at = new Date(data.updated_at);
    }

    isPending() {
        return this.status === StatusDevis.GENERATED || this.status === StatusDevis.SENT;
    }

    isSent() {
        return this.status === StatusDevis.SENT || this.status === StatusDevis.ACCEPTED || this.status == StatusDevis.REFUSED;
    }

    isRefused() {
        return this.status === StatusDevis.REFUSED;
    }

    isAccepted() {
        return this.status === StatusDevis.ACCEPTED;
    }
}

export class DevisData{
    comments: string;
    display_co2_rate: boolean;
    display_gazoil_surtax: boolean;
    distance: number;
    fees: DevisDataFees[];
    gazoil_surtax_rate: number;
    options?: DevisDataOptions;
    price: number;

    constructor(data: any) {
        this.comments = data.comments;
        this.display_co2_rate = data.display_co2_rate;
        this.display_gazoil_surtax = data.display_gazoil_surtax;
        this.distance = data.distance;
        this.fees = data.fees.map((item: any) => new DevisDataFees(item));
        this.gazoil_surtax_rate = data.gazoil_surtax_rate;
        if (data.options) {
            this.options =  new DevisDataOptions(data.options);
        }
        this.price = data.price;
    }
}

export class DevisDataFees{
    price: number;
    name: string;

    constructor(data: any) {
        this.price = data.price;
        this.name = data.name;
    }

}

export class DevisDataOptions{
    advalorem_insurance: number;
    customs_export: number;
    customs_import: number;
    tailgate: number;

    constructor(data: any) {
        this.advalorem_insurance = data.advalorem_insurance;
        this.customs_export = data.customs_export;
        this.customs_import = data.customs_import;
        this.tailgate = data.tailgate;
    }
}