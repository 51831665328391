import Place from "./place";

export default class StopPoint{
    id: number;
    address_id: number;
    date_end?: Date;
    date_start?: Date;
    demande_id: number;
    type: 'loading' | 'unloading';
    address?: Place;
    updated_at: Date;

    constructor(data: any) {
        this.id = data.id;
        this.address_id = data.address_id;
        if(data.date_end)
            this.date_end = new Date(data.date_end);
        if(data.date_start)
            this.date_start = new Date(data.date_start);
        this.demande_id = data.demande_id;
        this.type = data.type;
        this.updated_at = new Date(data.updated_at);

        if(data.address){
            this.address = new Place(data.address);
        }
    }

    getLabelType() {
        return this.type === 'loading' ? 'Chargement' : 'Déchargement';
    }
}