import { Image } from "antd";
import React from "react";

interface IconProps {
  path: string;
  size?: number;
}

export const Icon: React.FC<IconProps> = ({ path, size = 24 }) => {
  return <Image src={path} preview={false} width={size} height={size} />;
};
