import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import customerService from "../../../services/customer.service";
import Customer from "../../../models/customer";
import HasFound from "../../../components/core/commons/has-found/has-found";
import Header from "../../../components/layout/Header";
import { Col, Layout, Row, Space, Spin } from "antd";
import { Text } from "../../../components/ui/typo/Text";
import { RightOutlined } from "@ant-design/icons";
import CustomerGeneralInformations from "../../../components/core/customers/customer-general-informations";
import SectionNotesAndTasks from "../../../components/core/commons/section-notes-and-tasks.tsx/section-notes-and-tasks";
import CustomerListQuotation from "../../../components/core/customers/customer-list-quotations";
import CustomerListContracts from "../../../components/core/customers/customer-list-contracts";

export default function DetailCustomer() {

    const id = useParams().id;
    const [customer, setCustomer] = useState<Customer>();
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                setCustomer(await customerService.find(Number(id)));
            } catch (e) {
                console.log(e);
                setNotFound(true);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [id])

    return (
        <HasFound notFound={notFound} title="Le client n'existe pas !">
            <>
                {loading && <Spin />}
                {customer && (
                    <>
                        <Header breadcrumb={[
                            {
                                title: (
                                    <Space size={16}>
                                        <Link to="/customers"><Text target="H1">Fiches clients</Text></Link>
                                        <RightOutlined />
                                            <Text target="BreadCrumbDetail">{ customer.name}</Text>
                                    </Space>
                                ),
                            },
                        ]} />
                        <Layout>
                            <Row style={{scrollBehavior: 'auto'}}>
                                <Col className="gutter-row container full-height-content" span={15}>
                                    <Space direction="vertical" size={32} style={{ width: "100%" }}>
                                        <CustomerGeneralInformations customer={customer} />
                                    </Space>
                                    {customer && <Space direction="vertical" size={32} style={{ width: "100%" }}>
                                        <CustomerListQuotation customerId={customer.id} />
                                    </Space>}

                                    {customer && <Space direction="vertical" size={32} style={{ width: "100%", marginTop: 20 }}>
                                        <CustomerListContracts customerId={customer.id} />
                                    </Space>}

                                </Col>
                            
                                <Col
                                    className="gutter-row container full-height-content detail-customer-sider-wrapper"
                                    span={9}
                                    style={{ backgroundColor: "#F9FAFB" }}
                                >
                                    <SectionNotesAndTasks customerId={customer.id} />
                                </Col>
                            </Row>
                        </Layout>
                    </>
                )}
            </>
        </HasFound>
    )
}