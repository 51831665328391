export default class Account {
    id: number;
    name: string;
    email: string;
    client_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at?: Date;

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.email = data.email;
        this.client_id = data.client_id;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        if(data.deleted_at)
            this.deleted_at = new Date(data.deleted_at);
    }

    getLabel() {
        return `${this.name} (${this.email})`;
    }

    getLettersName() {
        return this.name.split(' ').map((word: string) => word[0]).join('');
    }

    getFullName() {
        return this.name;
    }
}