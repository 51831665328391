import axios, { AxiosResponse } from "axios";

export interface DashboardData{
    todo_agency: number;
    todo_assignment: number;
    waiting_acceptation: number;
}

async function get(): Promise<DashboardData> {
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/dashboard`).then(async (response: AxiosResponse) => {
        return response.data;
    })
}

export default {
    get
}