import { Button, Card, Flex, Space } from "antd";
import Devis from "../../../models/Devis";
import PreviewPDF from "../commons/preview-pdf/preview-pdf";
import { Text } from "../../ui/typo/Text";
import { DownloadOutlined, SendOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addToast } from "../../../store/toasts/reducer";
import Toast from "../../../models/utils/toast";
import devisService from "../../../services/devis.service";
import RoadDemande from "../../../models/RoadDemande";
import { ERole } from "../../../models/user";

interface IProps{
    cotation: RoadDemande;
    devis: Devis;
    refreshDevis: () => void;
}
export default function PreviewDevis({ cotation, devis, refreshDevis }: IProps) {
    const dispatch = useAppDispatch();
    const {value : user} = useAppSelector(state => state.user);
    
    const downloadPdf = () => {
        if (devis.public_url) {
            const url = devis.public_url;
            const filename = `${devis.code}.pdf`;
            // Download in new tab
            /*const link = document.createElement('a');
            link.style.display = 'none';
            document.body.appendChild(link);

            link.href = devis.public_url;

            link.setAttribute('download', `${devis.code}.pdf`);
            link.setAttribute('target', '_blank');

            link.click();

            document.body.removeChild(link);*/

            // Download manually
            fetch(url, {
                headers: {
                'Content-Type': 'application/pdf'
                },
                method: 'GET'
            })
            .then(response => response.blob())
            .then(blob => {
                // Créer un objet URL pour le blob
                const url = window.URL.createObjectURL(blob);

                // Créer un élément <a> invisible
                const link = document.createElement('a');
                link.style.display = 'none';
                document.body.appendChild(link);

                // Définir l'URL du fichier à télécharger
                link.href = url;

                // Définir l'attribut download pour forcer le téléchargement
                link.setAttribute('download', filename);

                // Simuler un clic sur le lien pour démarrer le téléchargement
                link.click();

                dispatch(addToast(new Toast("Le téléchargement a débuté...", "success")));

                // Nettoyer après le téléchargement
                document.body.removeChild(link);
            })
                .catch(error => {
                    dispatch(addToast(new Toast("Le téléchargement a échoué !", "error")));
                    console.error('Erreur lors du téléchargement du fichier', error)
                });
        }
        
    };

    async function sendMail() {
        try {
            await devisService.send(cotation.id);
            dispatch(addToast(new Toast("Le devis a été envoyé", "success")));
            refreshDevis();
        } catch (e) {
            dispatch(addToast(new Toast("Le devis n'a pas été envoyé !", "error")));
            console.log(e);
        }
    }

    async function onAccept() {
        try {
            await devisService.accept(cotation.id);
            dispatch(addToast(new Toast("Le devis a été accepté", "success")));
            refreshDevis();
        } catch (e) {
            dispatch(addToast(new Toast("Le devis n'a pas été accepté !", "error")));
            console.log(e);
        }
    }

    async function onDecline() {
        try {
            await devisService.refuse(cotation.id);
            dispatch(addToast(new Toast("Le devis a été refusé", "success")));
            refreshDevis();
        } catch (e) {
            dispatch(addToast(new Toast("Le devis n'a pas été refusé !", "error")));
            console.log(e);
        }
    }
    
    return <>
        <Flex justify="space-between" style={{ flexDirection: "column", height: '100%' }}>
            
            <PreviewPDF pdfUrl={devis?.public_url || ''} />

            <div className="ant-drawer-footer">
                <Space
                    direction="horizontal"
                    size={16}
                    style={{ width: "100%", justifyContent: 'space-between' }}
                >
                    <Space
                        size={16}
                    >
                        {devis.isRefused() &&
                            <Card style={{borderColor: '#990717', marginBottom: 10}}>
                                <Text target="Regular" style={{color:'#990717'}} >Le devis a été refusé !</Text>
                            </Card>
                        }
                        {devis.isAccepted() &&
                            <Card style={{borderColor: '#079455', marginBottom: 10}}>
                                <Text target="Regular" style={{color:'#079455'}} >Le devis a été accepté !</Text>
                            </Card>
                        }
                        {devis.isPending() && <>
                            <Button style={{backgroundColor: '#D92D20'}} type="primary" onClick={() => onDecline()}>
                                <Space size={6}>
                                    <CloseOutlined />
                                    <Text target="Bold">Refuser</Text>
                                </Space>
                            </Button>
                            <Button style={{backgroundColor: '#079455'}} type="primary" onClick={() => onAccept()}>
                                <Space size={6}>
                                    <CheckOutlined />
                                    <Text target="Bold">Valider</Text>
                                </Space>
                            </Button>
                        </>}
                    </Space>
                     <Space
                        size={16}
                    >
                        {devis.public_url && <Button type="default" onClick={() => downloadPdf()}>
                            <Space size={6}>
                                <DownloadOutlined />
                                <Text target="Bold">Télécharger</Text>
                            </Space>
                        </Button>}
                    
                        <Button type="primary" onClick={() => sendMail()} disabled={devis.isSent()}>
                            <Space size={6}>
                                <SendOutlined />
                                <Text target="Bold">Envoyer par mail</Text>
                            </Space>
                        </Button>
                    </Space>
                </Space>
            </div>
        </Flex>

        

    </>
}