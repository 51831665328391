import { Form, Input } from "antd";
import { Controller } from "react-hook-form";
import FormError from "./form-error";
import TextArea from "antd/lib/input/TextArea";
import ReadonlyField from "./readonly-field";
import './form-textarea.scss';

interface IProps{
    label: string;
    name: string;
    placeholder: string;
    control: any;
    required?: boolean;
    defaultValue?: string;
    error?: any;
    readonly?: boolean;
    contentReadonly?: React.ReactNode;
    labelIsVisible?: boolean;
    disabled?: boolean;
    className?: string;
}
export default function FormTextarea({ label, name, placeholder, control, required, defaultValue, error, readonly, contentReadonly,  labelIsVisible = true, disabled = false, className }: IProps) {
    
      function renderLabelWithContent() {
        return <Form.Item label={`${label} ${required ? '*' : ''}`} required>
               {renderInput()}
            </Form.Item>
    }

    function renderInput() {
        return <>
             <Controller
                name={name}
                defaultValue={defaultValue}
                control={control}
                disabled={disabled}
                render={({ field }: any) => <TextArea placeholder={placeholder} rows={5} {...field} className={className}  />}
            />
            <FormError message={error?.message} />
        </>
    }

    return (
        <ReadonlyField readonly={readonly} contentReadonly={contentReadonly} label={label} value={defaultValue}>
            {labelIsVisible ? renderLabelWithContent() : renderInput()}
        </ReadonlyField>
    )
}