import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IRoadDemandeState {
  nbRemainingRoadDemandes: number;
}

const initialState: IRoadDemandeState = {
  nbRemainingRoadDemandes: 0,
};

export const roadDemandesSlice = createSlice({
  name: "road-demandes",
  initialState,
  reducers: {
    setRemainingRoadDemandes: (state: IRoadDemandeState, action: PayloadAction<number>) => {
      state.nbRemainingRoadDemandes = action.payload;
    },
  },
});

export const { setRemainingRoadDemandes } = roadDemandesSlice.actions;

export const roadDemandesReducer = roadDemandesSlice.reducer;
