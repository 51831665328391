import RoadDemande from "./RoadDemande"
import Customer from "./customer"
import User from "./user"

export default class Task {
    id: number
    title: string
    description: string
    status: ETaskStatus
    client_id: number
    user_id: number
    priority: number
    due_date?: Date
    completed_at?: Date
    created_at: Date
    updated_at: Date
    deleted_at?: Date
    client: Customer
    reference?: string
    user?: User
    demande?: RoadDemande
    
    constructor(data: any) {
        this.id = data.id
        this.title = data.title
        this.description = data.description
        this.status = data.status
        this.client_id = data.client_id
        this.user_id = data.user_id
        this.priority = data.priority
        if (data.due_date)
            this.due_date = new Date(data.due_date)
        if (data.completed_at)
        this.completed_at = new Date(data.completed_at)
        this.created_at = new Date(data.created_at)
        this.updated_at = new Date(data.updated_at)
        if (data.deleted_at)
            this.deleted_at = new Date(data.deleted_at)
        this.client = new Customer(data.client)
        this.reference = data.reference
        if(data.user){
            this.user = new User(data.user)
        }
        if (data.demande)
            this.demande = new RoadDemande(data.demande)
    }

    getStatusLabel() {
        switch (this.status) {
            case ETaskStatus.TODO:
                return "A faire"
            case ETaskStatus.IN_PROGRESS:
                return "En cours"
            case ETaskStatus.DONE:
                return "Fait"
            default:
                return ""
        }
    }

    getBackgroundColor() {
        switch (this.status) {
            case ETaskStatus.TODO:
                return "#F0F9FF"
            case ETaskStatus.IN_PROGRESS:
                return "#FEF6EE"
            case ETaskStatus.DONE:
                return "#ECFDF3"
            default:
                return "#ccc"
        }
    }
    

    getBorderColor() {
        switch (this.status) {
            case ETaskStatus.TODO:
                return "#B9E6FE"
            case ETaskStatus.IN_PROGRESS:
                return "#FFD6AE"
            case ETaskStatus.DONE:
                return "#ABEFC6"
            default:
                return "#ccc"
        }
    }

    getColor() {
        switch (this.status) {
            case ETaskStatus.TODO:
                return "#026AA2"
            case ETaskStatus.IN_PROGRESS:
                return "#E62E05"
            case ETaskStatus.DONE:
                return "#067647"
            default:
                return "#000"
        }
    }

    getColorPriority() {
        switch (this.priority) {
            case ETaskPriority.LOW:
                return "#5bc0de"
            case ETaskPriority.MEDIUM:
                return "#f39c12"
            case ETaskPriority.HIGH:
                return "#d9534f"
            default:
                return "#000"
        }
        
    }
}

export enum ETaskStatus {
    TODO = "todo",
    IN_PROGRESS = "doing",
    DONE = "done",
}

export enum ETaskPriority {
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3,
}

export function getPriorityLabel(priority: ETaskPriority) {
    switch (priority) {
        case ETaskPriority.LOW:
            return "Faible"
        case ETaskPriority.MEDIUM:
            return "Normale"
        case ETaskPriority.HIGH:
            return "Haute"
        default:
            return "Aucune priorité"
    }
}