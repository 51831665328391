import { Tabs, TabsProps } from "antd";
import NotesList from "../../notes/notes-list";
import TasksList from "../../tasks/tasks-list";

interface IProps{
    customerId: number;
    reference?: string;
}
export default function SectionNotesAndTasks({customerId, reference}: IProps) {
    const items: TabsProps["items"] = [
        {
            key: "notes",
            label: "Notes",
            children: <NotesList customerId={customerId} reference={reference} />,
        },
        {
            key: "tasks",
            label: "Tâches",
            children: <TasksList customerId={customerId} reference={reference}/>,
        },
    ];

     return (
        <Tabs
            defaultActiveKey="notes"
            items={items}
            tabBarGutter={0}
            tabBarStyle={{ textAlign: "center" }}
        />
    );
}