export default class Filter {
    name: string;
    label: string;
    type: FilterType;
    list?: {label: string, value: any}[];
    value?: any;
    labelValue?: string;

    constructor(data: any){
        this.name = data.name;
        this.label = data.label;
        this.type = data.type;
        this.value = data.value;
        this.list = data.list;
        this.labelValue = data.labelValue;
    }
}

export enum FilterType {
    TEXT="text",
    DATE="date",
    SELECT = "select",
    DROPDOWN_CLIENT = "dropdown_client",
    DROPDOWN_USER = "dropdown_user",
    DROPDOWN_AGENCY = "dropdown_agency",
    
}