import User from "./user"

export default class Note{
    id: number
    text: string
    created_at: Date
    user_id: number
    author?: User

    constructor(data: any) {
        this.id = data.id;
        this.text = data.text;
        this.created_at = new Date(data.created_at);
        this.user_id = data.user_id;
        if(data.author)
            this.author = new User(data.author);
    }

    isAuthor(user: User) {
        return user.id === this.user_id;
    }
}