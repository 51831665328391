import { Flex } from "antd";
import Task from "../../../models/task";

interface IProps{
    task: Task;
}
export default function LabelTaskName({task}: IProps){
    return (
        <Flex gap={10} align="center">
            <div style={{ width: '10px', height: '10px', backgroundColor: task.getColorPriority(), borderRadius: 10 }}>
            </div> {task.title}
        </Flex>
    )
}