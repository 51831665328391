import { Button, Flex, Input, Layout, Space, Table } from "antd";
import HasPermission from "../../components/core/commons/has-permission/has-permission";
import Header from "../../components/layout/Header";
import { ERole } from "../../models/user";
import { Icon } from "../../components/ui/typo/Icon";
import { Text } from "../../components/ui/typo/Text";
import ButtonColumn from "../../components/ui/buttons/button-column";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import DataPaginate from "../../models/utils/data-paginate";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import useTable from "../../utils/hooks/useTable";
import { columns } from "../../configs/RoadContractsTableColumns";
import GroupFilter from "../../models/utils/group-filter";
import Filter, { FilterType } from "../../models/utils/filter";
import dayjs from 'dayjs';
import roadContractsService from "../../services/road-contracts.service";
import dateHelper from "../../utils/date.helper";
import Contract from "../../models/contract";
import useDebounce from "../../utils/hooks/useDebounce";
import DrawerFilterRoadQuotation from "../../components/core/commons/drawer-filter/drawer-filter";
import ModalStatusContract from "../../components/core/road-folder/modal-status-contract";
import StatusContractComponent from "../../components/core/road-folder/status-contract";
import { StatusContract, statusContractList } from "../../models/enums/status-contract.enum";
import './list-contract-road-quotation-page.scss';

export default function ListContractRoadQuotationPage() {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
    const [page, setPage] = useState<number>(searchParams.has('page') ? parseInt(searchParams.get('page') || '1') : 1);
    const [search, setSearch] = useState<string>(searchParams.get('search') || '');
    const { selectedRowKeys, rowSelection, visibleColumns, setVisibleColumns, renderColumnsAndSelection, resetRowSelection } = useTable(
        columns,
        undefined,
        true,
        true,
    )
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showFilters, setShowFilters] = useState(false);
    const {value : user} = useAppSelector(state => state.user);
    const [isCurrentUserFilterActive, setIsCurrentUserFilterActive] = useState(searchParams.has('assignee') && searchParams.get('assignee') == user?.id.toString() ? true : false);
    const [showModalStatus, setShowModalStatus] = useState(false);
    const [groupFilters, setGroupFilters] = useState<GroupFilter[]>([
        new GroupFilter({
            label: "Agence de rattachement",
            filters: [
                new Filter({
                    name: "agency_id",
                    label: "Nom de l'agence",
                    type: FilterType.DROPDOWN_AGENCY,
                    value: searchParams.get('agency_id') || undefined,
                })
            ],
            formater: function(){
                return this.filters[0].labelValue ? this.filters[0].labelValue : ""

            }
        }),
        new GroupFilter({
            label: "Client",
            filters: [
                new Filter({
                    name: "client_id",
                    label: "Nom du client",
                    type: FilterType.DROPDOWN_CLIENT,
                    value: searchParams.get('client_id') || undefined,
                })
            ],
            formater: function(){
                return this.filters[0].labelValue ? this.filters[0].labelValue : ""

            }
        }),
        new GroupFilter({
            label: "Date de chargement",
            filters: [
                new Filter({
                    name: "loading_date_from",
                    label: "Date de début",
                    type: FilterType.DATE,
                    value: searchParams.get('loading_from') ? dayjs(searchParams.get('loading_from')) : undefined,
                }),
                new Filter({
                    name: "loading_date_to",
                    label: "Date de fin",
                    type: FilterType.DATE,
                    value: searchParams.get('loading_to') ? dayjs(searchParams.get('loading_to')) : undefined,
                }),
            ],
            formater: function(){
                if (this.filters[0].labelValue && this.filters[1].labelValue) {
                    return `Du ${this.filters[0].labelValue} au ${this.filters[1].labelValue}`
                } else if (this.filters[0].labelValue) {
                    return `Depuis le ${this.filters[0].labelValue}`
                } else if (this.filters[1].labelValue) {
                    return `Jusqu'au ${this.filters[1].labelValue}`
                } else {
                    return ""
                }
            }
        }),
        new GroupFilter({
            label: "Date de livraison",
            filters: [
                new Filter({
                    name: "delivery_date_from",
                    label: "Date de début",
                    type: FilterType.DATE,
                    value: searchParams.get('delivery_from') ? dayjs(searchParams.get('delivery_from')) : undefined,
                }),
                new Filter({
                    name: "delivery_date_to",
                    label: "Date de fin",
                    type: FilterType.DATE,
                    value: searchParams.get('delivery_to') ? dayjs(searchParams.get('delivery_to')) : undefined,
                }),
            ],
            formater: function(){
                if (this.filters[0].labelValue && this.filters[1].labelValue) {
                    return `Du ${this.filters[0].labelValue} au ${this.filters[1].labelValue}`
                } else if (this.filters[0].labelValue) {
                    return `Depuis le ${this.filters[0].labelValue}`
                } else if (this.filters[1].labelValue) {
                    return `Jusqu'au ${this.filters[1].labelValue}`
                } else {
                    return ""
                }
            }
        }),
         new GroupFilter({
            label: "Statut interne",
            filters: [
                new Filter({
                    name: "internal_status",
                    label: "Statut interne",
                    type: FilterType.SELECT,
                    list: statusContractList,
                    value: searchParams.get('internal_status') || undefined
                })
            ],
            formater: function() {
                return this.filters[0].labelValue ? this.filters[0].labelValue : ""
            }
        }),

        new GroupFilter({
            label: "Transporteur",
            filters: [
                new Filter({
                    name: "carrier",
                    label: "Transporteur",
                    type: FilterType.TEXT,
                    value: searchParams.get('carrier') || undefined
                })
            ],
            formater: function() {
                return this.filters[0].labelValue ? this.filters[0].labelValue : "" 
            }
        }),

        //TODO
         new GroupFilter({
            label: "Affréteur",
            filters: [
                new Filter({
                    name: "assignee",
                    label: "Affréteur",
                    type: FilterType.DROPDOWN_USER,
                    value: searchParams.get('assignee') || undefined
                })
            ],
            formater: function() {
                return this.filters[0].labelValue ? this.filters[0].labelValue : "" 
            }
         }),
       
    ]);
    const nbFilters = useMemo(() => {   
        return groupFilters.reduce((acc, group) => {
            return acc + group.filters.filter(f => f.value != null).length;
        }, 0)
    }, [groupFilters]);



    const filters = useMemo(() => {
        return groupFilters.map(g => {
            const enabledFilters = g.filters.filter(f => {
                return f.value != null
            })
            return enabledFilters;
        }).flat().map(f => ({ name: f.name, value: f.value }));
    }, [groupFilters]);

    useEffect(() => {
        const queryParams = new URLSearchParams();
        if (page) {
            queryParams.append('page', page.toString());
        }

        if (isCurrentUserFilterActive) {
            queryParams.append('assignee', user?.id.toString() || '');
        } else {
            queryParams.delete('assignee');
        }
        
        if (filters.length > 0) {
            filters.forEach(filter => {
                queryParams.append(filter.name, filter.value);
            });
        }
        if (search) {
            queryParams.append('search', search);
        }

       

        const queryParamsString = queryParams.toString();
        if (queryParamsString) {
            navigate(`?${queryParams.toString()}`);
        }

        fetchData();

    }, [page, search, filters, isCurrentUserFilterActive]);

    async function fetchData() {
        try {
            setLoading(true);
            const res = await roadContractsService.list(page, search, filters, isCurrentUserFilterActive ? user?.id : undefined);
            setDatapaginate(res);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }
    
    const dataTable = useMemo(() => {
        if (!dataPaginate || !dataPaginate.data) return [];
        return dataPaginate.data.map((item: Contract) => {
            return {
                id: item.id,
                key: item.id,
                internal_status: <StatusContractComponent contract={item} />,
                assigned_user: item.assigned_user ? <div title={item.assigned_user.getFullName()}>{item.assigned_user.getLettersName()}</div> : '-',
                loading_date_end: item.loading_date_end ? dateHelper.dateToDDMMYYYY(item.loading_date_end) : '-',
                client: item.client ? <div title={item.client.name}>{ item.client.getName()}</div> : '-',
                code: item.code ? item.code : '-',
                client_reference: item.client_reference ? item.client_reference : '-',
                loading_address: item.delivery_address ? item.getOriginLabel() : '-',
                delivery_address: item.delivery_address ? item.getDestinationLabel() : '-',
                quantity: item.quantity ? item.quantity : '-',
                type: item.type ? item.type : '-',
                delivery_date: item.delivery_date_start ? dateHelper.dateToDDMMYYYY(item.delivery_date_start) : '-',
                carrier_name: item.carrier_name ? <div title={item.carrier_name}>{ item.getCarrierName()}</div> : '-',
                driver: item.driver ? item.driver : '-',
                dangerous_substances: item.dangerous_substances ? 'Oui' : 'Non',
                status: item.status ? item.status : '-',
                selling_price: item.selling_price ? `${item.selling_price} €` : '-',
                purchase_price: item.purchase_price ? `${item.purchase_price} €` : '-',
                internal_status_code: item.internal_status,
            }
        });
    }, [dataPaginate]);

    const handleSearch = useDebounce((term) => {
        setPage(1);
        setSearch(term)
    }, 500);
    return (
         <HasPermission permission={ERole.ROLE_ROAD_TRANSPORTS}>
            <Header title="Contrats de transport route">
            </Header>
            <Layout className="container">
                <Flex vertical gap={16}>
                    <Flex justify="space-between">
                        <Input
                            size="large"
                            placeholder="Rechercher..."
                            prefix={<Icon path="/icons/search-refraction.svg" size={20} />}
                            style={{ width: 320 }}
                            defaultValue={search ? search : undefined}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                        <Space size={8}>
                            {/*<Select
                                defaultValue="origin"
                                style={{ width: 267 }}
                                onChange={() => console.log('todo')}
                                options={[
                                    { value: "origin", label: "Origine" },
                                ]}
                            />*/}
                                {user && <Button icon={<Icon path="./icons/check-done.svg" size={20} />} onClick={() => setIsCurrentUserFilterActive(!isCurrentUserFilterActive)} className={isCurrentUserFilterActive && searchParams.get('assignee') == user.id.toString() ? 'selected-button' : ''}>
                                    <Text target="Bold">Mon suivi</Text>
                                </Button>}
                                <ButtonColumn columns={columns} visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} keyCache="road_contracts"/>
                                <Button icon={<Icon path="./icons/filter-lines.svg" size={20} />} onClick={() => setShowFilters(true)} >
                                <Text target="Bold">Filtres ({nbFilters})</Text>
                            </Button>
                        </Space>
                    </Flex>
                    <Table
                        rowKey={"id"}
                        loading={loading}
                        columns={renderColumnsAndSelection(
                            visibleColumns,
                            selectedRowKeys,
                            () => null,
                            () => null,
                            [
                                {svgPath: '/icons/edit.svg', label: 'Changer statut', uniqueSelection: false},
                            ],
                            () => setShowModalStatus(true),
                        )}
                        rowSelection={rowSelection}
                        dataSource={dataTable}
                        scroll={{ x: 1327, y: 500 }}
                        showSorterTooltip={false}
                        onRow={(record) => { return { onClick: () => { navigate(`/road-contracts/${record.id}`) } } }}
                        rowClassName={(record) => record.internal_status_code == StatusContract.COMPLETED ? 'row-completed' : ''}
                        pagination={{
                            current: page,
                            pageSize: dataPaginate?.per_page || 10,
                            total: dataPaginate?.total || 0,
                                onChange: (page) => {
                                    setPage(page);
                                },
                        }}
                    />
                </Flex>
            </Layout>
            {showFilters && groupFilters &&
                <DrawerFilterRoadQuotation
                    groupFilters={groupFilters}
                    isOpen={showFilters}
                    onClose={(groups?: GroupFilter[]) => {
                        if (groups) {
                            setGroupFilters(groups)
                        } else {
                            setShowFilters(false)
                        }
                    }}
                />}
            
            {showModalStatus && <ModalStatusContract contractIds={selectedRowKeys} modalOpen={showModalStatus} onClose={(refresh?: boolean) => {
                setShowModalStatus(false)
                if (refresh) {
                    fetchData();
                    resetRowSelection();
                }
            }} />}
        </HasPermission>
    )
}