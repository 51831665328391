import { Card } from "antd";
import Devis from "../../../models/Devis";
import { Text } from "../../ui/typo/Text";
import PreviewPDF from "../commons/preview-pdf/preview-pdf";

interface IProps{
    devis: Devis
}
export default function RoadQuotationDetailDevis({ devis}: IProps) {
    return (
        <>
            {devis.isRefused() &&
                <Card style={{borderColor: '#990717', marginBottom: 10}}>
                    <Text target="Regular" style={{color:'#990717'}} >Le devis a été refusé !</Text>
                </Card>
            }
            {devis.isAccepted() &&
                <Card style={{borderColor: '#079455', marginBottom: 10}}>
                    <Text target="Regular" style={{color:'#079455'}} >Le devis a été accepté !</Text>
                </Card>
            }
            {devis.isPending() &&
                <Card style={{borderColor: '#E62E05', marginBottom: 10}}>
                    <Text target="Regular" style={{color:'#E62E05'}} >Le devis est en attente de validation !</Text>
                </Card>
            }

            <PreviewPDF pdfUrl={devis?.public_url || ''} />
        </>
    )
    
}