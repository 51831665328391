import {v4 as uuidv4} from 'uuid';

export default class Toast{
    uuid: string;
    title: string;
    content: string;
    type: 'error' | 'success';

    constructor(content: string, type: 'error' | 'success'){
        this.uuid = uuidv4();
        this.title = type === 'error' ? 'Erreur' : 'Succès';
        this.content = content;
        this.type = type;
    }
}