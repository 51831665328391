import { useEffect, useMemo, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import EstimateDistanceCO2 from '../../../models/estimate-distance-co2';
import { Polyline } from '@react-google-maps/api';


interface IProps {
    legs: string[][];
    points: string[];
}
const MapItinerary = ({ legs, points }: IProps) => {
    const mapRef = useRef<google.maps.Map | null>(null);
    
    const defaultCenter = useMemo(() => {
        if (points.length === 0) return { lat: 0, lng: 0 };
        const [lat, lng] = points[0].split(',');
        return {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
        }
    }, [points]);
    
    const locations = useMemo(() => {
        if (legs.length === 0) return [];
        return legs.flat().map(leg => {
            const [lat, lng] = leg.split(',');
            return {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            }
        });
        
    }, [legs]);

    function renderMap() {
        if(mapRef.current === null) return;
        mapRef.current.setCenter(new google.maps.LatLng(defaultCenter.lat, defaultCenter.lng));

        
        new google.maps.Polyline({
            path: locations.map(loc => new google.maps.LatLng(loc.lat, loc.lng)),
            geodesic: true,
            strokeColor: '#FF0000',
            strokeOpacity: 1.0,
            strokeWeight: 2,
            map: mapRef.current
        });

            
        points.forEach((point, i) => {
            const [lat, lng] = point.split(',');
            new window.google.maps.Marker({
                position: { lat: parseFloat(lat), lng: parseFloat(lng) },
                map: mapRef.current,
                label: indexToLetter(i+1)
            });
        });
    }

    function indexToLetter(index: number): string {
        if (index < 1) {
            throw new Error("Index must be greater than or equal to 1");
        }

        // Convertit l'index en lettre en utilisant le code ASCII des lettres majuscules
        const letter = String.fromCharCode(64 + index);
        
        // Vérifie que l'index est dans la plage valide pour les lettres A-Z
        if (index > 26) {
            throw new Error("Index exceeds the number of letters in the alphabet (26)");
        }

        return letter;
    }

    return (
        <div style={{ height: '200px', width: '100%' }}>
             <GoogleMapReact
                bootstrapURLKeys={{ key: import.meta.env.VITE_GOOGLE_MAP_API_KEY }}
                defaultCenter={{lat: 48.8566, lng: 2.3522}}
                defaultZoom={5}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map;
                    renderMap()
                }}
            >
            </GoogleMapReact>
        </div>
    );
}

export default MapItinerary;
