import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./user/reducer";
import { pageReducer } from "./page/reducer";
import { toastsReducer } from "./toasts/reducer";
import { tasksReducer } from "./tasks/reducer";
import { roadDemandesReducer } from "./road-demandes/reducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    page: pageReducer,
    toasts: toastsReducer,
    tasks: tasksReducer,
    road_demandes: roadDemandesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
