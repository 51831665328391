import { Form, Select } from "antd";
import { Controller } from "react-hook-form";
import FormError from "./form-error";
import ReadonlyField from "./readonly-field";


interface IProps{
    label: string;
    placeholder: string;
    required?: boolean;
    name: string;
    control: any;
    defaultValue?: any;
    options: { label: string, value: string | number }[];
    error?: any;
    onSearch: (value: string) => void;
    readonly?: boolean;
    contentReadonly?: React.ReactNode;
    style?: React.CSSProperties;
    disabled?: boolean;
    readOnly?: boolean;
    hasCustomFilter?: boolean;
    loadMore?: () => void;
}
export default function FormSelectSingle({ label, placeholder, readOnly, required, name, control, defaultValue, options, error, onSearch, readonly, contentReadonly, style, disabled, hasCustomFilter = false, loadMore }: IProps) {
    return (
        <ReadonlyField readonly={readonly} contentReadonly={contentReadonly} label={label} value={defaultValue}>
            <Form.Item label={`${label} ${required ? '*' : ''}`} style={style}>
                <Controller
                    name={name}
                    defaultValue={defaultValue}
                    control={control}
                    disabled={disabled}
                    render={({ field }: any) => {
                        if (field.name === 'client_id') {
                            console.log(field);
                        }
                        return <Select
                            showSearch
                            readOnly={readOnly}
                            placeholder={placeholder}
                            onSearch={onSearch}
                            filterOption={hasCustomFilter ? false : (input, option) =>
                                option && option.label && option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={options}
                            {...field}
                            onPopupScroll={(e: any) => loadMore && e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight && loadMore()}
                        />
                    }
                   }
                />
                <FormError message={error?.message} />
            </Form.Item>
        </ReadonlyField>
    )
}