import { useMemo } from "react"

interface IProps{
    value: boolean
}
export default function StatusYesOrNo({ value }: IProps) {

    const borderColor = useMemo(() => {
        switch (value) {
            case true:
                return '#ABEFC6';
            case false:
                return '#FECDCA';
            default:
                return '#FECDCA';
        }
    }, [value]);

    const backgroundColor = useMemo(() => {
        switch (value) {
            case true:
                return '#ECFDF3';
            case false:
                return '#FEF3F2';
            default:
                return '#FEF3F2';
        }
    }, [value]);

    const color = useMemo(() => {
        switch (value) {
            case true:
                return '#067647';
            case false:
                return '#B42318';
            default:
                return '#B42318';
        }
    }, [value]);

    const label = useMemo(() => {
        switch (value) {
            case true:
                return 'Oui';
            case false:
                return 'Non';
            default:
                return 'Non';
        }
    }, [value]);

    return (
        <div className="status-task" style={{
            borderColor: borderColor,
            backgroundColor: backgroundColor,
            color: color,
            paddingRight: 8,
            paddingLeft: 8,
            paddingTop: 2,
            paddingBottom: 1,
            borderRadius: 20,            
        }}>
            <label style={{fontWeight: 500}}>{label}</label>
        </div>
    )
}