import { Drawer } from "antd";
import { useEffect, useState } from "react";

interface IProps{
    title: React.ReactNode;
    isOpen?: boolean;
    children?: React.ReactNode;
    onClose: () => void;
}
export default function Panel({ title, isOpen = false, children, onClose }: IProps) {
    return (
        <Drawer
            title={title}
            placement="right"
            width={720}
            open={isOpen}
            closeIcon={false}
            onClose={onClose}
        >
            {children}
        </Drawer>
    )
}