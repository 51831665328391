import { Link, useParams } from "react-router-dom";
import HasPermission from "../../components/core/commons/has-permission/has-permission";
import { ERole } from "../../models/user";
import { useEffect, useState } from "react";
import Contract from "../../models/contract";
import roadContractsService from "../../services/road-contracts.service";
import HasFound from "../../components/core/commons/has-found/has-found";
import { Col, Layout, Row, Space, Spin } from "antd";
import Header from "../../components/layout/Header";
import { Text } from "../../components/ui/typo/Text";
import { RightOutlined } from "@ant-design/icons";
import ReadonlyField from "../../components/form/readonly-field";
import UserTag from "../../components/core/commons/user-tag/user-tag";
import dateHelper from "../../utils/date.helper";
import SectionNotesAndTasks from "../../components/core/commons/section-notes-and-tasks.tsx/section-notes-and-tasks";
import ListDocumentsContract from "../../components/core/road-folder/list-documents-contract";


export default function DetailRoadContractPage() {
    const id = useParams().id;
    const [notFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const [contract, setContract] = useState<Contract>();

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                setContract(await roadContractsService.find(Number(id)))
            } catch (e: any) {
                console.log(e);
                if (e.response.status == 404) {
                    setNotFound(true);
                }
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [id])

     
    return (
        <HasPermission permission={ERole.ROLE_ROAD_TRANSPORTS}>
            <HasFound notFound={notFound} title="Le contrat n'existe pas !">
                <>
                    {loading && <Spin />}
                        {contract && <>
                            <Header breadcrumb={[
                                {
                                    title: (
                                        <Space size={16}>
                                                <Link to="/road-contracts"><Text target="H1">Contrat de transport route</Text></Link>
                                                <RightOutlined />
                                                    <Text target="BreadCrumbDetail">Contrat n°{ contract.id}</Text>
                                            </Space>
                                        ),
                                    },
                                ]} />
                            <Layout>
                                <Row style={{ scrollBehavior: 'auto' }}>
                                    <Col className="gutter-row container full-height-content" span={15}>
                                        <Space direction="vertical" style={{ width: "100%" }}>
                                            <Text target="SectionTitle">Informations générales</Text>

                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Client"
                                                        readonly
                                                        contentReadonly={ contract.client ? contract.client.name : '-'}
                                                    />
                                                </Col>

                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Nom du contact"
                                                        readonly
                                                        contentReadonly={contract.contact}
                                                    />
                                                </Col>
                                            
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Code du contrat"
                                                        readonly
                                                        contentReadonly={contract.code ? contract.code : '-'}
                                                    />
                                                </Col>
                                            
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Référence client"
                                                        readonly
                                                        contentReadonly={contract.client_reference ? contract.client_reference : '-'}
                                                    />
                                                </Col>
                                            
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Référence cotation"
                                                        readonly
                                                    contentReadonly={contract.demande?.last_devis ?
                                                        <Link to={`/road-quotations?id=${contract.demande?.id}`}>{contract.demande?.last_devis?.code}</Link>
                                                        : '-'}
                                                    />
                                                </Col>
                                            </Row>   
                                        
                                            <Text target="SectionTitle">Informations trajet</Text>

                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Statut"
                                                        readonly
                                                        contentReadonly={ contract.status ? contract.status : '-'}
                                                    />
                                                </Col>
                                                
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Affectation principale (affréteur)"
                                                        readonly
                                                        contentReadonly={contract.assigned_user ? <UserTag user={contract.assigned_user} /> : <>-</>}
                                                    />
                                                </Col>
                                            
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Date / Heure de chargement"
                                                        readonly
                                                        contentReadonly={ <Text target="Regular">{dateHelper.dateToDDMMYYYYHHMM(contract.loading_date_start)}</Text> }
                                                    />
                                                </Col>
                                                
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Origine"
                                                        readonly
                                                        contentReadonly={ contract.getOriginLabel()}
                                                    />
                                                </Col>
                                                
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Date / Heure de livraison"
                                                        readonly
                                                        contentReadonly={ <Text target="Regular">{dateHelper.dateToDDMMYYYYHHMM(contract.delivery_date_start)}</Text> }
                                                    />
                                                </Col>
                                                
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Destination"
                                                        readonly
                                                        contentReadonly={ contract.getDestinationLabel()}
                                                    />
                                                </Col>
                                                
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Type"
                                                        readonly
                                                        contentReadonly={ contract.type}
                                                    />
                                                </Col>
                                                
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Quantité"
                                                        readonly
                                                        contentReadonly={ contract.quantity ? contract.quantity : '-'}
                                                    />
                                                </Col>
                                            
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Nom transporteur"
                                                        readonly
                                                        contentReadonly={ contract.carrier_name ? contract.carrier_name : '-'}
                                                    />
                                                </Col>

                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Conducteur"
                                                        readonly
                                                        contentReadonly={ contract.driver ? contract.driver : '-'}
                                                    />
                                                </Col>

                                            </Row>
                                        
                                            <Text target="SectionTitle">Informations tarif</Text>

                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Matière dangereuse"
                                                        readonly
                                                        contentReadonly={ contract.dangerous_substances ? 'Oui' : 'Non'}
                                                    />
                                                </Col>
                                                
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Prix de vente (hors surtaxe gasoil)"
                                                        readonly
                                                        contentReadonly={ contract.selling_price ? `${contract.selling_price} € HT` : '-'}
                                                    />
                                                </Col>
                                                
                                                <Col span={12}>
                                                    <ReadonlyField
                                                        label="Prix d’achat"
                                                        readonly
                                                        contentReadonly={ contract.purchase_price ? `${contract.purchase_price} € HT` : '-'}
                                                    />
                                                </Col>
                                                
                                            </Row>
                                            
                                            <Text target="SectionTitle">Document(s) relatif(s) au contrat</Text>
                                            {contract && <ListDocumentsContract customerId={contract.client_id} contratId={contract.id} />}

                                        </Space>
                                    </Col>
                                
                                     <Col
                                        className="gutter-row container full-height-content detail-customer-sider-wrapper"
                                        span={9}
                                        style={{ backgroundColor: "#F9FAFB" }}
                                    >
                                        <SectionNotesAndTasks customerId={contract.client_id} reference={contract.code} />
                                    </Col>

                                </Row>
                            </Layout>
                        </>
                }
                </>
            </HasFound>
        </HasPermission>
    )
}