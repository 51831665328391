import fr from "antd/locale/fr_FR";

export const AntConf = {
  theme: {
    token: {
      colorPrimary: "#242C43",
      colorLink: "#242C43",
      borderRadius: 4,
      borderRadiusLG: 8,
      colorBgLayout: "#FFF",
      colorBgContainer: "#FFF",
      fontFamily: "Inter",
    },
    components: {
      Layout: {
        headerBg: "#ffffff",
        headerPadding: "0 24px",
        siderBg: "#30374F",
        headerHeight: 72,
      },
      Menu: {
        fontSize: 16,
        fontWeight: 600,
        activeBarBorderWidth: 0,
        iconMarginInlineEnd: 12,
        itemBorderRadius: 6,
        itemMarginBlock: 8,
        itemPaddingInline: 6,
        itemMarginInline: 0,
        darkItemBg: "#30374f",
        darkItemColor: "#FFF",
        darkSubMenuItemBg: "#30374f",
        darkItemSelectedBg: "#4A5578",
        darkItemHoverBg: "#4A5578",
        subMenuItemBg: "#30374f",
      },
      Table: {
        cellPaddingBlock: 8,
        headerBg: "#F9FAFB",
      },
      Form: {
        itemMarginBottom: 16,
      },
      Input: {
        controlHeight: 40,
        borderRadius: 8,
      },
      InputNumber: {
        controlHeight: 40,
        borderRadius: 8,
      },
      Select: {
        controlHeight: 40,
        borderRadius: 8,
      },
      Button: {
        controlHeight: 40,
        borderRadius: 8,
        contentFontSizeLG: 14,
        paddingBlock: 10,
        paddingInline: 14,
        onlyIconSizeLG: 22,
        primaryShadow: "1px 1px 10px 0px rgba(24, 34, 48, 0.05)",
      },
      Typography: {
        fontFamilyCode: "Inter",
      },
    },
  },
  locale: fr,
};
