import Customer from "./customer"

export default class Facture{
    id: number
    num: string
    client_code: string
    date: Date
    due_date: Date
    status: string
    contracts: { [key: string]: number | null  }
    file_path: string
    created_at: Date
    updated_at: Date
    public_url: string
    client?: Customer
   
    constructor(data: any) {
        this.id = data.id;
        this.num = data.num;
        this.client_code = data.client_code;
        this.date = new Date(data.date);
        this.due_date = new Date(data.due_date);
        this.status = data.status;
        this.contracts = data.contracts;
        this.file_path = data.file_path;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.public_url = data.public_url;
        this.client = data.client ? new Customer(data.client) : undefined;
    }
    
}