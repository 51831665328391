import { Drawer, Input, Select } from "antd";
import { Button, DatePicker, Form, Space } from "antd";
import { Text } from "../../../ui/typo/Text";
import { ArrowLeftOutlined, CheckOutlined } from "@ant-design/icons";
import GroupFilter from "../../../../models/utils/group-filter";
import { Fragment, useEffect, useMemo, useState } from "react";
import { FilterType } from "../../../../models/utils/filter";
import useDebounce from "../../../../utils/hooks/useDebounce";
import customerService from "../../../../services/customer.service";
import DataPaginate from "../../../../models/utils/data-paginate";
import Customer from "../../../../models/customer";
import userService from "../../../../services/user.service";
import User from "../../../../models/user";
import agencieService from "../../../../services/agencie.service";
import Agencie from "../../../../models/agencie";

interface IProps{
    isOpen: boolean;
    groupFilter?: GroupFilter;
    onClose: () => void;
    onAppliedFilters: (groupFilter: GroupFilter) => void;
}
export default function DrawerFilterDetail({ isOpen, groupFilter, onClose, onAppliedFilters }: IProps) {
    const [selectedGroupFilter, setSelectedGroupFilter] = useState<GroupFilter>();
    useEffect(() => {
        if (groupFilter) {
            // Crée une nouvelle instance de GroupFilter pour éviter toute mutation
            const clonedGroupFilter = new GroupFilter({ 
                label: groupFilter.label, 
                filters: groupFilter.filters.map(filter => ({ ...filter })), 
                formater: groupFilter.formater 
            });
            setSelectedGroupFilter(clonedGroupFilter);
        } else {
            setSelectedGroupFilter(undefined);
        }
    }, [groupFilter])

    function updateFilterDate(name: string, value: any) {
        const filter = selectedGroupFilter?.filters.find(f => f.name === name);
        if (filter) {
            filter.value = value;
            filter.labelValue = value ? value.format("DD/MM/YYYY") : "";
        }
    }

    function updateFilterSelect(name: string, value: any, list: {label: string, value: any}[]) {
        const filter = selectedGroupFilter?.filters.find(f => f.name === name);
        if (filter) {
            filter.value = value;
            filter.labelValue = list.find(l => l.value === value)?.label;
        }
    }

    const [clientSearch, setClientSearch] = useState<string>('');
    const handleSearchClient = useDebounce((term) => {
        setClientsPage(1);
        setClientSearch(term)
    }, 500);
    const [clientsPaginated, setClientsPaginated] = useState<DataPaginate>();
    const [clientsPage, setClientsPage] = useState(1);

    useEffect(() => {
        async function fetchData() {
            try {
                const hasFilterClient = groupFilter?.filters.find(f => f.type === FilterType.DROPDOWN_CLIENT) !== null;
                if (hasFilterClient) {
                    const res = await customerService.list(clientsPage, clientSearch);
                    if (clientsPage > 1 && clientsPaginated) {
                        res.setData([...clientsPaginated.data, ...res.data]);
                    }
                    setClientsPaginated(res);
                }
            } catch (e) {
                console.log(e);
            }
        }
        fetchData();
    }, [clientSearch, clientsPage])
    const clientOptions = useMemo(() => {
        if (!clientsPaginated || !clientsPaginated.data) return [];
        
        const data = clientsPaginated.data.map((customer: Customer) => ({ label: customer.name, value: customer.id.toString() }));
        const currentFilter = selectedGroupFilter?.filters.find(f => f.type === FilterType.DROPDOWN_CLIENT);
        if (currentFilter && currentFilter.value && currentFilter.labelValue && !data.find(d => d.value === currentFilter.value)) {
            data.push({ label: currentFilter.labelValue, value: currentFilter.value.toString() });
        }
        return data;
    }, [clientsPaginated]);

    function updateFilterDropdown(name: string, value: any, list: {label: string, value: any}[]) {
        const filter = selectedGroupFilter?.filters.find(f => f.name === name);
        if (filter) {
            filter.value = value;
            filter.labelValue = list.find(l => l.value === value)?.label;
        }
    }

    function updateFilterText(name: string, value: any) {
        const filter = selectedGroupFilter?.filters.find(f => f.name === name);
        if (filter) {
            filter.value = value;
            filter.labelValue = value;
        }
    }

    const [userSearch, setUserSearch] = useState<string>('');
    const handleSearchUser = useDebounce((term) => setUserSearch(term), 500);
    const [usersPaginated, setUsersPaginated] = useState<DataPaginate>();
    useEffect(() => {
        async function fetchData() {
            try {
                const hasFilterUser = groupFilter?.filters.find(f => f.type === FilterType.DROPDOWN_USER) !== null;
                if (hasFilterUser) {
                    const res = await userService.list(1, userSearch);
                    setUsersPaginated(res);
                }
            } catch (e) {
                console.log(e);
            }
        }
        fetchData();
    }, [userSearch])
    const userOptions = useMemo(() => {
        if (!usersPaginated || !usersPaginated.data) return [];

        const data = usersPaginated.data.map((user: User) => ({ label: user.getFullName(), value: user.id.toString() }));
        const currentFilter = selectedGroupFilter?.filters.find(f => f.type === FilterType.DROPDOWN_USER);
        if (currentFilter && currentFilter.value && currentFilter.labelValue && !data.find(d => d.value === currentFilter.value)) {
            data.push({ label: currentFilter.labelValue, value: currentFilter.value.toString() });
        }

        return data;
    }, [usersPaginated]);

    const [agenciesSearch, setAgenciesSearch] = useState<string>('');
    const handleSearchAgencies = useDebounce((term) => setAgenciesSearch(term), 500);
    const [agenciesPaginated, setAgenciesPaginated] = useState<DataPaginate>();
    useEffect(() => {
        async function fetchData() {
            try {
                const hasFilterAgencies = groupFilter?.filters.find(f => f.type === FilterType.DROPDOWN_AGENCY) !== null;
                if (hasFilterAgencies) {
                    const res = await agencieService.list(1, agenciesSearch);
                    setAgenciesPaginated(res);
                }
            } catch (e) {
                console.log(e);
            }
        }
        fetchData();
    }, [agenciesSearch])
    const agenciesOptions = useMemo(() => {
        if (!agenciesPaginated || !agenciesPaginated.data) return [];

        const data = agenciesPaginated.data.map((agencie: Agencie) => ({ label: agencie.name, value: agencie.id.toString() }));
        const currentFilter = selectedGroupFilter?.filters.find(f => f.type === FilterType.DROPDOWN_AGENCY);
        if (currentFilter && currentFilter.value && currentFilter.labelValue && !data.find(d => d.value === currentFilter.value)) {
            data.push({ label: currentFilter.labelValue, value: currentFilter.value.toString() });
        }

        return data;
    }, [agenciesPaginated]);


    return (
        <Drawer
            title={<Text target="H1">Filtres</Text>}
            placement="right"
            width={680}
            open={isOpen}
            onClose={onClose}
            footer={
                <Space size={16} style={{ justifyContent: "flex-end", width: "100%" }}>
                <Button type="default" onClick={onClose}>
                    <Space size={6}>
                        <ArrowLeftOutlined />
                        <Text target="Bold">Annuler</Text>
                    </Space>
                </Button>
                    <Button type="primary" onClick={() => {
                        if (selectedGroupFilter) {
                            onAppliedFilters(selectedGroupFilter);
                        }
                    }}>
                    <Space size={6}>
                        <CheckOutlined />
                        <Text target="Bold">Appliquer</Text>
                    </Space>
                </Button>
                </Space>
            }
            >
            <Form layout="vertical">
                {groupFilter && <Space direction="vertical" size={16} style={{ width: "100%" }}>
                    <Text target="H2">{groupFilter?.label}</Text>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        {groupFilter?.filters.map((filter, index) => (
                            <Fragment key={index}>
                                <Form.Item key={index} label={filter.label}>
                                    {filter.type === FilterType.DATE && <DatePicker
                                        onChange={(value) => updateFilterDate(filter.name, value)}
                                        placeholder="-- / -- / ----"
                                        suffixIcon={false}
                                        format="DD/MM/YYYY"
                                        style={{ width: "100%" }}
                                        defaultValue={filter.value}
                                    />}

                                    {filter.type === FilterType.SELECT && <Select
                                        showSearch
                                        placeholder={''}
                                        options={filter.list} 
                                        onChange={(value) => updateFilterSelect(filter.name, value, filter.list ? filter.list : [])}
                                        defaultValue={filter.value}
                                        allowClear={true}
                                        onClear={() => updateFilterSelect(filter.name, undefined, filter.list ? filter.list : [])}
                                    />
                                    }

                                    {filter.type === FilterType.DROPDOWN_CLIENT &&
                                        <Select
                                            showSearch
                                            placeholder={""}
                                            onSearch={handleSearchClient}
                                            filterOption={ false }
                                            options={clientOptions}
                                            onChange={(value) => {
                                                updateFilterDropdown(filter.name, value, clientOptions)
                                            }}
                                            defaultValue={filter.value}
                                            allowClear={true}
                                            onClear={() => updateFilterDropdown(filter.name, undefined, clientOptions)}
                                            onPopupScroll={(e: any) => clientsPaginated?.last_page && clientsPage < clientsPaginated?.last_page && e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight && setClientsPage(clientsPage + 1)}
                                        />    
                                    }

                                    {filter.type === FilterType.DROPDOWN_USER &&
                                        <Select
                                            showSearch
                                            placeholder={""}
                                            onSearch={handleSearchUser}
                                            filterOption={false}
                                            options={userOptions}
                                            onChange={(value) => updateFilterDropdown(filter.name, value, userOptions)}
                                            defaultValue={filter.value}
                                            allowClear={true}
                                            onClear={() => updateFilterDropdown(filter.name, undefined, userOptions)}
                                        />   
                                    }

                                    {filter.type === FilterType.DROPDOWN_AGENCY &&
                                        <Select
                                            showSearch
                                            placeholder={""}
                                            onSearch={handleSearchAgencies}
                                            filterOption={false}
                                            options={agenciesOptions}
                                            onChange={(value) => updateFilterDropdown(filter.name, value, agenciesOptions)}
                                            defaultValue={filter.value}
                                            allowClear={true}
                                            onClear={() => updateFilterDropdown(filter.name, undefined, agenciesOptions)}
                                        />   
                                    }

                                    {filter.type === FilterType.TEXT && <Input
                                        placeholder=""
                                        onChange={(e: any) => updateFilterText(filter.name, e.target.value)}
                                        defaultValue={filter.value}
                                    />}
                                
                                </Form.Item>
                            </Fragment>
                            
                        ))}
                        
                    </Space>
                </Space>}
            </Form>
        </Drawer>

    )
}