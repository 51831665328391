import { Button, Flex, Input, Layout, Space, Table } from "antd";
import Header from "../../components/layout/Header";
import { Icon } from "../../components/ui/typo/Icon";
import { Text } from "../../components/ui/typo/Text";
import { useEffect, useMemo, useState } from "react";
import DataPaginate from "../../models/utils/data-paginate";
import ButtonColumn from "../../components/ui/buttons/button-column";
import useDebounce from "../../utils/hooks/useDebounce";
import useTable from "../../utils/hooks/useTable";
import DeletionModal from "../../components/ui/modal/deletion-modal";
import { useAppDispatch } from "../../store/hooks";
import { addToast } from "../../store/toasts/reducer";
import Toast from "../../models/utils/toast";
import Agencie from "../../models/agencie";
import { columns } from "../../configs/AgencieTableColumns";
import agencieService from "../../services/agencie.service";
import PanelFormAgencie from "../../components/core/agencies/panel-form-agencie";


export default function ListAgenciesPage() {
    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
    const [showModal, setShowModal] = useState(false);
    const [editingAgencie, setEditingAgencie] = useState<Agencie>();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState<string>('');
    const { selectedRowKeys, rowSelection, visibleColumns, setVisibleColumns, renderColumnsAndSelection, showModalDeletion, setShowModalDeletion, resetRowSelection } = useTable(columns)
    const dispatch = useAppDispatch();

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const res = await agencieService.list(page, search);
                setDatapaginate(res);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [page, search])

    

    const dataTable = useMemo(() => {
        if (!dataPaginate || !dataPaginate.data) return [];
        return dataPaginate.data.map((item: Agencie) => {
            return {
                id: item.id,
                key: item.id,
                name: item.name || '-',
                code: item.code || '-',
                phone: item.phone || '-',
                email: item.email || '-',
            }
        });
    }, [dataPaginate]);

    const handleSearch = useDebounce((term) => {
        setPage(1);
        setSearch(term)
    }, 500);

    async function onClose(refresh?: boolean) {
        setShowModal(false)
        setEditingAgencie(undefined);
        if (refresh) {
            try {
                setLoading(true);
                const res = await agencieService.list();
                setDatapaginate(res);
                
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
                resetRowSelection();
            }
        }
    }

    function onEditAgencie() {
        const res = dataPaginate?.data?.find(item => item.id === selectedRowKeys[0]);
        if (res) {
            setEditingAgencie(res);
            setShowModal(true);
        }
    }

    async function onDelete() {
        try {
            setLoading(true);
            const listToDelete = dataPaginate?.data?.filter(item => selectedRowKeys.includes(item.id));
            if (listToDelete) {
                for (const item of listToDelete) {
                    await agencieService.destroy(item.id);

                }
                dispatch(addToast(new Toast('Les agences ont étaient supprimés avec succès', 'success')))
                if(dataPaginate && dataPaginate.data.length === selectedRowKeys.length){
                    setPage(page - 1)
                } else {
                    const res = await agencieService.list(page, search);
                    setDatapaginate(res);
                }
            }
        } catch (e) {
            dispatch(addToast(new Toast('Une erreur est survenue lors de la suppression des agences', 'error')))
            console.log(e);
        } finally {
            setLoading(false);
            setShowModalDeletion(false);
            resetRowSelection();
        }
    }

    return (
        <>
            <Header title="Gestion des agences">
                <Button type="primary" onClick={() => setShowModal(true)}>
                    <Space size={6}>
                        <Icon path="/icons/plus.svg" size={20} />
                        <Text target="Bold">Ajouter une agence</Text>
                    </Space>
                </Button>
            </Header>
            <Layout className="container">

                <Flex vertical gap={16}>
                    <Flex justify="space-between">
                        <Input
                        size="large"
                        placeholder="Rechercher..."
                        prefix={<Icon path="/icons/search-refraction.svg" size={20} />}
                        style={{ width: 320 }}
                        onChange={(e) => handleSearch(e.target.value)}
                        />
                        <Space size={8}>
                            <ButtonColumn columns={columns} visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} keyCache="agencies" />
                        </Space>
                    </Flex>

                    <Table
                        rowKey={"id"}
                        loading={loading}
                        columns={renderColumnsAndSelection(visibleColumns, selectedRowKeys, onEditAgencie)}
                        dataSource={dataTable}
                        scroll={{ x: 1327, y: 500 }}
                        showSorterTooltip={false}
                        pagination={{
                            current: page,
                            pageSize: dataPaginate?.per_page || 10,
                            total: dataPaginate?.total || 0,
                            onChange: (page) => {
                                setPage(page)
                            },
                        }}
                        rowSelection={rowSelection}
                    />
                </Flex>
            </Layout>
                        
            {showModal && <PanelFormAgencie agencie={editingAgencie} showModal={showModal} onClose={(refresh) => onClose(refresh)} />}
            {showModalDeletion && <DeletionModal
                modalOpen={showModalDeletion}
                title="Suppression d'agences"
                description="Voulez-vous vraiment supprimer ces agences ?"
                onCancel={() => setShowModalDeletion(false)}
                onConfirm={() => onDelete()}
            />}
        </>
    )
}