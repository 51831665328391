import { Flex, Table } from "antd";
import { columns } from "../../../configs/CustomerContactTableColumns";
import Account from "../../../models/account";
import useTable from "../../../utils/hooks/useTable";
import { Text } from "../../ui/typo/Text";
import { useMemo } from "react";
import dateHelper from "../../../utils/date.helper";

interface IProps{
    accounts: Account[]
}
export default function CustomerContactList({ accounts }: IProps) {
    const { renderColumns } = useTable(columns)

      const dataTable = useMemo(() => {
        if (!accounts || !accounts.length) return [];
        return accounts.map((item: Account) => {
        return {
            id: item.id,
            name: item.name,
            email: item.email,
            created_at: dateHelper.dateToDDMMYYYY(item.created_at),
        }
        });
    }, [accounts]);

    return (
        <Flex vertical gap={16}>
            <Text target="SectionTitle">Contacts</Text>

            <Table
                rowKey={"id"}
                columns={renderColumns()}
                dataSource={dataTable}
                scroll={{ x: 600, y: 1080 }}
                showSorterTooltip={false}
                pagination={{
                    pageSize: 10,
                    total: accounts.length || 0,
                }}
            />
        </Flex>
    )
}