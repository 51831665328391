import React from "react";
import { Typography } from "antd";
import type { TextProps } from "antd/es/typography/Text";

interface CustomProps {
  target:
    | "H1"
    | "H2"
    | "H2-Invert-Primary"
    | "H3"
    | "Bold"
    | "Regular"
    | "Regular-Primary"
    | "Regular-Grey"
    | "XSmall"
    | "XSmall-Grey"
    | "TableHeaderLabel"
    | "ItemHeaderLabel"
    | "BreadCrumbDetail"
    | "SectionTitle"
    | "NotesName"
    | "NotesDate"
    | "NotesLightMessage"
    | "NotesDarkMessage"
    | "LoginTitle"
    | "Regular-Invert-Secondary"
    | "Bold-Invert-Secondary"
    | "CardNumber";
  children: React.ReactNode;
}

export type LocalTextProps = CustomProps & TextProps;

export const Text: React.FC<LocalTextProps> = (props: LocalTextProps) => {
  const { target, children, ...rest } = props;

  return (
    <Typography.Text className={`text-${target}`} {...rest}>
      {children}
    </Typography.Text>
  );
};
