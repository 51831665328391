import { Icon } from "../components/ui/typo/Icon";
import type { MenuProps } from "antd";
import { ERole } from "../models/user";
import TaskItemMenu from "../components/core/tasks/task-item-menu";
import RoadDemandItemMenu from "../components/core/road-folder/road-demand-item-menu";

type MenuItem = Required<MenuProps>["items"][number];
type MenuItemWithPermission = MenuItem & {
  permission?: ERole;
};

export const MenuConf: MenuItemWithPermission[] = [
  {
    key: "/",
    label: "Accueil",
    icon: <Icon path="/icons/home-inline.svg" size={24} />,
  },
  {
    key: "dossiers-route",
    label: "Dossiers route",
    icon: <Icon path="/icons/truck-white.svg" size={24} />,
    permission: ERole.ROLE_ROAD_TRANSPORTS,
    children: [
      {
        key: "road-quotations?add=true",
        label: <div className="flex row align-item-center justify-content-between">Créer une cotation <Icon path="./icons/plus-circle.svg" size={20} /></div>,
      },
      {
        key: "road-quotations",
        label: <RoadDemandItemMenu />,
      },
      {
        key: "road-contracts",
        label: "Contrats de transport",
      },
    ],
  },
  {
    key: "dossiers-maritime",
    label: "Dossiers maritime",
    disabled: true,
    icon: <Icon path="/icons/waves-white.svg" size={24} />,
    permission: ERole.ROLE_SEA_TRANSPORTS,
    children: [
      {
        key: "cotations-de-transport1",
        label: "Cotations de transport",
      },
      {
        key: "contrats-de-transport1",
        label: "Contrats de transport",
      },
      {
        key: "creer-une-cotation1",
        label: "Créer une cotation",
      },
    ],
  },
  {
    key: "customers",
    label: "Fiches clients",
    icon: <Icon path="/icons/files.svg" size={24}/>,
  },
  {
    key: "tasks",
    label: <TaskItemMenu />,
    icon: <Icon path="/icons/clipboard-check.svg" size={24} />,
  },
  {
    key: "reporting",
    label: "Reporting",
    icon: <Icon path="/icons/pie-chart.svg" />,
    permission: ERole.ROLE_BI_ACCESS,
    children: [
      {
        key: "reporting-route",
        label: "Reporting route",
      },
      {
        key: "reporting-maritime",
        label: "Reporting maritime",
      },
    ],
    disabled: true,
  },
  {
    key: "gestion-des-tarifs",
    label: "Gestion des tarifs",
    icon: <Icon path="/icons/settings-line.svg" />,
    permission: ERole.ROLE_PRICING_REFERENCE,
    children: [
      {
        key: "tarifs-de-reference",
        label: "Tarifs de référence",
        disabled: true
      },
      {
        key: "matrices",
        label: "Matrices",
        disabled: true
      },
      {
        key: "montant-des-options",
        label: "Montant des options",
        disabled: true
      },
      {
        key: "annexes",
        label: "Annexes au devis",
      },
    ],
    disabled: false,
  },
  {
    key: "reglages",
    label: "Réglages",
    icon: <Icon path="/icons/settings-white.svg" />,
    permission: ERole.ROLE_MANAGE_USERS,
    children: [
      {
        key: "users",
        label: "Gestion des utilisateurs",
      },
      {
        key: "agencies",
        label: "Gestion des agences",
      },
    ],
  },
];
