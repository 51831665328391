import { Text } from "../typo/Text";

interface MessageProps {
  content: string;
  theme: "light" | "dark";
}

export default function Message({ content, theme }: MessageProps) {
  return (
    <>
      {theme == "dark" && (
        <div className="darkMessageWrapper">
          <Text target="NotesLightMessage">{content}</Text>
        </div>
      )}
      {theme == "light" && (
        <div className="lightMessageWrapper">
          <Text target="NotesDarkMessage">{content}</Text>
        </div>
      )}
    </>
  );
}
