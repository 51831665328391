import { useEffect, useMemo, useState } from "react";
import roadDemandesService from "../../services/road-demandes.service";
import DataPaginate from "../../models/utils/data-paginate";
import useTable from "../../utils/hooks/useTable";
import { columns } from "../../configs/RoadDemandesTableColumns";
import { useNavigate, useSearchParams } from "react-router-dom";
import useDebounce from "../../utils/hooks/useDebounce";
import RoadDemande from "../../models/RoadDemande";
import dateHelper from "../../utils/date.helper";
import Header from "../../components/layout/Header";
import { Button, Flex, Input, Layout, Space, Table } from "antd";
import { Icon } from "../../components/ui/typo/Icon";
import ButtonColumn from "../../components/ui/buttons/button-column";
import StatusRoadCotation from "../../components/core/road-folder/status-road-cotation";
import { Text } from "../../components/ui/typo/Text";
import HasPermission from "../../components/core/commons/has-permission/has-permission";
import { ERole } from "../../models/user";
import PanelRoadCotation from "../../components/core/road-folder/panel-road-cotation";
import DeletionModal from "../../components/ui/modal/deletion-modal";
import { addToast } from "../../store/toasts/reducer";
import Toast from "../../models/utils/toast";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import DrawerFilterRoadQuotation from "../../components/core/commons/drawer-filter/drawer-filter";
import GroupFilter from "../../models/utils/group-filter";
import Filter, { FilterType } from "../../models/utils/filter";
import { StatusRoadDemande, statusRoadDemandeList } from "../../models/enums/status-road-demande.enum";
import dayjs from 'dayjs';
import { setRemainingRoadDemandes } from "../../store/road-demandes/reducer";

export default function ListRoadQuotationsPage() {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
    const [page, setPage] = useState<number>(searchParams.has('page') ? parseInt(searchParams.get('page') || '1') : 1);
    const [search, setSearch] = useState<string>(searchParams.get('search') || '');
    const { selectedRowKeys, rowSelection, visibleColumns, setVisibleColumns, renderColumnsAndSelection, showModalDeletion, setShowModalDeletion, resetRowSelection } = useTable(columns, undefined, undefined, undefined)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(searchParams.has('add') ? true : false);
    const [showFilters, setShowFilters] = useState(false);
    const {value : user} = useAppSelector(state => state.user);
    const [isCurrentUserFilterActive, setIsCurrentUserFilterActive] = useState(searchParams.has('assignee') && searchParams.get('assignee') == user?.id.toString() ? true : false);

    useEffect(() => {
        if(searchParams.has('add')) {
            setShowModal(true);
        }
    }, [searchParams]);

    const [groupFilters, setGroupFilters] = useState<GroupFilter[]>([
        new GroupFilter({
            label: "Client",
            filters: [
                new Filter({
                    name: "client_id",
                    label: "Nom du client",
                    type: FilterType.DROPDOWN_CLIENT,
                    value: searchParams.get('client_id') || undefined,
                })
            ],
            formater: function(){
                return this.filters[0].labelValue ? this.filters[0].labelValue : ""

            }
        }),
        new GroupFilter({
            label: "Date de la demande de transport",
            filters: [
                new Filter({
                    name: "date_from",
                    label: "Date de début",
                    type: FilterType.DATE,
                    value: searchParams.get('date_from') ? dayjs(searchParams.get('date_from')) : undefined,
                }),
                new Filter({
                    name: "date_to",
                    label: "Date de fin",
                    type: FilterType.DATE,
                    value: searchParams.get('date_to') ? dayjs(searchParams.get('date_to')) : undefined,
                }),
            ],
            formater: function(){
                if (this.filters[0].labelValue && this.filters[1].labelValue) {
                    return `Du ${this.filters[0].labelValue} au ${this.filters[1].labelValue}`
                } else if (this.filters[0].labelValue) {
                    return `Depuis le ${this.filters[0].labelValue}`
                } else if (this.filters[1].labelValue) {
                    return `Jusqu'au ${this.filters[1].labelValue}`
                } else {
                    return ""
                }
            }
        }),
        new GroupFilter({
            label: "Affectation principale",
            filters: [
                new Filter({
                    name: "assignee",
                    label: "Nom de l'affectation principale",
                    type: FilterType.DROPDOWN_USER,
                    value: searchParams.get('assignee') || undefined
                })
            ],
            formater: function() {
                return this.filters[0].labelValue ? this.filters[0].labelValue : "" 
            }
        }),
        new GroupFilter({
            label: "Statut",
            filters: [
                new Filter({
                    name: "status",
                    label: "Statut",
                    type: FilterType.SELECT,
                    list: statusRoadDemandeList,
                    value: searchParams.get('status') || undefined
                })
            ],
            formater: function() {
                return this.filters[0].labelValue ? this.filters[0].labelValue : ""
            }
        }),
    ]);
    const nbFilters = useMemo(() => {   
        return groupFilters.reduce((acc, group) => {
            return acc + group.filters.filter(f => f.value != null).length;
        }, 0)
    }, [groupFilters]);

    const [cotation, setCotation] = useState<RoadDemande>();


    const filters = useMemo(() => {
        return groupFilters.map(g => {
            const enabledFilters = g.filters.filter(f => {
                return f.value != null
            })
            return enabledFilters;
        }).flat().map(f => ({ name: f.name, value: f.value }));
    }, [groupFilters]);

    useEffect(() => {
        const queryParams = new URLSearchParams();
        if (page) {
            queryParams.append('page', page.toString());
        }
        if (filters.length > 0) {
            filters.forEach(filter => {
                queryParams.append(filter.name, filter.value);
            });
        }
        if (search) {
            queryParams.append('search', search);
        }

        if (isCurrentUserFilterActive) {
            queryParams.append('assignee', user?.id.toString() || '');
        } else {
            queryParams.delete('assignee');
        }

        const queryParamsString = queryParams.toString();
        if (queryParamsString) {
            navigate(`?${queryParams.toString()}`);
        }

        fetchData();

    }, [page, search, filters, isCurrentUserFilterActive]);

    async function fetchData() {
        try {
            setLoading(true);
            const res = await roadDemandesService.list(page, search, filters, isCurrentUserFilterActive ? user?.id : undefined);
            setDatapaginate(res);
            const roadDemandesPaginated = await roadDemandesService.list(1, undefined, [{name: 'status', value: StatusRoadDemande.PENDING_PRICE}] , user?.id);
            dispatch(setRemainingRoadDemandes(roadDemandesPaginated.total));
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }
    
    const dataTable = useMemo(() => {
        if (!dataPaginate || !dataPaginate.data) return [];
        return dataPaginate.data.map((item: RoadDemande) => {
            return {
                id: item.id,
                created_at: dateHelper.dateToDDMMYYYY(item.created_at),
                client: item.client ? item.client.name : '-',
                main_assignee: item.main_assignee ? item.main_assignee.getFullName(): '-',
                created_by: item.author ? item.author.getFullName() : '-',
                estimated_by: item.last_devis && item.last_devis?.creator ? item.last_devis.creator.getFullName() : '-',
                origin: item.getLabelOrigin(),
                destination: item.getLabelDestination(),
                status: <StatusRoadCotation status={item.status} />,
                status_name: item.status
            }
        });
    }, [dataPaginate]);

    const handleSearch = useDebounce((term) => {
        setPage(1);
        setSearch(term)
    }, 500);

    async function onEdit() {
        const cotation = dataPaginate?.data?.find(item => item.id === selectedRowKeys[0]);
        if (cotation) {
            const detailCotation = await roadDemandesService.find(cotation.id);
            setCotation(detailCotation);
            setShowModal(true);
        }
    }

    async function onDelete() {
        try {
            setLoading(true);
            const listToDelete = dataPaginate?.data?.filter(item => selectedRowKeys.includes(item.id));
            if (listToDelete) {
                for (const item of listToDelete) {
                    await roadDemandesService.destroy(item.id);

                }
                dispatch(addToast(new Toast('Les cotations de transport ont été supprimées avec succès', 'success')))
                if (dataPaginate && dataPaginate.data.length === selectedRowKeys.length) {
                    setPage(page ? page - 1: 1)
                } else {
                    const res = await roadDemandesService.list(page, search, filters, isCurrentUserFilterActive ? user?.id : undefined);
                    setDatapaginate(res);
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            setShowModalDeletion(false);
            resetRowSelection();
            const roadDemandesPaginated = await roadDemandesService.list(1, undefined, [{name: 'status', value: StatusRoadDemande.PENDING_PRICE}] , user?.id);
            dispatch(setRemainingRoadDemandes(roadDemandesPaginated.total));
        }
    }
    
    return (
        <HasPermission permission={ERole.ROLE_ROAD_TRANSPORTS}>
            <Header title="Cotations de transport route">
                <Button type="primary" onClick={() => setShowModal(true)}>
                    <Space size={6} onClick={() => setShowModal(true)}>
                        <Icon path="/icons/plus.svg" size={20} />
                        <Text target="Bold">Ajouter une cotation</Text>
                    </Space>
                </Button>
            </Header>
            <Layout className="container">
                <Flex vertical gap={16}>
                <Flex justify="space-between">
                    <Input
                        size="large"
                        placeholder="Rechercher..."
                        prefix={<Icon path="/icons/search-refraction.svg" size={20} />}
                        style={{ width: 320 }}
                        defaultValue={search ? search : undefined}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <Space size={8}>
                        {/*<Select
                            defaultValue="origin"
                            style={{ width: 267 }}
                            onChange={() => console.log('todo')}
                            options={[
                                { value: "origin", label: "Origine" },
                            ]}
                        />*/}
                            {user && <Button icon={<Icon path="./icons/check-done.svg" size={20} />} onClick={() => setIsCurrentUserFilterActive(!isCurrentUserFilterActive)} className={isCurrentUserFilterActive ? 'selected-button' : ''}>
                                <Text target="Bold">Mon suivi</Text>
                            </Button>}
                            <ButtonColumn columns={columns} visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} keyCache="road_quotations"/>
                            <Button icon={<Icon path="./icons/filter-lines.svg" size={20} />} onClick={() => setShowFilters(true)} >
                            <Text target="Bold">Filtres ({nbFilters})</Text>
                        </Button>
                    </Space>
                </Flex>
                <Table
                    rowKey={"id"}
                    loading={loading}
                    columns={renderColumnsAndSelection(visibleColumns, selectedRowKeys, onEdit)}
                    dataSource={dataTable}
                    scroll={{ x: 1327, y: 500 }}
                    showSorterTooltip={false}
                    onRow={(record) => { return { onClick: () => { navigate(`/road-quotations/${record.id}`) } } }}
                    pagination={{
                        current: page,
                        pageSize: dataPaginate?.per_page || 10,
                        total: dataPaginate?.total || 0,
                            onChange: (page) => {
                                setPage(page);
                            },
                    }}
                        rowSelection={rowSelection}
                        
                    rowClassName={(item) => item.status_name === StatusRoadDemande.NEW ? 'row-blue' : ''}
                />
                </Flex>
            </Layout>
            {showModal && <PanelRoadCotation
                showModal={showModal}
                onClose={() => { setShowModal(false); setCotation(undefined); fetchData(); }}
                cotation={cotation}
            />}
            {showModalDeletion && <DeletionModal
                modalOpen={showModalDeletion}
                title="Annulation de cotations de transport"
                description="Voulez-vous vraiment annuler ces cotations de transport ?"
                onCancel={() => setShowModalDeletion(false)}
                onConfirm={() => onDelete()}
            />}

            {showFilters && groupFilters &&
                <DrawerFilterRoadQuotation
                    groupFilters={groupFilters}
                    isOpen={showFilters}
                    onClose={(groups?: GroupFilter[]) => {
                        if (groups) {
                            setGroupFilters(groups)
                        } else {
                            setShowFilters(false)
                        }
                    }}
                />}
        </HasPermission>
    );
}