import axios, { AxiosResponse } from "axios";
import Devis from "../models/Devis";

async function get(demandeId: number) {
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/transport/road/demandes/${demandeId}/devis`).then(async (response: AxiosResponse) => {
        if (Object.keys(response.data).length !== 0) {
            return new Devis(response.data);
        }
        throw new Error('No devis');
    })
}

async function create(demandeId: number, data: any) {
    return await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/app/transport/road/demandes/${demandeId}/devis`, data).then(async (response: AxiosResponse) => {
        return new Devis(response.data);   
    })
}

async function generate(demandeId: number) {
    return await axios.patch(`${import.meta.env.VITE_BACKEND_URL}/api/app/transport/road/demandes/${demandeId}/devis/generate`).then(async (response: AxiosResponse) => {
        return response.data;
    })
}

async function send(demandeId: number) {
    return await axios.patch(`${import.meta.env.VITE_BACKEND_URL}/api/app/transport/road/demandes/${demandeId}/devis/send`).then(async (response: AxiosResponse) => {
        return response.data;
    })
}

async function accept(demandeId: number) {
    return await axios.patch(`${import.meta.env.VITE_BACKEND_URL}/api/app/transport/road/demandes/${demandeId}/devis/accept`).then(async (response: AxiosResponse) => {
        return response.data;
    })
}

async function refuse(demandeId: number) {
    return await axios.patch(`${import.meta.env.VITE_BACKEND_URL}/api/app/transport/road/demandes/${demandeId}/devis/refuse`).then(async (response: AxiosResponse) => {
        return response.data;
    })
}
export default {
    get,
    create,
    generate,
    send,
    accept,
    refuse
}