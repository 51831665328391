import { Form, Modal } from "antd";
import { Space } from "antd";
import { Text } from "../../../components/ui/typo/Text";
import * as yup from "yup"
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import FormSelectSingle from "../../form/form-select-single";
import roadContractsService from "../../../services/road-contracts.service";
import { useAppDispatch } from "../../../store/hooks";
import { addToast } from "../../../store/toasts/reducer";
import Toast from "../../../models/utils/toast";
import { StatusContract } from "../../../models/enums/status-contract.enum";


interface IProps{
    contractIds: number[];
    modalOpen: boolean;
    onClose: (refresh?: boolean) => void;
}
type Inputs = {
    status: string,
}
export default function ModalStatusContract({ contractIds, modalOpen, onClose }: IProps) {
    const dispatch = useAppDispatch();
    const schema: any = yup.object().shape({
        status: yup.string().required(),
    })
    const {
        control,
        getValues
    } = useForm<Inputs>({
        resolver: yupResolver(schema), mode: 'onChange'
    })

    async function onConfirm() {
        try {
            for (const contractId of contractIds) {
                await roadContractsService.updateStatus(contractId, getValues());
            }
            dispatch(addToast(new Toast("Le statut a été modifié !", "success")));
            onClose(true);
        } catch (e) {
            console.log(e);
            dispatch(addToast(new Toast("Une erreur est survenue pendant la mise à jour du statut !", "error")));

        }
        
    }

    const statusOptions = useMemo(() => {
        return [
            { label: 'Heure ?', value: StatusContract.HEURE },
            { label: 'Livraison ?', value: StatusContract.LIVRAISON },
            { label: 'N° de camion ?', value: StatusContract.N_CAMION },
            { label: 'RAF', value: StatusContract.RAF },
            { label: 'Complet', value: StatusContract.COMPLETED },
        ]
    }, []);

    return (
        <Modal
            centered
            width={400}
            open={modalOpen}
            onOk={() => onConfirm()}
            onCancel={() => onClose()}
            okText={"Valider"}
            cancelText="Annuler"
            okButtonProps={{ type: "primary"}}
            cancelButtonProps={{ type: "default" }}
        >
            <Space direction="vertical" size={16} style={{ width: "100%" }}>
                    <Text target="H2">Modifier le statut</Text> 
                  
                    <Form style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} layout="vertical" requiredMark={false}>
                        <FormSelectSingle
                            label="Statut"
                            placeholder="Rechercher un statut"
                            name="status"
                    
                            defaultValue={statusOptions[0].value}
                            control={control}
                            options={statusOptions}
                            onSearch={() => null}
                        />                   
                    </Form>
            </Space>
        </Modal>
    )
}