import { Drawer, Flex, Space, Steps } from "antd";
import { Text } from "../../ui/typo/Text";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import FormQuotationInformations from "./form-quotation-informations";
import RoadDemande from "../../../models/RoadDemande";
import roadDemandesService from "../../../services/road-demandes.service";
import FormQuotationPrice from "./form-quotation-price";
import devisService from "../../../services/devis.service";
import Devis from "../../../models/Devis";
import PreviewDevis from "./preview-devis";

interface IProps{
    showModal: boolean;
    onClose: (refresh?: boolean) => void;
    cotation?: RoadDemande;
    
}
export default function PanelRoadCotation({ showModal, onClose, cotation }: IProps) {
    const [current, setCurrent] = useState(cotation ? cotation.getDefaultTabDemand() : 0);
    const [currentCotation, setCurrentCotation] = useState<RoadDemande | undefined>(cotation);
    const [loading, setLoading] = useState(true);
    const [devis, setDevis] = useState<Devis>();

    async function validateCotation() {
        if (currentCotation) {
            
            setCurrentCotation(await roadDemandesService.find(currentCotation.id));
            setCurrent(1);
        }
    }

    async function fetchDevis(goNext?: boolean) {
        if (currentCotation) {
            try {
                setLoading(true);
                const devis = await devisService.get(currentCotation.id);
                setDevis(devis);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
                if (goNext) {
                    setCurrent(2);
                }
            }
        }
    }

    useEffect(() => {
        fetchDevis();
    }, [currentCotation])

    const canEditForms = useMemo(() => {
        if (devis && (devis.isPending() || devis.isAccepted())) {
            return false;
        }
        return true;
    }, [devis])

    return (
        <Drawer
            title={
                <Space direction="vertical" size={24} style={{ width: "100%" }}>
                    <Flex justify="space-between">
                        <Text target="H2">{cotation ? `${cotation.code} - Edition de la cotation` : 'Nouvelle cotation de transport'}</Text>
                        <CloseOutlined size={24} onClick={() => onClose()} />
                    </Flex>
                    <Steps
                        current={current}
                        onChange={(current) => setCurrent(current)}
                        labelPlacement="vertical"
                        style={{ width: "100%" }}
                        className="steps-new-quotation"
                    >
                        <Steps.Step
                            title="Information"
                            className="steps-item-information"
                        />
                        <Steps.Step
                            title="Prix"
                            className="steps-item-price"
                            disabled={!currentCotation || !currentCotation.canEditPrice()}
                        />
                        <Steps.Step
                            title="Devis"
                            className="steps-item-estimate"
                            disabled={!currentCotation || !currentCotation.canShowDevis()}
                        />
                    </Steps>
                </Space>
            }
            placement="right"
            width={720}
            open={showModal}
            closeIcon={false}
            onClose={() => onClose()}
            >
            {current == 0 && <>
                <FormQuotationInformations
                    cotation={currentCotation}
                    devis={devis}
                    onValidate={() => validateCotation()}
                    canEdit={canEditForms}
                    onSaved={(cotation) => setCurrentCotation(cotation)}
                />
            </>}
            {current == 1 && <>
                {currentCotation && !loading && <FormQuotationPrice
                    cotation={currentCotation}
                    devis={devis}
                    refreshDevis={async (goNext?: boolean) => {
                        fetchDevis(goNext);
                        setCurrentCotation(await roadDemandesService.find(currentCotation.id));
                    }}
                    canEdit={canEditForms}
                />}
            </>}
            {current == 2 && devis && currentCotation && <PreviewDevis cotation={currentCotation} devis={devis} refreshDevis={() => fetchDevis()} />}
        </Drawer>
    );
}