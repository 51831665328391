import axios, { AxiosResponse } from "axios";
import DataPaginate from "../models/utils/data-paginate";
import Task, { ETaskStatus } from "../models/task";
import dateHelper from "../utils/date.helper";

async function list(page: number = 1, search: string = '', userId?: number, clientId?: number, reference?: string, status?: ETaskStatus) {
    const queryParams = new URLSearchParams();
    queryParams.append('page_size', '25');
    queryParams.append('page', page.toString());
    if (search) {
        queryParams.append('search', search);
    }
    if (userId) {
        queryParams.append('user_id', userId.toString());
    }
    if (clientId) {
        queryParams.append('client_id', clientId.toString());
    }
    if (reference) {
        queryParams.append('reference', reference);
    }
    if (status) {
        queryParams.append('status', status);
    }
    const params = {
        params: queryParams
    }

    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/tasks`, params).then(async (response: AxiosResponse) => {
        const dataPaginate = new DataPaginate(response.data);
        dataPaginate.setData(dataPaginate.data.map(item => new Task(item)));

        return dataPaginate;
    })
}

async function find(id: number) {
    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/tasks/${id}`).then((response: AxiosResponse) => {
        return new Task(response.data);
    })
}

async function create(data: any) {
    const { due_date, ...rest } = data;
    //const formated
    const formated = {
        ...rest,
        due_date: due_date ? dateHelper.dateToApiFormatWithoutTime(due_date) : undefined
    }

    return await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/app/tasks`, formated);
}

async function updateStatus(taskId: number, status: ETaskStatus) {
    const data = {
        status
    }

    return await axios.patch(`${import.meta.env.VITE_BACKEND_URL}/api/app/tasks/${taskId}`, data);
}

async function destroy(id: number) {
    return await axios.delete(`${import.meta.env.VITE_BACKEND_URL}/api/app/tasks/${id}`);
}

export default {
    list,
    find,
    create,
    updateStatus,
    destroy
}