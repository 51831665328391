import React, { useState } from 'react';
import { AutoComplete, Form, Input, Row } from 'antd';
import { useLoadScript } from '@react-google-maps/api';
import { Controller } from 'react-hook-form';
import FormError from './form-error';
import ReadonlyField from './readonly-field';
import useDebounce from '../../utils/hooks/useDebounce';


const libraries: any = ['places'];

interface IProps{
    label: string;
    name: string;
    placeholder: string;
    control: any;
    required?: boolean;
    defaultValue?: string | number;
    error?: any;
    readonly?: boolean;
    contentReadonly?: React.ReactNode;
    prefix?: string;
    labelIsVisible?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
}

export default function FormAddressAutoComplete({ label, name, placeholder, control, required, defaultValue, error, readonly, contentReadonly, prefix, labelIsVisible = true, disabled = false, style = {} }: IProps) {
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const [inputValue, setInputValue] = useState('');

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
        libraries,
    });
    
    const handleSearch = useDebounce((term) => search(term), 500);

    const search = (value: string) => {
        setInputValue(value);
        if (value.length > 2 && isLoaded) {
        const service = new window.google.maps.places.AutocompleteService();
        service.getPlacePredictions({ input: value }, (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
            const newOptions = predictions.map((prediction) => ({
                value: prediction.description,
            }));
            setOptions(newOptions);
            }
        });
        }
    };

    const handleSelect = (value: string) => {
        setInputValue(value);
    };
    
    function renderLabelWithContent() {
        return <Form.Item label={`${label} ${required ? '*' : ''}`} required>
               {renderInput()}
            </Form.Item>
    }

    function renderInput() {
        return <>
             <Row style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', gap: '5px', ...style}}>
                    <Controller
                        name={name}
                        defaultValue={defaultValue}
                        control={control}
                        disabled={disabled}
                        render={({ field }: any) =>
                            <AutoComplete
                                value={inputValue}
                                options={options}
                                onSearch={handleSearch}
                                onSelect={handleSelect}
                                style={{ width: '100%' }}
                                {...field}
                            >
                                <Input.Search placeholder={placeholder} {...field}/>
                            </AutoComplete>}
                    />
                    {prefix && <span style={{width: 60}}>{prefix}</span>}
            </Row>
            <FormError message={error?.message} />
        </>
    }

  return (
    <ReadonlyField readonly={readonly} contentReadonly={contentReadonly} label={label} value={defaultValue}>
        {labelIsVisible ? renderLabelWithContent() : renderInput()}
    </ReadonlyField>
  );
}
