import Filter from "./filter";

export default class GroupFilter{
    label: string;
    filters: Filter[];
    formater: () => string;

    constructor(data: any){
        this.label = data.label;
        this.filters = data.filters;
        this.formater = data.formater ? data.formater : () => "";
    }
}