import React, {
  useRef,
  useState,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import { Icon } from "../../components/ui/typo/Icon";
import { Flex, Input } from "antd";
import type { InputRef } from "antd";

interface VerificationInputProps {
  callback: (code: string) => void;
  reset: boolean;
  isLoading: boolean;
}

const VerificationInput: React.FC<VerificationInputProps> = ({
  callback,
  reset,
  isLoading,
}) => {
  const [code, setCode] = useState<string>("");

  // Refs to control each digit input element
  const inputRefs = [
    useRef<InputRef>(null),
    useRef<InputRef>(null),
    useRef<InputRef>(null),
    useRef<InputRef>(null),
    useRef<InputRef>(null),
    useRef<InputRef>(null),
  ];

  // Reset all inputs and clear state
  const resetCode = () => {
    inputRefs.forEach((ref) => {
      if (ref.current) {
        ref.current.value = ""; 
      }
    });
    if (inputRefs[0].current) {
      inputRefs[0].current.focus();
    }
    setCode("");
  };

  // Call our callback when code = 6 chars
  useEffect(() => {
    if (code.length === 6) {
      if (typeof callback === "function") callback(code);
    }
  }, [code, callback]); //eslint-disable-line

  // Listen for external reset toggle
  useEffect(() => {
    resetCode();
  }, [reset]); //eslint-disable-line

  // Handle input
  const handleInput = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const input = e.target;
    const previousInput = inputRefs[index - 1]?.current;
    const nextInput = inputRefs[index + 1]?.current;

    // Update code state with single digit
    const newCode = [...code];
    // Convert lowercase letters to uppercase
    if (/^[a-z]+$/.test(input.value)) {
      const uc = input.value.toUpperCase();
      newCode[index] = uc;
      const currentRef = inputRefs[index]?.current;
      if (currentRef) {
        currentRef.value = uc;  //eslint-disable-line
      }
    } else {
      newCode[index] = input.value;
    }
    setCode(newCode.join(""));

    const currentRef = inputRefs[index]?.current;
    if (currentRef) {
      currentRef.select();
    }

    if (input.value === "") {
      // If the value is deleted, select previous input, if exists
      if (previousInput) {
        previousInput.focus();
      }
    } else if (nextInput) {
      // Select next input on entry, if exists
      nextInput.select();
    }
  };

  // Select the contents on focus
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  // Handle backspace key
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    const input = e.target as HTMLInputElement;
    const previousInput = inputRefs[index - 1]?.current;

    if ((e.keyCode === 8 || e.keyCode === 46) && input.value === "") {
      e.preventDefault();
      setCode(
        (prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1)
      );
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  // Capture pasted characters
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedCode = e.clipboardData.getData("text");
    if (pastedCode.length === 6) {
      setCode(pastedCode);
      inputRefs.forEach((inputRef, index) => {
        if (inputRef.current) {
          inputRef.current.value = pastedCode.charAt(index); // eslint-disable-line no-use-before-define
        }
      });
    }
  };

  // // Clear button deletes all inputs and selects the first input for entry
  // const ClearButton: React.FC = () => (
  //     <button onClick={resetCode} className="text-2xl absolute right-[-30px] top-3">
  //         <FaTimes />
  //     </button>
  // );

  return (
    <Flex justify="space-between" align="center">
      {[0, 1, 2, 3, 4, 5].map((index) => (
        <React.Fragment key={`container${index}`}>
          <Input
            className="ant-input css-dev-only-do-not-override-mv3141 ant-input-outlined textCenter ant-input-code"
            type="text"
            maxLength={1}
            onChange={(e) => handleInput(e, index)}
            ref={inputRefs[index]}
            autoFocus={index === 0}
            onFocus={handleFocus}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={handlePaste}
            disabled={isLoading}
          />
          {index === 2 && (
            <Icon path="/icons/hyphen.svg" size={18} />
          )}
        </React.Fragment>
      ))}
    </Flex>
  );
};

export default VerificationInput;
