import { TypeDocument } from "./enums/type-document.enum";
import Facture from "./facture";

export default class Document{
    id: number
    data_type: TypeDocument
    type: string
    name: string
    client_visible: boolean
    public_url: string
    data: Facture | OtherDocument

    constructor(data: any) {
        this.id = data.id;
        this.data_type = data.num ? TypeDocument.FACTURE : TypeDocument.OTHER;
        this.type = data.num ? 'Facture' : data.type;
        this.name = data.num ? data.num : data.name;
        this.client_visible = data.client_visible === false ? false : true;
        this.public_url = data.public_url;
        this.data = data.num ? new Facture(data) : new OtherDocument(data);
    }
}

export class OtherDocument{
    id: number;
    code: string;
    name: string;
    type: string;
    description: string;
    client_code: string;
    contract_code: string;
    client_visible: boolean;
    file_path: string;
    created_at: Date;
    updated_at: Date;
    contract_id: number;
    public_url: string;

    constructor(data: any) {
        this.id = data.id;
        this.code = data.code;
        this.name = data.name;
        this.type = data.type;
        this.description = data.description;
        this.client_code = data.client_code;
        this.contract_code = data.contract_code;
        this.client_visible = data.client_visible;
        this.file_path = data.file_path;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
        this.contract_id = data.contract_id;
        this.public_url = data.public_url;
    }
}
