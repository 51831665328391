import { Upload, Space, Card, Button, Row } from "antd";
import { Icon } from "../../ui/typo/Icon";
import { Text } from "../../ui/typo/Text";
import "./input-file.scss";
import { useEffect, useMemo, useState } from "react";

const { Dragger } = Upload;

interface IProps {
    multiple?: boolean;
    onUpload: (file: any) => void;
    base64?: boolean;
    existingUrls?: string[]
    accept?: any
    onView?: (url: string) => void
    onDelete?: (url: string) => void
    disabled?: boolean,
    hideDragger?: boolean
    canDelete?: boolean,
    className?: string
}
export default function InputFile({ multiple = false, onUpload, base64 = false, existingUrls, accept, onView, onDelete, disabled, hideDragger = false, canDelete = true, className }: IProps) {
    
    const [files, setFiles] = useState<any>([])
    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            if (!base64) {
                setFiles([...files, file]);
                //onUpload(file)
            }
            onSuccess("ok");
        }, 0);
    };

    const handleChange = (info: any) => {
        if (base64) {
            const file = info.file.originFileObj;
            if (file ) {
                const reader = new FileReader();
                reader.onload = () => {
                    const content = reader.result;
                    //onUpload(content);
                    setFiles([...files, content]);
                    
                };
                reader.onerror = () => {
                    console.error("Error reading file");
                };
             
                reader.readAsDataURL(file);  // Read file as Base64
            }
        }
    };

    const handleRemove = (file: any) => {
        const newFiles = files.filter((f: any) => f.uid !== file.uid);
        setFiles(newFiles);
    }

    useEffect(() => {
        if (files.length > 0) {
            onUpload(files);
        }
    }, [files])


    const getFilename = (url: string) => {
        const parts = url.split("/");
        return parts[parts.length - 1];
    }
    
    return (
        <>
            {hideDragger === false && <Dragger
                disabled={disabled}
                accept={accept}
                name="file"
                multiple={multiple}
                customRequest={dummyRequest}
                onChange={(info) => handleChange(info)}
                onRemove={(file) => handleRemove(file)}
                className={className}
            >
                <Space direction="vertical" size={12} style={{ width: "100%" }} align="center">
                    <Icon path="/icons/upload.svg" size={40} />
                    <Space size={4} style={{ width: "100%" }}>
                        <Text target="Bold">Cliquer pour ajouter</Text>
                        <Text target="Regular">ou glisser déposer</Text>
                    </Space>
                </Space>
            </Dragger>}

            {existingUrls?.map((existingUrl: string, i: number) =>  <Card key={`file_${existingUrl}`} className="custom-card" style={{marginTop: (hideDragger === false || (existingUrl.length && i > 0)  ? 10 : 0)}}>
                <Row align="middle" justify="space-between">
                    <Space direction="horizontal" align="center">
                        <Icon path="/icons/pdf-icon.svg" size={40} />
                        <Text target="Bold">{getFilename(existingUrl)}</Text>
                    </Space>

                    <Space direction="horizontal" align="center">
                        <Button
                            type="link"
                            icon={<Icon path="/icons/eyes.svg" size={20} />}
                            danger
                            onClick={() => onView && onView(existingUrl)}
                            style={{ padding: 0, height: "20px" }}
                        ></Button>

                        {canDelete && <Button
                            type="link"
                            icon={<Icon path="/icons/trash.svg" size={20} />}
                            danger
                            onClick={() => onDelete && onDelete(existingUrl)}
                            style={{ padding: 0, height: "20px" }}
                            disabled={disabled}
                        ></Button>}
                    </Space>
                </Row>
            </Card>)}

            
        </>
    );
}
