import axios, { AxiosResponse } from "axios";
import Note from "../models/note";
import DataPaginate from "../models/utils/data-paginate";

async function list(page: number = 1, customerId: number, reference?: string) {
    const queryParams = new URLSearchParams();
    queryParams.append('page', page.toString());
    queryParams.append('client_id', customerId.toString());
    if (reference) {
        queryParams.append('reference', reference);
    }
    const params = {
        params: queryParams
    }

    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/app/notes`, params).then(async (response: AxiosResponse) => {
        const dataPaginated = new DataPaginate(response.data);
        dataPaginated.data = dataPaginated.data.map((note: any) => new Note(note));

        return dataPaginated;
    })
}

async function create(data: any) {
    return await axios.post(`${import.meta.env.VITE_BACKEND_URL}/api/app/notes`, data).then(async (response: AxiosResponse) => {
        return new Note(response.data);
    })
}

export default {
    list,
    create
}