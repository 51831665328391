import { Text } from "../../../components/ui/typo/Text";

interface IAvatarProps {
    placeholder?: string;
    size?: 'normal' | 'small';
}
export default function Avatar({ placeholder, size = 'normal' }: IAvatarProps) {

    function getSize() {
        if (size === 'normal') {
            return '40px';
        }
        return '24px';
    }
    
    function getTarget() {
        if (size === 'normal') {
            return 'H3';
        }
        return 'XSmall';
    }

    return (
        <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: '#F5F5F5',
        color: '#A8A8A8',
        width: getSize(),
        height: getSize()
        }}>
            <Text target={getTarget()}>{placeholder}</Text>
        </div>
    );
}
