import { ERole } from "../../../../models/user";
import { useAppSelector } from "../../../../store/hooks";

interface IProps{
    children: React.ReactNode;
    permission: ERole
}
export default function HasPermission({ permission, children}: IProps) {
    const { value: user } = useAppSelector(state => state.user);

    if (user?.hasPermission(permission)) {
        return children;
    }

    return <div>
        Vous n'avez pas le droit d'accéder à cette page !
    </div>
    


}