import { Col, Row, Space } from "antd";
import RoadDemande from "../../../models/RoadDemande";
import ReadonlyField from "../../form/readonly-field";
import dateHelper from "../../../utils/date.helper";
import { Text } from "../../ui/typo/Text";
import UserTag from "../commons/user-tag/user-tag";
import StatusRoadCotation from "./status-road-cotation";
import { useMemo } from "react";
import InputFile from "../../ui/input-file/input-file";
import axios from "axios";

interface IProps{
    cotation: RoadDemande;
    attachments: any[];
}
export default function RoadQuotationDetailInformation({ cotation, attachments }: IProps) {
    function getLabelDateToDate(date1: Date, date2: Date) {
        return `${dateHelper.dateToDDMMYYYYHHMM(date1)} et ${dateHelper.dateToDDMMYYYYHHMM(date2)}`
    }

    const isControlledTemperature = useMemo(() => {
        return cotation?.features?.controlled_temperature_min != null || cotation?.features?.controlled_temperature_max != null
    }, [cotation])

    const isPalette = useMemo(() => {
        return cotation?.features?.nb_returnable_pallets != null && cotation?.features?.nb_returnable_pallets != 0
    }, [cotation])

    async function onViewAttachment(url: string) {
        try {
            const response: any = await axios.get(url, {
                responseType: 'blob'
            });
            const blob = response.data;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            const parts = url.split("/");
            const filename = parts[parts.length - 1];
            link.download = `${filename}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Erreur lors du téléchargement du fichier :", error);
        }     
    }


    return <>
        <Row gutter={16}>
            <Col span={12}>
                <ReadonlyField
                    label="Client"
                    value={cotation.client ? `${cotation.client.name} (${cotation.client.code})`: '-'}
                    readonly
                />
            </Col>

            <Col span={12}>
                <ReadonlyField
                    label="Contact"
                    value={cotation.contact ? cotation.contact.getLabel() : '-'}
                    readonly
                />
            </Col>

            <Col span={12}>
                <ReadonlyField
                    label="Date de la demande"
                    contentReadonly={ <Text target="Regular">{dateHelper.dateToDDMMYYYY(cotation.created_at)}</Text> }
                    readonly
                />
            </Col>
            
            <Col span={12}>
                <ReadonlyField
                    label="Affectation"
                    readonly
                    contentReadonly={cotation.main_assignee ? <UserTag user={cotation.main_assignee} /> : <>-</>}
                />
            </Col>

            <Col span={12}>
                <ReadonlyField
                    label="Affectation secondaire"
                    readonly
                    contentReadonly={cotation.secondary_assignee ? <UserTag user={cotation.secondary_assignee} /> : <>-</>}
                />
            </Col>

            <Col span={12}>
                <ReadonlyField
                    label="Créée par"
                    readonly
                    contentReadonly={cotation.author ?  <UserTag user={cotation.author} />  : <>-</>}
                />
            </Col>

            <Col span={12}>
                <ReadonlyField
                    label="Chiffrée par"
                    readonly
                    contentReadonly={cotation.last_devis && cotation.last_devis.creator ?  <UserTag user={cotation.last_devis.creator} />: <>-</>}
                />
            </Col>

            <Col span={12}>
                <ReadonlyField
                    label="Status"
                    readonly
                    contentReadonly={ <StatusRoadCotation status={cotation.status} />}
                />
            </Col>
        </Row>

        <Space direction="vertical" style={{ width: "100%" }}>
            <Text target="SectionTitle">Détails du trajet</Text>


            <Row gutter={16}>
                <Col span={12}>
                    <ReadonlyField
                    label="Adresse de chargement"
                    readonly
                    contentReadonly={ cotation.origin ? cotation.origin.raw : '-'}
                    />
                </Col>

                <Col span={12}>
                    <ReadonlyField
                    label="Date"
                    readonly
                    contentReadonly={cotation.origin_date_start && cotation.origin_date_end ? `Le chargement est prévu entre le ${getLabelDateToDate(cotation.origin_date_start, cotation.origin_date_end)}` : '-'}
                    />
                </Col>
            </Row>     
            
            {cotation.stop_points && cotation.stop_points.length > 0 && <>
                {cotation.stop_points.map((stop_point, index) => {
                    return (
                        <Row gutter={16} key={index}>
                            <Col span={11}>
                                <ReadonlyField
                                    label={`Point d'arrêt ${index + 1}`}
                                    readonly
                                    contentReadonly={stop_point.address?.raw}
                                />
                            </Col>
                            <Col span={5}>
                                <ReadonlyField
                                    label={`Type`}
                                    readonly
                                    contentReadonly={stop_point.getLabelType()}
                                />
                            </Col>
                            <Col span={8}>
                                <ReadonlyField
                                    label={`Date`}
                                    readonly
                                    contentReadonly={stop_point.date_start && stop_point.date_end ? `L'arrêt est prévu entre le ${getLabelDateToDate(stop_point.date_start, stop_point.date_end)}` : '-'}
                                />
                            </Col>
                        </Row>
                    )
                })}
            </>}
           
            <Row gutter={16}>
                <Col span={12}>
                    <ReadonlyField
                    label="Adresse de livraison"
                    readonly
                    contentReadonly={ cotation.destination ? cotation.destination.raw : '-'}
                    />
                </Col>

                <Col span={12}>
                    <ReadonlyField
                    label="Date"
                    readonly
                    contentReadonly={cotation.destination_date_start && cotation.destination_date_end ? `La livraison est prévu entre le ${getLabelDateToDate(cotation.destination_date_start, cotation.destination_date_end)}` : '-'}
                    />
                </Col>
            </Row>

            <Space direction="vertical" style={{ width: "100%" }}>
                <Text target="SectionTitle">Détails sur la marchandise</Text>
                <Row gutter={16}>
                    <Col span={8}>
                        <ReadonlyField
                            label="Unité"
                            readonly
                            contentReadonly={cotation.getUnitLabel()}
                        />
                    </Col>
                    <Col span={8}>
                        <ReadonlyField
                            label="Quantité"
                            readonly
                            contentReadonly={cotation.features?.quantity}
                        />
                    </Col>
                     <Col span={8}>
                        <ReadonlyField
                            label="Poids"
                            readonly
                            contentReadonly={cotation.features?.total_weight ? `${cotation.features?.total_weight} kg` : '-'}
                        />
                    </Col>
                </Row>

                <Col span={24}>
                    <ReadonlyField
                        label="Matière dangereuse"
                        readonly
                        contentReadonly={cotation.getDangerousClassLabel()}
                    />
                </Col>

                <Row gutter={16}>
                    <Col span={8}>
                        <ReadonlyField
                            label="Température dirigée"
                            readonly
                            contentReadonly={isControlledTemperature ? 'Oui' : 'Non'}
                        />
                    </Col>

                    {isControlledTemperature && <>
                        <Col span={8}>
                            <ReadonlyField
                                label="Température minimale"
                                readonly
                                contentReadonly={`${cotation.features?.controlled_temperature_min} °C`}
                            />
                        </Col>

                        <Col span={8}>
                            <ReadonlyField
                                label="Température maximale"
                                readonly
                                contentReadonly={`${cotation.features?.controlled_temperature_max} °C`}
                            />
                        </Col>
                    </>}
                </Row>

                <Row gutter={16}>
                    <Col span={8}>
                        <ReadonlyField
                            label="Palettes consignées"
                            readonly
                            contentReadonly={isPalette ? 'Oui' : 'Non'}
                        />
                    </Col>
                    {isPalette &&<Col span={8}>
                            <ReadonlyField
                                label="Nombre"
                                readonly
                                contentReadonly={cotation.features?.nb_returnable_pallets}
                            />
                        </Col>
                    }
                </Row>

                <Col span={24}>
                    <ReadonlyField
                        label="Valeur de la marchandise"
                        readonly
                        contentReadonly={cotation.features?.goods_value ? `${cotation.features?.goods_value} €` : ''}
                    />
                </Col>
            </Space>

            <Space direction="vertical" style={{ width: "100%" }}>
                <Text target="SectionTitle">Informations complémentaires</Text>

                <Col span={24}>
                    <ReadonlyField
                        label="Matériel exigé"
                        readonly
                        contentReadonly={cotation.features?.required_equipment ? `${cotation.features?.required_equipment}` : ''}
                    />
                </Col>

                <Col span={24}>
                    <ReadonlyField
                        label="Instructions"
                        readonly
                        contentReadonly={cotation.features?.instructions ? `${cotation.features?.instructions}` : ''}
                    />
                </Col>

                {attachments.length > 0 && <ReadonlyField
                    label="Pièce(s) jointe(s)"
                    readonly
                    contentReadonly={
                        <InputFile
                            onUpload={(file: any) => null}
                            existingUrls={attachments}
                            accept={['.pdf']}
                            onDelete={(url) => null}
                            onView={(url) => onViewAttachment(url)}
                            disabled={true}
                            hideDragger={true}
                            canDelete={false}
                        />
                    }
                />}

            </Space>

        </Space>
    </>
}