export default class DataPaginate{
    current_page: number;
    data: any[];
    first_page_url: string
    from: number
    last_page: number
    last_page_url: string
    links: any
    next_page_url: string
    path: string
    per_page: number
    prev_page_url: string
    to: number
    total: number

    constructor(data: any) {
        this.current_page = data.current_page;
        this.data = data.data;
        this.first_page_url = data.first_page_url;
        this.from = data.from;
        this.last_page = data.last_page;
        this.last_page_url = data.last_page_url;
        this.links = data.links;
        this.next_page_url = data.next_page_url;
        this.path = data.path;
        this.per_page = data.per_page;
        this.prev_page_url = data.prev_page_url;
        this.to = data.to;
        this.total = data.total;
    }

    setData(data: any) {
        this.data = data;
    }

}