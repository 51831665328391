import type { ColumnsType } from "antd/es/table";
import { AgencieTableType } from "../models/table/AgencieTableType";

const columns: ColumnsType<AgencieTableType> = [
    {
        title: "Nom de l'agence",
        dataIndex: "name",
        key: "name",
        sorter: false,
    },
    {
        title: "Identifiant",
        dataIndex: "code",
        key: "code",
        sorter: false,
    },
    {
        title: "Téléphone",
        dataIndex: "phone",
        key: "phone",
        sorter: false,
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: false,
    }
];

export { columns }