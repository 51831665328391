interface IProps{
    pdfUrl: string;
}
const PreviewPDF = ({ pdfUrl }: IProps) => {
  return (
    <div style={{ width: '100%', height: '90%' }}>
      <iframe
        src={pdfUrl}
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="PDF Preview"
      />
    </div>
  );
};

export default PreviewPDF;