import { Col, Row, Space } from "antd";
import Devis from "../../../models/Devis";
import RoadDemande from "../../../models/RoadDemande";
import { Text } from "../../ui/typo/Text";
import ReadonlyField from "../../form/readonly-field";
import { useEffect, useMemo, useState } from "react";
import roadDemandesService from "../../../services/road-demandes.service";
import dateHelper from "../../../utils/date.helper";
import InputFile from "../../ui/input-file/input-file";
import axios from "axios";
import EstimateDistanceCO2 from "../../../models/estimate-distance-co2";
import MapItinerary from "./map-itinerary";

interface IProps{
    cotation: RoadDemande;
    devis: Devis;
    attachments: string[];
}
export default function RoadQuotationDetailPrice({ cotation, devis, attachments}: IProps) {

    const [rateCO2, setRateCO2] = useState<number>();
    const [estimateDistance, setEstimateDistance] = useState<EstimateDistanceCO2>();

    useEffect(() => {
        async function getCO2() {
            if (cotation && devis) {
                const res = await roadDemandesService.getEstimationCO2(cotation?.id, devis.data.distance);
                if (res.co2) {
                    setRateCO2(res.co2);
                }
                setEstimateDistance(await roadDemandesService.getEstimationCO2(cotation?.id));
            } else {
                setRateCO2(undefined);
            }
        }
        getCO2();
    }, [devis]) 

    const rateCO2Label = useMemo(() => {
    if (rateCO2) {
        return `${rateCO2} kg CO2`;
    }
    return '-';
    }, [rateCO2])

    const hasOptions = useMemo(() => {
        return devis.data?.options?.customs_import != null
            || devis.data?.options?.customs_export != null
            || devis.data?.options?.tailgate
            || devis.data?.options?.advalorem_insurance;
    }, [devis])

    async function onViewAttachment(url: string) {
        try {
            const response: any = await axios.get(url, {
                responseType: 'blob'
            });
            const blob = response.data;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            const parts = url.split("/");
            const filename = parts[parts.length - 1];
            link.download = `${filename}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Erreur lors du téléchargement du fichier :", error);
        }     
    }
    
    return (
        <>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Row gutter={16}>
                    <Col span={12}>
                        <ReadonlyField
                            label="Distance"
                            value={`${devis.data.distance} km`}
                            readonly
                        />
                    </Col>
                    <Col span={12}>
                        <ReadonlyField
                            label="Taux de CO2"
                            value={rateCO2Label}
                            readonly
                        />
                    </Col>

                    <Col span={12}>
                        <ReadonlyField
                            label="Tarif personnalisé"
                            value={`${devis.data.price} €`}
                            readonly
                        />
                    </Col>

                    <Col span={12}>
                        <ReadonlyField
                            label="Surtaxe gasoil"
                            value={devis?.data?.gazoil_surtax_rate ? `${devis.data.gazoil_surtax_rate} %` : '-'}
                            readonly
                        />
                    </Col>

                     <Col span={12}>
                        <ReadonlyField
                            label="Date d'expiration"
                            value={dateHelper.dateToDDMMYYYY(devis.expired_at)}
                            readonly
                        />
                    </Col>

                    
                </Row>
            </Space>

            <Space direction="vertical" style={{ width: "100%" }}>
                <Text target="SectionTitle">Options</Text>

                {!hasOptions && (    
                    <Text target="Regular">Aucun</Text>
                )}

                {devis.data?.options?.customs_import != null && (
                    <ReadonlyField
                        label="Frais de douane - Import"
                        value={`${devis.data.options.customs_import} €`}
                        readonly
                    />
                )}

                {devis.data?.options?.customs_export != null && (
                    <ReadonlyField
                        label="Frais de douane - Export"
                        value={`${devis.data.options.customs_export} €`}
                        readonly
                    />
                )}

                {devis.data?.options?.tailgate != null && (
                    <ReadonlyField
                        label="Hayon"
                        value={`${devis.data.options.tailgate} €`}
                        readonly
                    />
                )}

                {devis.data?.options?.advalorem_insurance != null && (
                    <ReadonlyField
                        label={`Assurance ad-valorem (Valeur de la marchandise ${cotation.features?.goods_value ? cotation.features?.goods_value : '-'} € HT)`}
                        value={`${devis.data.options.advalorem_insurance} %`}
                        readonly
                    />
                )}
            </Space>

            <Space direction="vertical" style={{ width: "100%", marginTop: 20 }}>
                <Text target="SectionTitle">Frais additionnels</Text>
                {!devis.data.fees || devis.data.fees.length === 0 && (
                    <Text target="Regular">Aucun</Text>
                )}

                {devis.data.fees && devis.data.fees.map((fee, index) => (
                    <ReadonlyField
                        key={index}
                        label={fee.name}
                        value={`${fee.price} €`}
                        readonly
                    />
                ))}
            </Space>

             <Space direction="vertical" style={{ width: "100%", marginTop: 20 }}>
                <Text target="SectionTitle">Prix total</Text>
                <Text target="Regular">{`${devis.total} €`}</Text>
            </Space>


            <Space direction="vertical" style={{ width: "100%", marginTop: 20 }}>
                <Text target="SectionTitle">Commentaires internes</Text>
                <Text target="Regular">{devis.data.comments || '-'}</Text>
            </Space>

            <Space direction="vertical" style={{ width: "100%", marginTop: 20 }}>
                <Text target="SectionTitle">Itinéraire</Text>
                {estimateDistance && estimateDistance.legs.length > 0 && estimateDistance.points.length > 0 ? <MapItinerary legs={estimateDistance.legs} points={estimateDistance.points} /> : <></>}
            </Space>

            <Space direction="vertical" style={{ width: "100%", marginTop: 20 }}>
                {attachments.length > 0 && <ReadonlyField
                    label="Pièce(s) jointe(s)"
                    readonly
                    contentReadonly={
                        <InputFile
                            onUpload={(file: any) => null}
                            existingUrls={attachments}
                            accept={['.pdf']}
                            onDelete={(url) => null}
                            onView={(url) => onViewAttachment(url)}
                            disabled={true}
                            hideDragger={true}
                            canDelete={false}
                        />
                    }
                />}
            </Space>

            
        </>
    )
    
}