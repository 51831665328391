export default class EstimateDistanceCO2{
    co2?: number;
    distance?: number;
    legs: string[][] = [];
    points: string[] = [];

    constructor(data: any) {
        this.co2 = data.co2;
        this.distance = data.distance;
        if(data.legs)
            this.legs = data.legs;
        if(data.points)
            this.points = data.points;
    }

    canShowMap() {
        return this.points.length > 0 && this.legs.length > 0;
    }
}