import type { ColumnsType } from "antd/es/table";
import { UserTableType } from "../models/table/UserTableType";

const columns: ColumnsType<UserTableType> = [
    {
        title: "Nom",
        dataIndex: "full_name",
        key: "full_name",
        sorter: false,
    },
    {
        title: "Addresse mail",
        dataIndex: "email",
        key: "email",
        sorter: false,
    },
    {
        title: "Droits",
        dataIndex: "role",
        key: "role",
        sorter: false,
    },
    {
        title: "Agence(s) de rattachement",
        dataIndex: "agency_name",
        key: "agency_name",
        sorter: false,
    },
    {
        title: "Code",
        dataIndex: "code",
        key: "code",
        sorter: false,
    }
];

export { columns }