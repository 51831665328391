import { Form, Select } from "antd";
import { Controller } from "react-hook-form";
import FormError from "./form-error";

interface IProps{
    label: string;
    name: string;
    placeholder: string;
    required?: boolean;
    defaultValue?: { label: string, value: string | number }[];
    control: any;
    options: { label: string, value: string | number }[];
    error?: any;
    hasCustomFilter?: boolean;
    onSearch?: (value: string) => void;
}
export default function FormSelectMultiple({ label, name, placeholder, required, defaultValue, control, options, error, hasCustomFilter, onSearch }: IProps) {
    return (
        <Form.Item label={`${label} ${required ? '*' : ''}`}>
            <Controller
                    name={name}
                    defaultValue={defaultValue || []} 
                    control={control}
                    render={({ field }: any) => <Select
                        mode="multiple"
                        showSearch
                        onSearch={onSearch ? onSearch : null}
                        placeholder={placeholder}
                        options={options} 
                        filterOption={hasCustomFilter ? false : (input, option) =>
                            option && option.label && option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        {...field} 
                    />}
            />
            <FormError message={error?.message} />
        </Form.Item>
    )
}