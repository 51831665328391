import Contract from "../../../models/contract"

interface IProps{
    contract: Contract
}
export default function StatusContract({ contract }: IProps) {
    return (
        <div className="status-task" style={{borderColor: contract.getBorderColorInternalStatus(), backgroundColor: contract.getBackgroundColorInternalStatus(), color: contract.getColorInternalStatus()}}>
            {contract.getStatusLabel()}
        </div>
    )
}