import { useEffect, useMemo, useState } from "react";
import roadDemandesService from "../../../services/road-demandes.service";
import DataPaginate from "../../../models/utils/data-paginate";
import useTable from "../../../utils/hooks/useTable";
import { columns } from "../../../configs/CustomerRoadDemandesTableColumns";
import RoadDemande from "../../../models/RoadDemande";
import dateHelper from "../../../utils/date.helper";
import { Text } from "../../ui/typo/Text";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";

interface IProps{
    customerId: number;
}
export default function CustomerListQuotation({ customerId }: IProps) {

    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
    const [page, setPage] = useState(1);
    const { renderColumns } = useTable(columns)
    const navigate = useNavigate();

    
    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const res = await roadDemandesService.list(page, '', [{ name: 'client_id', value: customerId }]);
                setDatapaginate(res);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [page])

    const dataTable = useMemo(() => {
        if (!dataPaginate || !dataPaginate.data) return [];
        return dataPaginate.data.map((item: RoadDemande) => {
            return {
                id: item.id,
                code: item.code,
                date:  dateHelper.dateToDDMMYYYY(item.origin_date_start),
                origin: item.origin?.raw || '-',
                destination: item.destination?.raw || '-',
                distance: item.last_devis?.data.distance ? `${item.last_devis?.data.distance} km` : '-',
                price: item.last_devis?.data.price ? `${item.last_devis?.data.price} €` : '-',
            }
        });
    }, [dataPaginate]);

    return (
        <>
            <Text target="SectionTitle">Cotations de transport</Text>

            <Table
                style={{marginTop: 20}}
                rowKey={"id"}
                loading={loading}
                columns={renderColumns()}
                dataSource={dataTable}
                scroll={{ x: 1327, y: 1080 }}
                showSorterTooltip={false}
                onRow={(record) => { return { onClick: () => { navigate(`/road-quotations/${record.id}`) } } }}
                pagination={{
                pageSize: dataPaginate?.per_page || 10,
                total: dataPaginate?.total || 0,
                onChange: (page) => {
                    setPage(page);
                },
                }}
            />
        </>
    )

}