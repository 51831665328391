import moment from "moment";

function dateToCompleteDateLettersAndHours(date: Date) {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    return `${getDayName(date.getDay())} ${day} ${getMonthName(date.getMonth())} ${date.getFullYear()} - ${hours}:${minutes}`
}

function dateToDDMMYYYY(date?: Date) {
    if (!date) {
        return '-'
    }
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;

    return `${day}/${month}/${date.getFullYear()}`
}

function dateToDDMMYYYYHHMM(date?: Date) {
    if (!date) {
        return '-'
    }
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    return `${day}/${month}/${date.getFullYear()} - ${hours}:${minutes}`
}

function dateToApiFormat(date: Date) {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
    return `${date.getFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

function dateToApiFormatWithoutTime(date: Date) {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    return `${date.getFullYear()}-${month}-${day}`
}

function getDayName(day: number) {
    const days = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
    ]
    return days[day]

}

function getMonthName(month: number) {
    const months = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ]
    return months[month]
}

function formatDateAndTime(dateDate: Date, timeDate: Date) {
    const year = moment(dateDate).year();
    const month = moment(dateDate).month();
    const day = moment(dateDate).date();

    const hours = moment(timeDate).hours();
    const minutes = moment(timeDate).minutes();
    const seconds = moment(timeDate).seconds();

    // Combinez les composants pour former la date finale
    const finalDate = moment.utc().set({ year, month, date: day, hours, minutes, seconds });

    // Retourne un objet Date
    return finalDate.toDate();
}

function getDateInThreeMonths(): Date {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  
  // Set the month to three months ahead
  // The setMonth method automatically handles year transition
  currentDate.setMonth(currentMonth + 3);
  
  return currentDate;
}

function getTodayAtMidnight(): Date {
  const newDate = new Date();
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}


export default {
    dateToCompleteDateLettersAndHours,
    dateToDDMMYYYY,
    dateToDDMMYYYYHHMM,
    dateToApiFormat,
    dateToApiFormatWithoutTime,
    formatDateAndTime,
    getDateInThreeMonths,
    getTodayAtMidnight
}